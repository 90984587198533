import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AthleteDetails from '../athlete-details/AthleteDetails';
import AthleteAttending from '../athlete-attending/AthleteAttending';
import useTabInfoStyle from './useTabInfoStyle';
import AthleteNotes from '../athlete-notes/AthleteNotes';
import AthleteTagsHistory from '../athlete-tags-history/AthleteTagsHistory';
import AthleteRanksHistory from '../athlete-ranks-history/AthleteRanksHistory';
import { AthleteHistoryRanks } from '../athlete-ranks-history/types';
import AthleteVideos from '../athlete-video/AthleteVideos';
import { AthletesListAthleteType } from '../../../../api/athletes';
import { CollegeTagsDto } from '../../../../api/tags';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import { CollegeNoteDto } from '../../../../api/notes';
import { useAppStateValue } from '../../../../state/StateContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export type AthleteTabInfoProps = {
  athlete: AthletesListAthleteType;
  activeSport: number;
  collegeTags: CollegeTagsDto[];
  setShow: (isShow: boolean) => void;
  activeTab?: number;
  bgColor?: string;
  width?: string | number;
  notes: CollegeNoteDto[];
  updateAthleteNotes: (notes: CollegeNoteDto[]) => void;
}
const AthleteTabInfo = (props: AthleteTabInfoProps) => {
  const [value, setValue] = useState(props.activeTab || 0);

  const classes = useTabInfoStyle(props);

  const [{ deletedTags }] = useAppStateValue();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.tabsContainer}>
        <Tabs
          classes={{ indicator: classes.indicator }}
          value={value}
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          aria-label="details info"
        >
          <Tab label="DETAILS" classes={{ root: classes.rootTab, selected: classes.selectedTab }} {...a11yProps(0)} />
          <Tab label="NOTES" classes={{ root: classes.rootTab, selected: classes.selectedTab }} {...a11yProps(1)} />
          <Tab label="TAGS HISTORY" classes={{ root: classes.rootTab, selected: classes.selectedTab }} {...a11yProps(2)} />
          <Tab label="GROUPS HISTORY" classes={{ root: classes.rootTab, selected: classes.selectedTab }} {...a11yProps(3)} />
          <Tab label="ATTENDING" classes={{ root: classes.rootTab, selected: classes.selectedTab }} {...a11yProps(4)} />
          <Tab label="VIDEO" classes={{ root: classes.rootTab, selected: classes.selectedTab }} {...a11yProps(5)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <AthleteDetails athlete={props.athlete} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.tabAthlete}>
          <div className={classes.notes}>
            <AthleteNotes
              athleteId={props.athlete.athlete_master_id}
              notes={props.notes}
              updateAthleteNotes={props.updateAthleteNotes}
              isMoreNotes={true}
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.tableBlock}>
          <AthleteTagsHistory
            historyTags={AthleteHelpers.prepareAthleteTagsHistory(
              props.athlete.history_tags || [],
              props.collegeTags || [],
              deletedTags
            )}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className={classes.tableBlock}>
          <AthleteRanksHistory
            historyRanks={props.athlete.history_ranks as AthleteHistoryRanks[]}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div className={classes.tableBlock}>
          <AthleteAttending athleteAttending={props.athlete.attending} activeSport={props.activeSport} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className={classes.tableBlock}>
          <AthleteVideos videos={props.athlete.videos || []} />
        </div>
      </TabPanel>
    </div >
  );
};

export default AthleteTabInfo;

