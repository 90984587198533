import { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import SearchIcon from '@material-ui/icons/Search';
import LabelIcon from '@material-ui/icons/Label';
import StarIcon from '@material-ui/icons/Star';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ViewColumnIcon from '@material-ui/icons/ViewColumn'; // ViewKanban
import GroupIcon from '@material-ui/icons/Group';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { useAppStateValue } from '../../state/StateContext';

import { signOut } from '../../api/auth';
import LocalStorageHelpers from '../../helpers/LocalStorageHelpers';

import { mainMenuDic } from '../../dictionary';
// import logo from '../../assets/logo_52.svg';
import ncsaLogo from '../../assets/cp_logo_2024_white_blue.png';
import { DRAWER_WIDTH, KANBAN_PAGE } from '../../constants';

type Props = {
  resize: boolean;
};
type StyleProps = {
  backgroundColor: string,
  borderColor: string,
  color: string,
}

const colors = {
  appBar: `#005DA8`,
  logoWhite: `#FFFF`,
  menuItemColor: '#1B4484',
};
const fonts = {
  lato: '"Lato", sans-serif',
};
const menuIcon = {
  color: colors.menuItemColor,
  minWidth: `${DRAWER_WIDTH.mobile - 32}px`,
};

const useStyles = makeStyles((theme: Theme) =>
({
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    background: colors.appBar,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    paddingLeft: 25,
    textDecoration: 'none',
  },
  logoImg: {
    width: 24,
  },
  logoContent: {
    width: '100%',
    position: 'relative',
    left: -13,
  },
  logoContentHide: {
    display: 'none',
  },
  listPadding: {
    padding: 12,
  },
  listItemRadius: {
    borderRadius: '5px',
  },
  itemGutters: {
    paddingLeft: '12px',
  },
  itemGuttersShift: {
    paddingLeft: '12px',
  },
  menuText: {
    fontWeight: 400,
    color: colors.menuItemColor,
    fontSize: 16,
    lineHeight: '20px',
    whiteSpace: 'nowrap'
  },
  divider: {
    margin: '0 12px'
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 70,
    width: '100%',
  },
  avatar: (props: StyleProps) => ({
    color: props.color,
    backgroundColor: props.backgroundColor,
    border: props.borderColor,
    boxSizing: 'border-box',
    boxShadow: '4px 8px 20px rgba(154, 170, 207, 0.12)',
    width: 50,
    height: 50,
  }),
  nameContainer: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: 70,
    width: '100%',
    color: '#1B4484',
    padding: '0 12px 0 24px',
  },
  userName: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '150%',
  },
  collegeName: {
    width: '100%',
    fontSize: 14,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuIcon,
})
);

export default function MainMenuItems(props: Props) {
  const { resize } = props;
  const [{ account, isVisibleRanks, isVisibleTasks }, dispatch] = useAppStateValue();
  let userColor: StyleProps = {
    backgroundColor: (account?.color && account?.color.length > 0 ? `#${account?.color}` : '#fff'),
    borderColor: (account?.color && account?.color.length > 0 ? `1px solid #${account?.color}` : `1px solid #DFE2EC`),
    color: (account?.color && account?.color.length > 0 ? `#fff` : '#1B4484')
  }
  const classes = useStyles(userColor);

  const location = useLocation();
  const history = useHistory();

  const logOut = async () => {
    LocalStorageHelpers.clearAllLocalStorage();
    await signOut();
    dispatch({ type: 'clearState', value: {} });
    history.push('/')
    history.go(0);
  };

  const initials = `${account?.first.slice(0, 1)}${account?.last.slice(0, 1)}`;

  const [lastKanbanLocation, setLastKanbanLocation] = useState<typeof location | undefined>()
  useEffect(() => {
    if(location.pathname === KANBAN_PAGE) {
      // @ts-ignore
      setLastKanbanLocation(location)
    }
  }, [location])

  const mainMenuItems = [
    {
      text: mainMenuDic.search,
      route: '/search',
      icon: <SearchIcon />,
    },
    // {
    //   text: mainMenuDic.searchClubs,
    //   route: '/search-clubs',
    //   icon: <GroupIcon />,
    // },
    // {
    //   text: mainMenuDic.searchTeams,
    //   route: '/search-teams',
    //   icon: <GroupIcon />,
    // },
    {
      text: mainMenuDic.tags,
      route: '/tags',
      icon: <LabelIcon />,
    },
  ];

  const ranksMenuItems = [
    {
      text: mainMenuDic.ranks,
      route: '/ranks',
      icon: <StarIcon />,
    },
  ];

  const kanbanMenuItems = [
    {
      text: mainMenuDic.kanban,
      route: KANBAN_PAGE,
      location: lastKanbanLocation,
      icon: <ViewColumnIcon />,
    },
  ];

  const optionalMenuItems = [
    {
      text: mainMenuDic.settings,
      route: '/settings',
      icon: <SettingsIcon />,
    },
  ];

  const actionsMenuItems = [
    {
      text: mainMenuDic.logout,
      action: logOut,
      icon: <ExitToAppIcon />,
    },
  ];

  const LIST:{text: string, route: string, icon: JSX.Element, location?: typeof location}[] = [
    ...mainMenuItems,
    ...(isVisibleRanks? ranksMenuItems : []),
    ...(isVisibleTasks? kanbanMenuItems : []),
    ...optionalMenuItems,
  ]

  return (
    <>
      <div className={classes.toolbar}>
        <Link className={classes.logo} to="/">
          {/* <img src={logo} alt={'logo'} className={classes.logoImg} /> */}
          <img src={ncsaLogo} alt={'ncsaLogo'} 
          className={classes.logoContent} 
          />
        </Link>
      </div>

      {resize ?
        (
          <div className={classes.avatarContainer}>
            <Avatar className={classes.avatar}>{initials}</Avatar>
          </div>
        )
        :
        (
          <div className={classes.nameContainer}>
            <Typography className={classes.userName}>{account?.first} {account?.last}</Typography>
            <Typography className={classes.collegeName}>{account?.college_name}</Typography>
          </div>
        )
      }
      <Divider className={classes.divider} />
      <List className={classes.listPadding}>
        {LIST.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.location || item.route}
            className={classes.listItemRadius}
            classes={
              resize
                ? { gutters: classes.itemGuttersShift }
                : { gutters: classes.itemGutters }
            }
            selected={item.route === location.pathname}
          >
            <ListItemIcon className={classes.menuIcon}>
              {item.icon}
            </ListItemIcon>
            <ListItemText className={classes.menuText} primary={item.text} />
          </ListItem>
        ))}
      </List>

      <Divider className={classes.divider} />
      <List className={classes.listPadding}>
        {actionsMenuItems.map((menuItem) => (
          <ListItem
            button
            key={menuItem.text}
            onClick={menuItem.action}
            className={classes.listItemRadius}
            classes={
              resize
                ? { gutters: classes.itemGuttersShift }
                : { gutters: classes.itemGutters }
            }
          >
            <ListItemIcon className={classes.menuIcon}>
              {menuItem.icon}
            </ListItemIcon>
            <ListItemText
              className={classes.menuText}
              primary={menuItem.text}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}
