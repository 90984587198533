import { API } from 'aws-amplify';

import ApiResponse from './ApiResponse';
import { API_ERRORS, DEFAULT_ERROR_MESSAGE } from '../constants';

export type CollegeRankDto = {
  college_program_id: number;
  college_rank_id: number; // number?
  college_rank_group_id: number;
  rank_order: number;
  title: string;
  description: string;
  alias: string;
  type_rank: string;
  type_value?: number;
  values?: string | string[];
  is_primary: boolean;
};

export type AthleteRankForFiltersList = {
  college_rank_id: number;
  athlete_rank_id: number;
  group: string;
  rank: string;
}

export type CreateCollegeRankDto = {
  title: string;
  alias: string;
  description?: string;
  college_program_id?: number;
  type_rank: string;
  type_value?: number;
  values?: string | string[];
  college_rank_group_id?: number;
};

export type CollegeRankReorderDto = {
  college_rank_id: number;
  rank_order: number;
};
export type CollegeGroupReorderDto = {
  college_rank_group_id: number;
  sort_order: number;
};

export interface AthleteRankDto {
  college_program_id: number;
  college_rank_id: number;
  college_rank_group_id: number;
  athlete_master_id: number;
  tournament_id: number;
  users_id: number;
  athlete_rank_id: number;
  value: string;
  initials: string;
  initials_color: string;
};

export type CreateAthleteRankDto = Omit<AthleteRankDto, "athlete_rank_id">
export type UpdateAthleteRankDto = Pick<AthleteRankDto, "college_program_id" | "value" | "users_id">

export type CollegeGroupDto = {
  college_program_id: number;
  college_rank_group_id: number;
  sort_order: number;
  title: string;
  description: string;
  date_modified: string;
  date_deleted: string;
  ranks: CollegeRankDto[];
  general: boolean;
};

export type CreateCollegeGroupDto = {
  college_program_id?: number;
  title: string;
  description?: string;
}

export async function createCollegeRank(
  createCollegeRankDto: CreateCollegeRankDto
): Promise<ApiResponse<CollegeRankDto>> {
  try {
    const collegeRankDto: CollegeRankDto = await API.post(
      'legacyAPI',
      '/college-rank',
      {
        body: { ...createCollegeRankDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: collegeRankDto,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}


export async function getCollegeRanks(

  college_program_id: number | undefined,
): Promise<ApiResponse<CollegeRankDto[]>> {
  try {
    const data: CollegeRankDto[] = await API.get(
      'legacyAPI',
      `/college-rank?college_program_id=${college_program_id}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function getAthleteRanksForFiltersList(

  college_program_id: number | undefined,
): Promise<ApiResponse<AthleteRankForFiltersList[]>> {
  try {
    const data: AthleteRankForFiltersList[] = await API.get(
      'legacyAPI',
      `/athlete-rank/filter-list?college_program_id=${college_program_id}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}


export async function updateCollegeRank(

  updateCollegeRankDto: CollegeRankDto
): Promise<ApiResponse<CollegeRankDto>> {
  try {
    let credentials: any = { ...updateCollegeRankDto };
    delete credentials["college_rank_id"]
    delete credentials["date_modified"]
    delete credentials["date_deleted"]
    delete credentials["rank_order"]

    const data: CollegeRankDto = await API.put(
      'legacyAPI',
      `/college-rank/${updateCollegeRankDto.college_rank_id}`,
      { body: { ...credentials } }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function deleteCollegeRank(college_rank_id: number, college_program_id: number): Promise<ApiResponse<null>> {
  try {
    await API.del('legacyAPI', `/college-rank/${college_rank_id}/${college_program_id}`, {});

    return {
      success: true,
      status: 200,
      data: null,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function reorderCollegeRanks(

  collegeRankReorderDtoArr: CollegeRankReorderDto[]
): Promise<ApiResponse<CollegeRankDto[]>> {
  try {
    const data: CollegeRankDto[] = await API.put(
      'legacyAPI',
      '/college-rank/sort',
      {
        body: collegeRankReorderDtoArr,
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}


export async function createAthleteRank(

  createAthleteRankDto: CreateAthleteRankDto
): Promise<ApiResponse<AthleteRankDto>> {
  try {
    const data: AthleteRankDto = await API.post(
      'legacyAPI',
      `/athlete-rank`,
      {
        body: { ...createAthleteRankDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}


export async function updateAthleteRank(

  athlete_rank_id: number,
  updateAthleteRankDto: UpdateAthleteRankDto
): Promise<ApiResponse<AthleteRankDto>> {
  try {
    const data: AthleteRankDto = await API.put(
      'legacyAPI',
      `/athlete-rank/${athlete_rank_id}`,
      {
        body: { ...updateAthleteRankDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function deleteAthleteRank(

  athlete_rank_id: number,
  college_program_id: number
): Promise<void> {
  await API.del(
    'legacyAPI',
    `/athlete-rank/${athlete_rank_id}/${college_program_id}`,
    {}
  );
}

export async function getCollegeGroups(): Promise<ApiResponse<CollegeGroupDto[]>> {
  try {
    const data: CollegeGroupDto[] = await API.get(
      'legacyAPI',
      `/college-rank-group/list?with_rank=1`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function createCollegeGroup(

  createCollegeRankDto: CreateCollegeGroupDto
): Promise<ApiResponse<CollegeGroupDto>> {
  try {
    const data: CollegeGroupDto = await API.post(
      'legacyAPI',
      '/college-rank-group',
      {
        body: { ...createCollegeRankDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}


export async function updateCollegeGroup(

  college_rank_group_id: number,
  updateCollegeGroupDto: CreateCollegeGroupDto
): Promise<ApiResponse<CollegeGroupDto>> {
  try {
    const data: CollegeGroupDto = await API.put(
      'legacyAPI',
      `/college-rank-group/${college_rank_group_id}`,
      { body: { ...updateCollegeGroupDto } }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function deleteCollegeGroup(college_rank_group_id: number, college_program_id: number): Promise<void> {
  await API.del('legacyAPI', `/college-rank-group/${college_rank_group_id}/${college_program_id}`, {});
}

export async function reorderCollegeGroup(

  collegeGroupReorderDtoArr: CollegeGroupReorderDto[]
): Promise<ApiResponse<CollegeGroupDto[]>> {
  try {
    const data: CollegeGroupDto[] = await API.put(
      'legacyAPI',
      '/college-rank-group/sort',
      {
        body: collegeGroupReorderDtoArr,
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}