import { useState, ChangeEvent } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import useAthleteRanksStyle from '../useAthleteRanksStyle';
import { CollegeRankDto, AthleteRankDto } from '../../../../../api/ranks';
import StyledInputRank from './StyledInputRank';
import StyledAutocompleteRank from './StyledAutocompleteRank';
import type { HistoryRank } from '../../athlete-ranks-history/types';



export type RankElementProps = {
  item: CollegeRankDto;
  athleteRanks: AthleteRankDto[] | HistoryRank[];
  deleteRank: (athlete_rank_id: number) => void;
  addNewRank: (newRank: string, college_rank_id: number) => Promise<AthleteRankDto | undefined>;
  updateRank: (newRank: string, athlete_rank_id: number) => Promise<AthleteRankDto | undefined>;
  disabledInput?: boolean;
}

const RankElement = (props: RankElementProps) => {
  const { item: collegeRank, athleteRanks, updateRank, addNewRank, deleteRank, disabledInput } = props;

  const [tooltipValue, setTooltipValue] = useState<string>(collegeRank.description ?? "");
  const [athleteRank, setAthleteRank] = useState<AthleteRankDto | HistoryRank | null>((athleteRanks as any).filter((i: AthleteRankDto | HistoryRank) => i.college_rank_id === collegeRank.college_rank_id).slice(-1)[0]);
  const [isChange, setIsChange] = useState<boolean>(false);

  const classes = useAthleteRanksStyle({ disabledInput });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'input') {
      if (collegeRank.type_value === 1) {
        return setAthleteRank({ ...athleteRank, value: value.replace(/[^\d]/g, '').slice(0, 2) } as AthleteRankDto)
      }
      else if (collegeRank.type_value === 2) {
        return setAthleteRank({ ...athleteRank, value: value.replace(/[^\D]/g, '').slice(0, 2) } as AthleteRankDto)
      }
      else if (collegeRank.type_value === 3) {
        let validateValue = value.slice(0, 1).replace(/[^\d]/g, '') + value.slice(1, 2).replace(/[^\D]/g, '')

        return setAthleteRank({ ...athleteRank, value: validateValue } as AthleteRankDto)
      }
      else if (collegeRank.type_value === 4) {
        let validateValue = value.slice(0, 1).replace(/[^\D]/g, '') + value.slice(1, 2).replace(/[^\d]/g, '')

        return setAthleteRank({ ...athleteRank, value: validateValue } as AthleteRankDto)
      }
      setAthleteRank({ ...athleteRank, value } as AthleteRankDto)
    };

    if (name === 'autocomplete') {
      if (value.length === 0 && athleteRank && (athleteRank as AthleteRankDto).athlete_rank_id) {
        deleteRank((athleteRank as AthleteRankDto).athlete_rank_id as number)
        setAthleteRank(null)
      }
    };
  };

  const handleChangeAutocomplete = async (value: string) => {
    setAthleteRank({ ...athleteRank, value } as AthleteRankDto);

    if(value.length === 0){
      deleteRank((athleteRank as AthleteRankDto).athlete_rank_id as number)
      return setAthleteRank(null)
    }
    else if (athleteRank && athleteRank.value.length > 0) {
      let updatedRank = await updateRank(value, (athleteRank as AthleteRankDto).athlete_rank_id)
      setAthleteRank(updatedRank as AthleteRankDto)
    }
    else {
      let addedRank = await addNewRank(value, collegeRank.college_rank_id)
      setAthleteRank(addedRank as AthleteRankDto)
    }
  };

  const handleClickAway = async () => {

    if (disabledInput) return

    setIsChange(false)

    if (athleteRank && (athleteRank as AthleteRankDto).athlete_rank_id && athleteRank.value.length > 0) {
      let updatedRank = await updateRank(athleteRank.value, (athleteRank as AthleteRankDto).athlete_rank_id)
      // let updatedRank = await addNewRank(athleteRank.value, item.college_rank_id)
      return setAthleteRank(updatedRank as AthleteRankDto)
    }

    if (athleteRank && (athleteRank as AthleteRankDto).athlete_rank_id && athleteRank.value.length === 0) {
      deleteRank((athleteRank as AthleteRankDto).athlete_rank_id)
      return setAthleteRank(null)
    }
    else {
      if (!athleteRank || !athleteRank.hasOwnProperty('value')) return
      if (athleteRank && athleteRank.value.length === 0) return

      let addedRank = await addNewRank(athleteRank.value, collegeRank.college_rank_id)
      return setAthleteRank(addedRank as AthleteRankDto)
    }
  }

  const handleKeyPress = (e: any) => {
    var key = e.keyCode || e.which;
    if (key === 13) {
      handleClickAway();
    }
  }

  function getTooltipForRankOption(option: string) {
    switch (option) {
      case '2': return 'Too Good For Us'
      case '1': return 'Would make us better'
      case '0': return 'Same as Current Roster'
      case '-1': return 'Not good enough'
      case '-2': return 'Not even close'

      default: return ''
    }
  }

  return (
    <>
      {
        collegeRank.values ?
          <Tooltip title={tooltipValue} arrow placement="top">
            <StyledAutocompleteRank
              options={athleteRank?.value ? ["", ...collegeRank.values as string[]]:collegeRank.values as string[]}
              onChange={(event, value: any) => {
                handleChangeAutocomplete(value)
              }}
              onOpen={() => setTooltipValue(collegeRank.title)}
              onClose={() => setTooltipValue(collegeRank.description)}
              renderOption={(option: any) => <span style={{minHeight:24}}>{collegeRank.title === 'Recruit' && option ? `"${option}" ${getTooltipForRankOption(option)}` : option}</span>}
              renderInput={(params) =>
                <StyledInputRank
                  placeholder={collegeRank.alias}
                  {...{ ...params, inputProps: { ...params.inputProps, maxLength: 2 } }}
                  name='autocomplete'
                  onChange={handleChangeInput}
                  isdeletedrank={athleteRank && athleteRank.hasOwnProperty('date_deleted') && (athleteRank as HistoryRank).date_deleted ? 'true' : ''}
                />
              }
              value={athleteRank && athleteRank.value ? athleteRank.value : ''}
              disabled={disabledInput}
            />
          </Tooltip>
          :
          !isChange ?
            <Tooltip title={collegeRank.description} arrow placement="top">
              <div className={classes.ranks} onClick={() => setIsChange(true)}>
                {athleteRank && athleteRank.value.length > 0 ?
                  (athleteRank.hasOwnProperty('date_deleted') && (athleteRank as HistoryRank).date_deleted ?
                    <span className={classes.colorRankValue} style={{ color: 'red' }}>{athleteRank.value}</span>
                    :
                    <span className={classes.colorRankValue}>{athleteRank.value}</span>
                  )
                  :
                  <span className={classes.colorRankPlaceholder}>{collegeRank.alias}</span>
                }
              </div>
            </Tooltip>
            :
            <ClickAwayListener onClickAway={handleClickAway}>
              <Tooltip title={collegeRank.title} arrow placement="top">
                <StyledInputRank
                  placeholder={collegeRank.alias}
                  value={athleteRank && athleteRank.value ? athleteRank.value : ''}
                  onChange={handleChangeInput}
                  name='input'
                  autoFocus
                  inputProps={{ maxLength: 2, onKeyPress: handleKeyPress }}
                  disabled={disabledInput}
                  isdeletedrank={athleteRank && athleteRank.hasOwnProperty('date_deleted') && (athleteRank as HistoryRank).date_deleted ? 'true' : ''}
                />
              </Tooltip>
            </ClickAwayListener>
      }
    </>
  );
};

export default RankElement;
