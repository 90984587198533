export const DURATION_SNACKBAR = 3000;
export const MAX_SHOW_SNACKBAR = 3;

export const MAX_SHOW_PRIMARY_TAGS = 7;
export const MAX_SHOW_REGULAR_TAGS = 4;

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
export const API_ERRORS = {
  UNAUTHORIZED: 'Request failed with status code 401',
}

export const TOOLBAR_HEIGHT = {
  desktop: 64,
  mobile: 56,
}

export const DRAWER_WIDTH = {
  desktop: 235,
  mobile: 73,
}

export const ATHLETE_APP_BAR = 50;

export const EMOJI_REGEXP = /([\p{Extended_Pictographic}])/gu

export const COACH_COLORS = [
  "007AFF",
  "60AF06",
  "FF7E29",
  "FF3200",
  "6940CC",
  "E359FF",
  "B15001",
  "BFEB0F",
  "00D2DF",
  "15858C",
  "FFC527",

  "055CA3",
  "8BB0F9",
  "417505",
  "D2000D",
  "BB328E",
  "E57D99",
  "EA3D6F",
  "D59B3C",
  "AD8CFF",
  "555FE0",
  "333333",
  "728FAB",
  "9B9B9B"
]

export const KANBAN_PAGE = '/boards'
