import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useScrollTrigger, Zoom } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import BaseFabButton from '../../shared/BaseFabButton';
import { FontSizes } from '../../../theme';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(10),
    right: 64,
    [theme.breakpoints.down('sm')]: {
      right: 29,
    },
  },
}));

function ScrollTop(props: { children: React.ReactElement, id: string }) {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      props.id,
    );

    if (anchor) {
      // this working in Safari with set experimental option CSSOM View Smooth Scrolling at 11 August 2021
      anchor.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  return (
    <Zoom in={trigger} timeout={500}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

const ButtonScrollTop = (props: { id: string }) => {
  return (
    <>
      <ScrollTop id={props.id}>
        <BaseFabButton
          bgColor={'#fff'}
          icon={<KeyboardArrowUpIcon />}
          sizeIcon={FontSizes.huge}
          p={8}
        />
      </ScrollTop>
    </>
  );
};

export default ButtonScrollTop;
