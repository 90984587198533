import { useEffect, useState, useRef } from 'react';
import AthleteOptions from './AthleteOptions';
import { useAppStateValue } from '../../../../state/StateContext';
import { AthleteListQueryDto, AthletesListDto } from '../../../../api/athletes';
import WrapperBlock from '../../shared/filter/WrapperBlock';
import AthleteOptions2 from './AthleteOptions2';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import AthleteAppBar from '../athlete-app-bar/AthleteAppBar';

const hideOptionsKeys = [
  'first',
  'last',
  'gradyears',
  'positions',
  'states',
  'team',
  'tournament',
  'is_evaluated',
  'tags',
  'ranks',
  'note',
  'tasks',
];

const viewOptions = {
  is_evaluated: 'Evaluated',
};

const initialState: AthleteListQueryDto = {
  first: '',
  last: '',
  gradyears: [] || '',
  positions: [] || '',
  states: [] || '',
  club: '',
  is_evaluated: false,
  tags: [] || '',
  ranks: [] || '',
  note: '',
  is_event: false,
  is_evaluated_event: false,
  team: '',
  tournament: null,
};

export type AthletesFilterProps = {
  athletesListDto: AthletesListDto;
  athletesChecked: number[];
  setAthletesChecked: any;
  submit: (filters: AthleteListQueryDto) => void;
}
const AthletesFilter = (props: AthletesFilterProps) => {
  const { athletesListDto, athletesChecked, setAthletesChecked } = props;
  const [{ athleteFilter, activeSport }, dispatch] = useAppStateValue();
  const [state, setState] = useState<AthleteListQueryDto>(athleteFilter);
  const athleteFilterRef = useRef<AthleteListQueryDto>();


  useEffect(() => () => {
    athleteFilterRef.current = state;
  }, [state]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'update', value: { athleteFilter: athleteFilterRef.current } })
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      setState(initialState)
    }
  }, [activeSport])

  const updateAthletesData = (data: AthleteListQueryDto) => {
    props.submit(data);
  };

  const submit = () => {
    updateAthletesData(state);
  };

  const clearFilters = () => {
    setState(initialState);
  };

  const onDeleteHideFilterOption = (value: { [key: string]: any }) => {
    setState((prev: any) => {
      const stateKey = Object.keys(value)[0];

      let newValue = '';

      if (value[stateKey]?.id) {
        newValue = prev[stateKey].filter((i: any) => i.id !== value[stateKey]?.id);
      }
      
      if (value[stateKey]?.athlete_rank_id) {
        newValue = prev[stateKey].filter((i: any) => i.athlete_rank_id !== value[stateKey]?.athlete_rank_id);
      }

      if (value[stateKey]?.college_task_id) {
        newValue = prev[stateKey].filter((i: any) => i.college_task_id !== value[stateKey]?.college_task_id);
      }

      if (value[stateKey]?.val) {
        newValue = prev[stateKey].filter((i: any) => i !== value[stateKey]?.val);
      }

      const newState = {
        ...prev,
        ...{
          [stateKey]: newValue,
        },
      };

      updateAthletesData(newState);
      
      return newState;
    });
  };

  return (
    <WrapperBlock
      showOptions={<AthleteOptions state={state} setState={setState} />}
      showOptions2={<AthleteOptions2 state={state} setState={setState} />}
      appBarOptions={Boolean(athletesListDto.athletes?.length) && <AthleteAppBar
        totalAthletes={athletesListDto.total}
        athletesChecked={athletesChecked}
        setAllAthletesChecked={() => setAthletesChecked(athletesListDto.athletes.map(a => a.athlete_master_id))}
        clearAllAthletesChecked={() => setAthletesChecked([])}
        changeParams={(params) => {
          const { sort_by, sort_order } = params;
          updateAthletesData({ ...state, sort_by, sort_order })
          setAthletesChecked([]);
        }}
      />}
      submit={submit}
      clearFilters={clearFilters}
      hideOptions={AthleteHelpers.objectToArray(state, hideOptionsKeys)}
      viewOptions={viewOptions}
      onDeleteHideOption={onDeleteHideFilterOption}
    />
  );
};

export default AthletesFilter;
