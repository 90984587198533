import React, { useCallback, useEffect, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { ATHLETE_APP_BAR, TOOLBAR_HEIGHT } from '../../constants';
import { useScroll } from '../../hooks/useScroll';
import { Colors } from '../theme';

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
};

const useStyles = makeStyles((theme: Theme) => ({
  container: (props: MainWrapperContainerProps) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    height: `calc(100% - ${TOOLBAR_HEIGHT.desktop}px)`,
    opacity: props.isLoading ? 0.4 : 1,
    pointerEvents: props.isLoading ? 'none' : 'inherit',
    [theme.breakpoints.down('sm')]: {
      height: `calc(100% - ${TOOLBAR_HEIGHT.mobile}px)`,
    },
  }),
  toolPanel: {
    ...boxStyle,
    flexWrap: 'nowrap',
    marginBottom: 18,
    flexShrink: 0,
    borderColor: 'transparent',
    boxShadow: `0 8px 8px -5px ${Colors.common.bgShadow}`,
    overflowY: 'unset',
    overflowX: 'unset',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  mainBlock: {
    ...boxStyle,
    flexDirection: 'column',
    height: '100%',
    flexWrap: 'nowrap',
    marginBottom: 27,
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      height: "auto",
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  spinner: {
    position: 'fixed',
    top: '50%',
    left: '50%',
  },
  sticky: {
    position: 'sticky',
    top: TOOLBAR_HEIGHT.desktop,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      top: TOOLBAR_HEIGHT.mobile,
      position: 'inherit',
      zIndex: 0,
      boxShadow: 'none',
    },
  },
}));

type MainWrapperContainerProps = {
  panelComponent?: JSX.Element;
  mainComponent: JSX.Element;
  isLoading?: boolean;
};
export default function MainWrapperContainer(props: MainWrapperContainerProps) {
  const classes = useStyles(props);

  return (
    <>
      {props.isLoading && <CircularProgress className={classes.spinner} color="secondary" />}

      <div className={classes.container}>

        {props.panelComponent && <>
          <div className={`${classes.toolPanel} ${classes.sticky}`}>
            {props.panelComponent}
          </div>
        </>}
        <div className={classes.mainBlock}>
          {props.mainComponent}
        </div>
      </div>
    </>
  );
}
