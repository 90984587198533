import React from 'react';
import { IconProps } from '../icons/index';

export const PlanIcon = (props: IconProps) => {
  return (
      <svg fill={props.color || '#e8e6e6'} width={props.width || '73px'} height={props.height || '84px'} preserveAspectRatio="xMidYMid meet" data-bbox="-0.004 24.97 199.704 150.03" xmlns="http://www.w3.org/2000/svg" viewBox="-0.004 24.97 199.704 150.03" role="presentation" aria-hidden="true">
        <g>
          <path d="M199.7 95.5c0-11.9-9-21.6-20-22.7V39c0-4.5-2.2-8.6-6.3-11.2-4.7-3-10.8-3.8-16-1.5L47.3 58.6H22C10.4 58.6.8 67.1.6 77.3L0 111.4c-.1 4.6 1.8 9.1 5.2 12.6 4.1 4.1 10 6.6 16.2 6.6h25.9l8.5 2.5-4.8 15.2c-2.6 8.2 1.9 17 10.1 19.6l20 6.4c1.6.5 3.2.7 4.7.7 6.6 0 12.8-4.2 14.9-10.9l5-15.7 51.6 15.9c2.2 1 4.6 1.5 7 1.5 3.2 0 6.3-.9 9-2.6 4.1-2.6 6.3-6.7 6.3-11.3v-33.8c11.1-.9 20.1-10.7 20.1-22.6zM21.4 121.6c-4 0-7.7-1.2-10.2-3.8-1.3-1.3-2.8-3.3-2.7-6.3l.6-33.9c.1-5.6 5.9-10 12.9-10h26.6l97.2-28.8V152l-97.2-30.4H21.4zM98 163.3c-2.1 6.7-9.4 10.4-16.1 8.3l-20-6.4c-6.7-2.1-10.4-9.4-8.3-16.1l4.8-15.1 44.5 13.7-4.9 15.6zm73.7-11.3c0 2.2-1.7 3.5-2.7 4.1-2.4 1.5-5.8 1.7-8.4.5l-11.9-3.6V38l11.4-3.4.6-.2c2.6-1.2 6-1 8.4.5.9.6 2.6 1.9 2.6 4.1v113zm8-36.6V75.7c10 1 17.2 9.5 17.2 19.9s-7.2 18.7-17.2 19.8z"></path>
        </g>
      </svg>
      );
};
