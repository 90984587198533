import React from 'react';
import { Box, Button, ClickAwayListener, TextareaAutosize } from '@material-ui/core';
import useSummaryStyle from '../useSummaryStyle';
import {
  TypeDialogSummary,
} from '../types';
// import BaseMenu, { AdditionalOptionType } from '../../../shared/BaseMenu';
// import AlertDialog from '../../../shared/AlertDialog';
import { CollegeNoteDto } from '../../../../../api/notes';

export type EditSummaryProps = {
  summary?: CollegeNoteDto;
  saveSummary: (updateSummary: CollegeNoteDto, summary?: CollegeNoteDto | undefined) => void;
  removeSummary: (deleteSummary: CollegeNoteDto) => void;
  closeSummary: () => void;
}
const EditSummary = (props: EditSummaryProps) => {
  const { summary, saveSummary, closeSummary } = props;

  const [value, setValue] = React.useState(summary?.note || '');

  const typeDialogSummaryRef = React.useRef<TypeDialogSummary | null>(null);

  const classes = useSummaryStyle();

  const handleSaveSummary = () => {
    if (value.trim() && value.trim() !== summary?.note) {
      saveSummary({ ...summary, note: value.trim() } as CollegeNoteDto, summary);
    }
    closeSummary();
  };

  const handleCancelSummary = () => {
    if (value.trim() && value.trim() !== summary?.note) {
      typeDialogSummaryRef.current = TypeDialogSummary.CLOSE;
      // setIsOpenDialog(true);
    } else {
      closeSummary();
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleCancelSummary}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <div className={classes.editNotes}>
            <TextareaAutosize
              className={classes.textarea}
              aria-label="note-textarea"
              placeholder='Changes will be seen by all staff'
              minRows={3}
              maxRows={5}
              value={value}
              onChange={e => setValue(e.target.value)}
            />

            <Box mb={1}>
              <Button
                disabled={!(value.trim() && value.trim() !== summary?.note)}
                variant="contained"
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  handleSaveSummary();
                }}
                className={classes.saveNoteBtn}
              >
                Save
              </Button>
              <Button
                className={classes.canselNoteBtn}
                color="secondary"
                onClick={e => {
                  e.stopPropagation();
                  handleCancelSummary();
                }}>
                Cancel
              </Button>
            </Box>
          </div>

        </Box>
      </ClickAwayListener>
    </>
  );
};

export default EditSummary;
