import { useMemo, useState } from "react";
import { useAppStateValue } from "../../../../../state/StateContext";

import { AthleteRankDto } from "../../../../../api/ranks";
import { HistoryRank } from "../../athlete-ranks-history/types";
import AthleteRanks from "../AthleteRanks";


interface Props {
	athleteRatings: AthleteRankDto[] | HistoryRank[];
	athlete_master_id: number;
}

export default function AthleteGeneralGroup(props: Props) {
	const { athleteRatings, athlete_master_id } = props;
	const [{ collegeGroups, account }] = useAppStateValue();

	const generalGroup = useMemo(() => {
		let group = collegeGroups.find(cg => cg.general)

		if (group) {
			group.ranks = group.ranks.filter(r => r.is_primary);
		}

		return group
	}, [collegeGroups, athleteRatings]);

	const [ranksList, setRanksList] = useState(getRatingList());

	function getRatingList() {
		const generalAthleteRatings = (athleteRatings as AthleteRankDto[] || []).filter(ar => ar.college_rank_group_id === generalGroup?.college_rank_group_id)
		var result = generalAthleteRatings.reduce((acc: any, item: any) => {
			if (item.college_rank_group_id) {
				if (acc[item.college_rank_group_id]) {
					acc[item.college_rank_group_id].push(item)
				} else {
					acc[item.college_rank_group_id] = [item]
				}
			}
			return acc
		}, {})

		for (const key in result) {
			result[key] = { usersRanks: result[key], colleaguesRanks: [] }
		}

		return result
	}

	return (
		<AthleteRanks
			ranksList={ranksList as any}
			athleteId={athlete_master_id}
			users_id={account?.id}
			groupId={generalGroup?.college_rank_group_id || 0}
			setRanksList={setRanksList}
		/>
	)
}