import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%'
    },
  },
  left: {
    marginRight: 30,
    width: '50%',
    '& p': {
      margin: '8px 0px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  right: {
    width: '50%',
    '& p': {
      margin: '8px 0px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: 0,
    },
  },
  label: {
    ...theme.typography.baseText,
    fontWeight: 'normal',
    fontSize: 16,
    margin: theme.spacing(1),
    width: 140,
    minWidth: 140,
    color: '#AFAFAF',
    lineHeight: '19px',
    [theme.breakpoints.down('md')]: {
      width: 100,
    },
  },
  text: {
    ...theme.typography.baseText,
    margin: theme.spacing(1),
    fontWeight: 500,
    fontSize: 16,
    color: '#32312F',
    lineHeight: '19px',
    wordBreak: 'break-word',
    minWidth: 'auto',
  },
  emptyText: {
    ...theme.typography.emptyText,
    margin: theme.spacing(1),
  },
}));

export type AthleteBlockDetailsType = {
  leftColumns: { label: string, value: string }[];
  rightColumns: { label: string, value: string }[];
}
export type BlockDetailsProps = {
  details: AthleteBlockDetailsType
}
const BlockDetails = (props: BlockDetailsProps) => {
  const { details: { leftColumns = [], rightColumns = [] } } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        {leftColumns.map(left => {
          return (
            <Box display="flex" key={left.label}>
              <Typography classes={{ root: classes.label }}>{left.label}</Typography>
              {Boolean(left.value) && <Typography classes={{ root: classes.text }}>{left.value}</Typography>}
              {!left.value && <Typography classes={{ root: classes.emptyText }}>empty</Typography>}
            </Box>
          );
        })}
      </div>
      <div className={classes.right}>
        {rightColumns.map(right => {
          return (
            <Box
              key={right.label}
              display="flex"
            >
              <Typography classes={{ root: classes.label }}>{right.label}</Typography>
              {Boolean(right.value) && <Typography classes={{ root: classes.text }}>{right.value}</Typography>}
              {!right.value && <Typography classes={{ root: classes.emptyText }}>empty</Typography>}
            </Box>
          );
        })}
      </div>
    </div>
  );
}

export default BlockDetails;
