import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AthleteTag from '../../athlete-tag/AthleteTag';

import useAthleteTagsStyle from '../useAthleteTagsStyle';
import { AthleteTagsType } from '../../../../../api/athletes';
import { CollegeTagsDto } from '../../../../../api/tags';
import ModalTransition from '../../../shared/ModalTransition';

export type ModalSelectTagsProps = {
  open: boolean;
  athleteName: string;
  regularTags: AthleteTagsType[];
  tags: CollegeTagsDto[];
  addItem: (tag: CollegeTagsDto) => void;
  deleteItem: (tag: AthleteTagsType) => void;
  closeWindow: () => void;
}
const ModalSelectTags = (props: ModalSelectTagsProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { open, athleteName, tags, regularTags, closeWindow, addItem, deleteItem } = props;

  const classes = useAthleteTagsStyle();

  return (
    <ModalTransition open={open} closeWindow={closeWindow}>
      <div className={classes.rootModalTags}>
        <Typography className={classes.athleteNameText}>{athleteName}</Typography>
        <div className={classes.contentModalTags}>
          <Typography component={'h6'} variant={'subtitle2'}>Assigned</Typography>

          <br/>

          {open ? (<div className={classes.assignedTags} style={{ pointerEvents: isLoading ? 'none' : 'inherit'}}>
            {regularTags.map(a => {
              return (
                <AthleteTag
                  key={a.college_tag_id}
                  tag={a}
                  handleClick={async (t) => {
                    setIsLoading(true);
                    await deleteItem(t as AthleteTagsType);
                    setIsLoading(false);
                  }}
                />
              );
            })}
          </div>): <></>}
        </div>

        <br/>

        <div className={classes.contentModalTags}>
          <Typography component={'h6'} variant={'subtitle2'}>Available</Typography>
          <br/>

          {open ? (<div className={classes.blockModalTags} style={{ pointerEvents: isLoading ? 'none' : 'inherit'}}>
            {tags.filter(r => !r.primary).map(r => {
              return (
                <AthleteTag
                  key={r.college_tag_id}
                  tag={r}
                  isDisable={true}
                  handleClick={async (t) => {
                    setIsLoading(true);
                    await addItem(t as CollegeTagsDto);
                    setIsLoading(false);
                  }}
                />
              );
            })}
          </div>): <></>}

        </div>
      </div>
    </ModalTransition>
  );
};

export default ModalSelectTags;
