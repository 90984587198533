import React from 'react';
import { Fade, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import useNoteStyle from '../athlete-notes/useNoteStyle';
import TeamNote from './TeamNote';
import EditTeamNote from './EditTeamNote';
import BaseButton from '../../shared/BaseButton';
import { createTeamNote, updateTeamNote, deleteTeamNote, TeamNoteDto } from '../../../../api/notes';
import DateHelpers from '../../../../helpers/DateHelpers';
import { AthletesListAthleteType } from '../../../../api/athletes';
import { useAppStateValue } from '../../../../state/StateContext';

export type TeamNotesProps = {
  athlete: AthletesListAthleteType;
  notes: TeamNoteDto[];
  updateAthleteNotes: (notes: TeamNoteDto[]) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const TeamNotes = (props: TeamNotesProps) => {
  const { athlete, notes, updateAthleteNotes, setIsLoading } = props;
  const [{ activeSport, account }] = useAppStateValue();
  const [isOpenNewNote, setIsOpenNewNote] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useNoteStyle();

  const saveNote = (updateNote: TeamNoteDto, note?: TeamNoteDto) => {
    setIsLoading(true)
    if (note?.team_note_id) {
      updateTeamNote(

        note.team_note_id,
        {
          team_master_id: updateNote.team_master_id,
          note: updateNote.note,
          college_program_id: updateNote.college_program_id,
          users_id: updateNote.users_id,
          // tournament_id: updateNote.tournament_id,
          uuid: updateNote.uuid,
        },
      ).then(res => {
        if (!res.success) {
          enqueueSnackbar(res.error?.message, { variant: 'error' });
          return setIsLoading(false);
        }
        enqueueSnackbar('Note updated successfully', { variant: 'success' });
        const teamNote: TeamNoteDto = res.data as TeamNoteDto;
        // Update note in state of athlete
        const idx = notes.findIndex(i => i.team_note_id === note?.team_note_id);
        const updateNotes = [
          { ...teamNote },
          ...notes.slice(0, idx),
          ...notes.slice(idx + 1),
        ];

        updateAthleteNotes(updateNotes);
        setIsLoading(false)
      });
    } else {
      createTeamNote({
        team_master_id: athlete.team_master_id as number,
        note: updateNote.note,
        college_program_id: (activeSport && activeSport.college_program_id) as number,
        users_id: (account && account.id) as number,
        // tournament_id: activeTournament,
        // uuid: updateNote.uuid,
      })
        .then(res => {
          if (!res.success) {
            enqueueSnackbar(res.error?.message, { variant: 'error' });
            setIsLoading(false)
          } else {
            enqueueSnackbar('Note created successfully', { variant: 'success' });
            const newNote = res.data as TeamNoteDto;
            // Create note in state of athlete
            const newNotes = [{ ...newNote, date_modified: new Date().toString(), }, ...notes,];
            updateAthleteNotes(newNotes);
            setIsLoading(false)
          }
        });
      setIsOpenNewNote(false);
    }
  };

  const deleteNote = (deleteNote: TeamNoteDto) => {
    const teamNoteId = deleteNote.team_note_id || 0;
    setIsLoading(true)
    try {
      deleteTeamNote(teamNoteId)
        .then(() => {
          enqueueSnackbar('Note removed successfully', { variant: 'success' });
          // Delete note in state of athlete
          updateAthleteNotes(notes.filter(n => n.team_note_id !== teamNoteId));
        });
    } catch (e) {
      enqueueSnackbar(e.message || 'Error removed note!', { variant: 'error' });
    }
    finally {
      setIsLoading(false)
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.notes}>

        {notes.map((note, i) => {
          return <TeamNote
            key={i}
            note={note}
            saveNote={saveNote}
            removeNote={deleteNote}
          />;
        })}
      </div>
      {!isOpenNewNote && <div style={{ width: '100%' }}>
        <BaseButton
          title={'+ Add Note'}
          customClass={classes.actionBtn}
          onClick={() => setIsOpenNewNote(true)}
        />
      </div>
      }
      {isOpenNewNote && <Fade in={isOpenNewNote}>
        <div className={classes.note}>
          <Typography className={classes.dateModified}>
            {DateHelpers.toLocaleDateString({ dateStr: new Date().toString() || '' })}
          </Typography>

          <EditTeamNote
            saveNote={saveNote}
            removeNote={deleteNote}
            closeNote={() => setIsOpenNewNote(false)}
          />
        </div>
      </Fade>}
    </div>
  );
};

export default TeamNotes;
