import { API } from 'aws-amplify';
import Qs from 'query-string';

import ApiResponse from './ApiResponse';
import { API_ERRORS, DEFAULT_ERROR_MESSAGE } from '../constants';


export type TeamDetailsDto = {
  team_master_id: number;
  team_name: string;
  age: number;
  division: string;
  state: string;
};

export type TeamStaffDto = {
  club_staff_master_id: number;
  first: string;
  last: string;
  role: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

export type TeamScheduleDto = {
  team_master_id: number;
  event_name: string;
  date_time: string;
  division: string;
  court: string;
  team_1_name: string;
  team_2_name: string;
  winning_team: string;
  scores: string;
};

export type TeamRosterDto = {
  team_roster_id: number;
  team_name: string;
  organization_code: string;
  age: number;
  rank: string;
  players: string;
};

// export interface TeamFilterDto {
//   club_master_id?: number;
//   club_name?: string;
//   club_region?: string;
//   club_state?: string;
//   is_search_team: boolean;
//   team_name?: string;
//   team_state?: string;
//   tournament_id?: number;
//   sort_by?: string;
//   sort_order?: 'ASC' | 'DESC';
//   page?: number;
//   page_length?: number;
// }

export interface TeamListDto {
  club_master_id: number;
  club_name: string;
  team_master_id: number;
  team_name: string;
  team_state: string;
  club_region: string;
  club_address1: string;
  club_state: string;
  club_city: string;
  club_zip: string;
}


export async function getTeamDetails(
  team_master_id: number,
): Promise<ApiResponse<TeamDetailsDto>> {
  try {
    const data: TeamDetailsDto = await API.get(
      'legacyAPI',
      `/team-details?team_master_id=${team_master_id}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function getTeamStaff(
  team_master_id: number,
): Promise<ApiResponse<TeamStaffDto[]>> {
  try {
    const data: TeamStaffDto[] = await API.get(
      'legacyAPI',
      `/team-staff?team_master_id=${team_master_id}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function getTeamSchedules(
  team_master_id: number,
  tournament_id?: number,
): Promise<ApiResponse<TeamScheduleDto[]>> {
  try {
    const data: TeamScheduleDto[] = await API.get(
      'legacyAPI',
      !tournament_id ?
        `/team-schedule?team_master_id=${team_master_id}` :
        `/team-schedule?team_master_id=${team_master_id}&tournament_id=${tournament_id}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function getTeamRoster(

  team_master_id: number,
  tournament_id?: number,
): Promise<ApiResponse<TeamRosterDto[]>> {
  try {
    const data: TeamRosterDto[] = await API.get(
      'legacyAPI',
      !tournament_id ?
        `/team-roster?team_master_id=${team_master_id}` :
        `/team-roster?team_master_id=${team_master_id}&tournament_id=${tournament_id}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}
