class DateHelpers {
  static toLocaleDateString(params: {
    dateStr: string | number,
    format?: string,
    options?: any,
  }): string {
    const { dateStr, format = 'en-US', options = { year: 'numeric', month: '2-digit', day: '2-digit' } } = params;

    return new Date(dateStr).toLocaleDateString(format, options);
  }

  static prepareFromToDatesString(dateStart: string, dateEnd: string): string {
    const yearStart = new Date(dateStart).getFullYear();
    const yearEnd = new Date(dateEnd).getFullYear();

    const options = { year: 'numeric', month: 'short', day: '2-digit' };

    if (yearStart !== yearEnd) {
      // Mar 13, 2020 - Mar 11, 2021
      return `${DateHelpers
        .toLocaleDateString({ dateStr: dateStart, options })} - ${DateHelpers
          .toLocaleDateString({ dateStr: dateEnd, options })}`;
    }

    const monthStart = new Date(dateStart).getMonth();
    const monthEnd = new Date(dateEnd).getMonth();

    const optionsMonth = { month: 'short', day: '2-digit' };

    if (monthStart !== monthEnd) {
      // Feb 20 - Mar 01, 2021
      return `${DateHelpers
        .toLocaleDateString({ dateStr: dateStart, options: optionsMonth })} - ${DateHelpers
          .toLocaleDateString({ dateStr: dateEnd, options })}`;
    }

    const dayStart = new Date(dateStart).getDate();
    const dayEnd = new Date(dateEnd).getDate();

    if (dayStart !== dayEnd) {
      const optionsDay = { day: '2-digit' };
      const optionsYear = { year: 'numeric' };
      // Feb 14 - 16, 2020
      return `${DateHelpers
        .toLocaleDateString({ dateStr: dateStart, options: optionsMonth })} - ${DateHelpers
          .toLocaleDateString({ dateStr: dateEnd, options: optionsDay })}, ${DateHelpers
            .toLocaleDateString({ dateStr: dateStart, options: optionsYear })}`;
    }

    // Feb 16, 2020
    return DateHelpers.toLocaleDateString({ dateStr: dateStart, options: options });

  }
}

export default DateHelpers;
