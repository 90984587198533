import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DesktopDetails from './DesktopDetails';
import MobileDetails from './MobileDetails';
import { AthletesListAthleteType } from '../../../../api/athletes';

const useStyles = makeStyles((theme: Theme) => ({
  desktop: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: 295,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      padding:'12px 0'
    },
  },
}));

export type AthleteDetailsProps = {
  athlete: AthletesListAthleteType;
}
const AthleteDetails = (props: AthleteDetailsProps) => {
  const { athlete } = props;
  const classes = useStyles(props);

  return (
    <>
      <DesktopDetails athlete={athlete} className={classes.desktop} />
      <MobileDetails athlete={athlete} className={classes.mobile} />
    </>
  );
};

export default AthleteDetails;
