import React from 'react';
import { Chip, makeStyles, Theme, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Colors, FontSizes } from '../../../theme';
import { AthleteTagsType } from '../../../../api/athletes';
import { CollegeTagsDto } from '../../../../api/tags';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import TagIcon from '../../shared/TagIcon';

type StyleProps = {
  height: number;
}
const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginRight: 12,
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
  primaryTag: (props: StyleProps) => ({
    backgroundColor: 'transparent',
    cursor: 'pointer',
    height: props.height,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  }),
  chipLabel: {
    display: 'none',
  },
}));

export type AthleteTagProps = {
  tag: AthleteTagsType | CollegeTagsDto;
  isDisable?: boolean;
  isArchive?: boolean;
  handleClick?: (tag: AthleteTagsType | CollegeTagsDto) => void;
  customClass?: string;
  isCloseIcon?: boolean;
  isPrimaryLabel?: boolean;
  height?: number;
  borderRadius?: number;
}
const AthleteTag = (props: AthleteTagProps) => {
  const {
    tag, isDisable = false, isArchive = false, handleClick, customClass = '', isCloseIcon = false, isPrimaryLabel = false,
    height = 21, borderRadius = (height || 21) / 2,
  } = props;
  const { title, icon, color, primary } = tag;

  const classes = useStyles({ height });

  if (primary) {
    return (
      <Tooltip title={title} placement="top-end" key={icon}>
        <span datatype='primary' className={classes.item} onClick={(event) => {
          if (isCloseIcon || !handleClick) return;
          event.stopPropagation();
          event.preventDefault();
          handleClick(tag);
        }}>
          <Chip
            classes={{ root: customClass || classes.primaryTag, label: isPrimaryLabel ? '' : classes.chipLabel }}
            style={{ paddingLeft: AthleteHelpers.isHaveIcon(icon) ? 10 : 2, borderRadius, textDecoration: isArchive ? 'line-through' : '' }}
            avatar={<TagIcon name={icon} color={isDisable || isArchive ? Colors.tag.bgPrimaryTag : color} />}
            label={isPrimaryLabel ? title : ''}
            variant={'default'}
            onDelete={(event) => {
              if (!(isCloseIcon && handleClick)) return;
              event.stopPropagation();
              event.preventDefault();
              handleClick(tag);
            }}
            deleteIcon={isCloseIcon ? <CloseIcon style={{ width: 16 }} /> : <></>}
          />
        </span>
      </Tooltip>);
  }

  return (
    <Tooltip title={title} placement="top-end" key={icon}>
      <span datatype='regular' className={classes.item} onClick={(event) => {
        if (isCloseIcon || !handleClick) return;
        event.stopPropagation();
        event.preventDefault();
        handleClick(tag);
      }}>
        <Chip
          avatar={AthleteHelpers.isHaveIcon(icon) ? <TagIcon
            name={icon} color={isDisable || isArchive ? color : Colors.tag.colorRegular}
            fontSize={FontSizes.extraMedium}
          /> : <></>}
          className={customClass}
          style={{
            color: isDisable || isArchive ? (color || '') : Colors.tag.colorRegular,
            backgroundColor: isDisable || isArchive ? Colors.tag.bgRegularTag : (color || ''),
            height,
            paddingLeft: AthleteHelpers.isHaveIcon(icon) ? 10 : 2,
            paddingRight: 2,
            borderRadius,
            cursor: 'pointer',
            textDecoration: isArchive ? 'line-through' : ''
          }}
        size="small"
        label={title}
        onDelete={(event) => {
          if (!(isCloseIcon && handleClick)) return;
          event.stopPropagation();
          event.preventDefault();
          handleClick(tag);
        }}
        deleteIcon={isCloseIcon ? <CloseIcon style={{ color: Colors.tag.colorRegular, width: 16 }} /> : <></>}
        />
      </span>
    </Tooltip>
  );
};

export default AthleteTag;
