import { API } from 'aws-amplify';

import ApiResponse from './ApiResponse';
import { API_ERRORS, DEFAULT_ERROR_MESSAGE } from '../constants';

export type CollegeTagsDto = {
  college_tag_id: number;
  date_modified?: string;
  date_deleted?: string | null;
  college_program_id: number | null;
  title: string;
  description: string | null;
  color: string | null;
  icon: string | null;
  primary: boolean;
  sort_order: number;
  tag_type: 'done' | 'omit' | 'default' | null;
};

export type AthleteTagsDto = {
  athlete_tag_id: number;
  college_tag_id: number;
  date_created?: string;
  date_modified?: string;
  athlete_master_id: number;
  college_program_id: number;
  tournament_id: number;
  users_id: number;
};

export type CreateCollegeTagDto = {
  title: string;
  description: string | null;
  color: string;
  icon: string | null;
  primary: boolean;
  sort_order?: number;
};

export type CollegeTagReorderDto = {
  college_tag_id: number;
  sort_order: number;
  college_program_id?: number;
};

export type CreateAthleteTagDto = {
  tournament_id: number;
  athlete_master_id: number;
  college_program_id?: number;
};

export async function getCollegeTags(): Promise<ApiResponse<CollegeTagsDto[]>> {
  try {
    const data: CollegeTagsDto[] = await API.get(
      'legacyAPI',
      '/college-tags',
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function createCollegeTag(
  createCollegeTagDto: CreateCollegeTagDto,
  college_program_id?: number
): Promise<ApiResponse<CollegeTagsDto>> {
  try {
    const data: CollegeTagsDto = await API.post('legacyAPI', '/college-tags', {
      body: { ...createCollegeTagDto, college_program_id },
    });

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.response?.data?.statusCode,
        message: error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        name: error.response?.data?.error,
      },
    };
  }
}

export async function reorderCollegeTags(

  collegeTagReorderDtoArr: CollegeTagReorderDto[]
): Promise<ApiResponse<CollegeTagsDto[]>> {
  try {
    const data: CollegeTagsDto[] = await API.put(
      'legacyAPI',
      '/college-tags/order',
      {
        body: collegeTagReorderDtoArr,
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function updateCollegeTag(
  id: number,
  createCollegeTagDto: CreateCollegeTagDto,
): Promise<ApiResponse<CollegeTagsDto>> {
  try {
    const data: CollegeTagsDto = await API.put(
      'legacyAPI',
      `/college-tags/${id}`,
      {
        body: { ...createCollegeTagDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.response?.data?.statusCode,
        message: error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        name: error.response?.data?.error,
      },
    };
  }
}

export async function archiveCollegeTag(id: number): Promise<void> {
  await API.del('legacyAPI', `/college-tags/${id}`, {});
}

export async function createAthleteTag(

  id: number,
  createAthleteTagDto: CreateAthleteTagDto
): Promise<ApiResponse<AthleteTagsDto>> {
  try {
    const data: AthleteTagsDto = await API.post(
      'legacyAPI',
      `/athlete-tags/${id}`,
      {
        body: { ...createAthleteTagDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function deleteAthleteTag(id: number): Promise<void> {
  await API.del('legacyAPI', `/athlete-tags/${id}`, {});
}
