import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { ClickAwayListener } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useAthleteTagsStyle from '../useAthleteTagsStyle';
import { CollegeTagsDto } from '../../../../../api/tags';
import AthleteTag from '../../athlete-tag/AthleteTag';

export type SelectedTagsProps = {
  items: CollegeTagsDto[];
  selectedTagsMap: Record<string, boolean>;
  selectItem: (tag: CollegeTagsDto) => void;
  closeWindow: () => void;
  className?: string;
  style?: any;
}
const SelectedTags = (props: SelectedTagsProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const classes = useAthleteTagsStyle();

  return (
    <ClickAwayListener onClickAway={props.closeWindow}>
      <div
        className={`${props.className || ''}`}
        style={props.style || {}}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          mr={5}
          p={2}
          style={{ pointerEvents: isLoading ? 'none' : 'inherit' }}
        >
          {props.items.filter(i => !i.primary && !props.selectedTagsMap[i.college_tag_id]).map(t => {
            return (
              <AthleteTag
                key={t.college_tag_id}
                tag={t}
                isDisable={true}
                handleClick={async (tag) => {
                  setIsLoading(true);
                  await props.selectItem(tag as CollegeTagsDto);
                  setIsLoading(false);
                }}
              />
            );
          })}
        </Box>
        <CloseIcon
          classes={{ root: classes.closeIcon }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            props.closeWindow();
          }}/>
      </div>
    </ClickAwayListener>
  );
};

export default SelectedTags;
