import React from 'react';
import { Grid, Typography, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AthleteVideoType } from '../../../../api/athletes';
import { Colors, FontSizes } from '../../../theme';
import HtmlHelpers from '../../../../helpers/HtmlHelpers';
import veoImage from '../../../../assets/veo.png';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  block: {
    display: 'block',
  },
  vid: {
    width: '100%',
    // maxWidth: 340,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 327,
    },
  },
  title: {
    ...theme.typography.baseText,
    width: 295,
    alignSelf: 'flex-start',
    fontWeight: 500,
    fontSize: FontSizes.extraMedium,
    color: Colors.athlete.video.title,
  },
  textDate: {
    ...theme.typography.baseText,
    color: Colors.note.textDate,
    padding: 0,
    lineHeight: '24px',
  },
}));

type AthleteVideosProps = {
  videos: AthleteVideoType[];
}
const AthleteVideos = (props: AthleteVideosProps) => {
  const { videos } = props;

  const classes = useStyles();

  if (!videos.length) {
    return (
      <Box display={'flex'} justifyContent={'center'} m={2}>
        <Typography classes={{ root: classes.textDate }}>
          No information yet
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={3} classes={{ root: classes.container }}>
      {videos.map((video, idx) => {
        return (
          <>
            {!video.provider_url.includes('app.veo.co') ?
              <Grid item xs={12} md={6} lg={4} xl={3} key={`video${idx}`} className={classes.card}>
                <div className={classes.block}>
                  <video
                    id={`video${idx}`}
                    tabIndex={idx}
                    className={classes.vid}
                    controls
                    disablePictureInPicture
                    controlsList={'nodownload'}
                    src={`${video.provider_url}#t=0.001`}
                  />
                  <Typography classes={{ root: classes.title }}>{video.title}</Typography>
                </div>
              </Grid>
              :
              <Grid item xs={12} md={6} lg={4} xl={3} className={classes.card}>
                <div className={classes.block}>
                  <div className={classes.vid}
                    style={{
                      background: 'black',
                      height: 198, cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 8
                    }}
                    onClick={() => HtmlHelpers.prepareHtmlElementForOpenUrl(video.provider_url).click()}
                  >
                    <img src={veoImage} alt={video.provider_url} style={{ width: '50%' }} />
                  </div>
                  <Link classes={{ root: classes.title }}
                    href={video.provider_url}
                    target="_blank"
                    rel="noopener"
                  >
                    {video.title}
                  </Link>
                </div>
              </Grid>
            }
          </>
        );
      })}
    </Grid>
  );
};

export default AthleteVideos;
