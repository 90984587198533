import { makeStyles, Theme } from '@material-ui/core/styles';
import { athleteColumns } from '../athleteListSettings';
import { Colors, FontSizes } from '../../../theme';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 'auto',
    minHeight: 53,
    flexBasis: athleteColumns[1].flexBasis,
    flexGrow: athleteColumns[1].flexGrow,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      flexGrow: 0,
      width: '100%',
      flexBasis: 'auto',
    },
  },
  tags: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'relative',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },
  menuDesktop: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    height: '100%',
    width: 55,
    marginRight: '12px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  verticalDivider: {
    color: Colors.athlete.colDividerBody,
    height: 35,
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  selectTags: {
    zIndex: 1298,
    position: 'absolute',
    right: 0,
    marginTop: theme.spacing(1),
    minHeight: 60,
    ...theme.typography.baseBorder,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
  },
  actionBtn: {
    ...theme.typography.actionBtn,
    marginLeft: theme.spacing(3),
    '&:hover':{
      backgroundColor: Colors.common.hoverOnWhite,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  newTag: {
    marginLeft: 'auto',
    marginRight: 14,
    [theme.breakpoints.down('sm')]: {
      // width: 35,
    },
  },
  rootModalTags: {
    minWidth: 275,
    maxWidth: 500,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 275,
    },
  },
  contentModalTags: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  assignedTags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
  },
  blockModalTags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  athleteNameText: {
    ...theme.typography.baseText,
    textDecoration: 'none',
    fontWeight: 400,
    fontSize: FontSizes.preLarge,
    paddingLeft: 14,
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    position: 'absolute',
    right: 5,
    top: 3,
    cursor: 'pointer',
    padding: 9,
    width: 35, // TODO: 24 - in storybook this size ok, but in site very small
    height: 35, // TODO: 24 - in storybook this size ok, but in site very small
    borderRadius: '50%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Colors.common.hoverOnWhite,
    },
  },
  blockTags: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    width: '100%',
  },
}));
