import React from 'react';
import Button from '@material-ui/core/Button';
import { PropTypes } from '@material-ui/core';

export type BaseButtonProps = {
  title: string | React.ReactNode;
  StartIcon?: React.ReactNode;
  EndIcon?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  color?: PropTypes.Color;
  customStyle?: any;
  customClass?: string;
  disabled?: boolean;
  onClick?: (params: any) => void;
}
const BaseButton = (props: BaseButtonProps) => {
  const {
    title,
    customStyle = {},
    customClass = '',
    color = 'inherit',
    variant = 'text',
    size='medium',
    StartIcon = null,
    EndIcon = null,
    disabled = false,
    onClick,
  } = props;

  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      style={customStyle}
      className={customClass}
      startIcon={StartIcon}
      endIcon={EndIcon}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

export default BaseButton;
