import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Colors, ColorTypes, FontSizes } from '../../../theme';

export default makeStyles((theme: Theme) => ({
  athTopOptionsWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 15,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    color: '#0F2B5B',
    paddingLeft: 10,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
  },
  athBottomOptionsWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
    color: '#0F2B5B',
  },
  formControlWrap: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      paddingLeft: 14,
    },
  },
  formControlSwitch: {
    display: 'flex',
    minHeight: 40
  },
  formControlLabel: {
    display: 'flex',
    marginRight: 0,
    '& span': {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      '& span': {
        fontSize: 16,
      },
    }
  },

  input: {
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 145,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 600,
    },
  },

  inputForTags: {
    '& [class*="MuiOutlinedInput-root"]': {
      paddingTop: '5px !important',
      paddingBottom: '5px !important',
      paddingRight: '55px !important',
      '& .MuiAutocomplete-input': {
        minWidth: 5,
      }
    },
  },

  evalsCountBtn: {
    color: Colors.default[ColorTypes.secondary],
    borderColor: '#F68B16',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 56,
      fontSize: 18,
    },
  },

  btn: {
    fontFamily: 'Roboto',
    fontSize: FontSizes.extraMedium,
    height: 36,
    width: 85,
    lineHeight: '14px',
    boxShadow: 'none',
    opacity: 0.8,
    textTransform: 'capitalize',
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
      width: '25%',
      height: 48,
      fontSize: FontSizes.preLarge,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 56,
    },
  },

  actionBlock: {
    display: 'flex',
    marginTop: 22,
    alignItems: 'center',
  },
}));
