import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import MainWrapperContainer from '../blocks/MainWrapperContainer';
import AthleteList from '../blocks/athletes/AthleteList';
import AthletesFilter from '../blocks/athletes/athlete-filter/AthletesFilter';
import { useAppStateValue } from '../../state/StateContext';
import ApiResponse from '../../api/ApiResponse';
import { AthleteListQueryDto, AthletesListDto, getAthletes } from '../../api/athletes';
import empty from '../../assets/empty.svg';
import ButtonScrollTop from '../blocks/athletes/fragments/ButtonScrollTop';
import { useSearchAthleteStateValue } from '../../contexts/SearchAthletesContext';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '404px',
    maxHeight: '343px',
  },
  emptyBlock: {
    width: '100%',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    backgroundColor: '#F7F6FB',
    borderRadius: 8,
  },
}));

export default function Search() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [{
    activeSport,
    // athleteList,
    athleteListQuery,
  }, dispatch] = useAppStateValue();

  const [{ athletes }, dispatchSearchAthlete] = useSearchAthleteStateValue();

  const [isPressedSearch, setIsPressedSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [athletesChecked, setAthletesChecked] = useState<number[]>([]);
  const [athletesListDto, setAthletesListDto] = useState<AthletesListDto>({ athletes, total: 0 });

  useEffect(() => {
    return () => {
      setAthletesListDto({ athletes: [], total: 0 })
    }
  }, [activeSport])

  useEffect(() => {
    setAthletesListDto(prev => ({ ...prev, athletes }))
  }, [athletes])

  const searchFilter = useRef<AthleteListQueryDto>(athleteListQuery);

  const handleAthleteChecked = (athleteId: number, isCheck: boolean) => {
    setAthletesChecked(prev => {
      if (!isCheck) {
        return prev.filter(i => i !== athleteId);
      }

      return prev.includes(athleteId) ? prev : [...prev, athleteId];
    });
  };


  const search = async (page: number) => {
    setIsPressedSearch(true);
    setIsLoading(true);
    setAthletesListDto({ athletes: [], total: 0 })
    const athletesDto: ApiResponse<AthletesListDto> = await getAthletes({ ...searchFilter.current, college_program_id: activeSport?.college_program_id as number, page });

    if (athletesDto.success) {
      // const athleteList = athletesDto.data as AthletesListDto;
      setAthletesListDto(athletesDto.data as AthletesListDto);
      dispatch({ type: 'update', value: { athleteListQuery: searchFilter.current } });

      dispatchSearchAthlete({ type: 'updateState', value: { athletes: athletesDto.data.athletes } });
    } else {
      enqueueSnackbar(athletesDto.error?.message, { variant: 'error' });
    }
    setIsLoading(false);

  };

  return (
    <>
      <div id="back-to-top-search" />
      <MainWrapperContainer
        isLoading={isLoading}
        panelComponent={
          <AthletesFilter
            athletesListDto={athletesListDto}
            athletesChecked={athletesChecked}
            setAthletesChecked={setAthletesChecked}
            submit={(filters: AthleteListQueryDto) => {
              searchFilter.current = { ...searchFilter.current, ...filters, page: 1 } as unknown as AthleteListQueryDto;
              setAthletesChecked([]);
              search(1);
            }}
          />}
        mainComponent={
          <>
            {!isPressedSearch && <div className={classes.emptyBlock}>
              <img
                src={empty}
                className={classes.image}
                alt="search-placeholder"
              />
            </div>}
            {isPressedSearch && <>
              <AthleteList
                athletesListDto={athletesListDto}
                page={searchFilter.current.page || 1}
                athletesChecked={athletesChecked}
                handleAthleteChecked={handleAthleteChecked}
                changeParams={(params: { page: number }) => {
                  searchFilter.current = { ...searchFilter.current, ...params };
                  setAthletesChecked([]);
                  search(params.page);
                }}
              />
            </>}
          </>
        }
      />
      {Boolean(athletesListDto.athletes?.length) && <ButtonScrollTop id={'#back-to-top-search'} />}
    </>
  );
}
