import React, { MutableRefObject } from 'react';
import { AthleteGenderedPositions, CountryGroup, GroupedListType, PositionType, regionList, statesList } from './data';
import { CollegeTagsDto } from '../api/tags';
import { AthleteTagsResponse, AthleteTagsType, HistoryTags } from '../api/athletes';
import { Colors, FontSizes } from '../component/theme';
import { CollegeNoteDto } from '../api/notes';
import { AthleteBlockDetailsType } from '../component/blocks/athletes/athlete-details/BlockDetails';
import DateHelpers from './DateHelpers';
import { AthleteHistoryTags } from '../component/blocks/athletes/athlete-tags-history/types';

class AthleteHelpers {

  static get currentSeason(): number {
    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // 0-11

    // > August
    if (currentMonth > 8) {
      currentYear++;
    }

    return currentYear;
  }

  static get gradYearList(): number[] {
    let startYear = AthleteHelpers.currentSeason;
    let list: number[] = [];
    const currentYear = new Date().getFullYear();
    for (; startYear < currentYear + 5; startYear++) {
      list.push(startYear);
    }
    return list;
  }

  static getPositionsBySport(sportId: number): PositionType[] {
    return AthleteGenderedPositions[sportId] || [];
  }

  static getStatesByCountry(country: CountryGroup = CountryGroup.USA): GroupedListType | undefined {
    return statesList.find(item => item.group === country);
  }
  static getRegionsByCountry(country: CountryGroup = CountryGroup.usaRegions): GroupedListType | undefined {
    return regionList.find(item => item.group === country);
  }

  static filterAthleteTags(collegeTags: CollegeTagsDto[] = [], athleteTags: AthleteTagsResponse[] = []): AthleteTagsType[] {
    return athleteTags.reduce((res: AthleteTagsType[], curr: AthleteTagsResponse) => {
      const tag = collegeTags.find(t => t.college_tag_id === curr.college_tag_id);

      if (!tag) return res;

      const { icon, color, primary, title, tag_type, sort_order } = tag;
      return [...res, { ...curr, icon, color, primary, title, tag_type, sort_order } as AthleteTagsType];
    }, []).sort(this.reorderTags);
  }

  static prepareAthleteDetailsData(
    athlete: any,
    titles: Array<{ title: string, name: string, value: string, fn?: (value: string | number) => void }[]>,
  ): AthleteBlockDetailsType {
    return {
      leftColumns: titles[0].map(left => {
        return {
          label: left.title,
          value: left.fn ? (left.fn(athlete[left.name])) : athlete[left.name],
        };
      }),
      rightColumns: titles[1].map(right => {
        return {
          label: right.title,
          value: right.fn ? (right.fn(athlete[right.name])) : athlete[right.name],
        };
      }),
    };
  }

  static formatDateToLocaleDateString(value?: string | number) {
    return value && DateHelpers.toLocaleDateString({ dateStr: Number(value) * 1000 });
  }

  static toInches(val: number | string): string | null {
    if (val === 0) return null;
    const absNum = Math.abs(+val);
    const negative = absNum !== val;
    const inc = `${Math.floor(absNum / 12)}'${((absNum % 12) < 10) ? 0 : ''}${(absNum % 12)}"`;
    return `${negative ? '-' : ''}${inc}`;
  }

  static prepareVisualOfArray(values: (string | number)[] = []): React.ReactElement | null {
    if (!values.length) return null;
    return <>
      <span>{values[0]}</span>
      {values.length > 1 ?
        <span
          style={{ color: Colors.note.textDate, fontSize: FontSizes.small }}
        >
          +{values.length - 1}
        </span>
        :
        null}
    </>;
  }

  static reorderTags(a: CollegeTagsDto | AthleteTagsType, b: CollegeTagsDto | AthleteTagsType): number {
    if (a.tag_type === 'omit' || b.tag_type === 'omit') return Number(a.tag_type === 'omit') - 0.5;
    if (a.primary !== b.primary) return Number(b.primary) - 0.5;
    return a.sort_order - b.sort_order;
  };

  static scrollToRef = (
    ref: MutableRefObject<HTMLElement | null>,
    additional?: number,
  ) => {
    return ref.current ? window.scrollTo(0, ref.current.offsetTop - (additional || 0)) : null;
  };

  static reorderNotes(a: CollegeNoteDto, b: CollegeNoteDto): number {
    if ((a.export_pda === 2) && (2 === b.export_pda)) {
      const date1 = a.date_modified ? new Date(a.date_modified).getTime() : 0;
      const date2 = b.date_modified ? new Date(b.date_modified).getTime() : 0;
      return date2 - date1;
    }
    return Boolean(a.export_pda === 2) ? -1 : 1;
  };

  static objectToArray(obj: { [key: string]: any }, filterKeys?: string[]): { [key: string]: any }[] {
    let keys = Object.keys(obj);

    if (filterKeys && filterKeys.length > 0) {
      keys = keys.filter(i => filterKeys.includes(i));
    }

    return keys.map(k => ({ [k]: obj[k] }));
  }

  static isHaveIcon(icon?: string | null): boolean {
    return !!(icon && icon !== 'default');
  }

  static prepareAthleteTagsHistory(historyTags: HistoryTags[], collegeTags: CollegeTagsDto[], deletedTags: CollegeTagsDto[]): AthleteHistoryTags[] {
    return historyTags.map(historyTag => {
      let tags: (CollegeTagsDto & { is_deleted: boolean, is_archived: boolean })[] = [];

      for (let itemTag of historyTag.tags) {
        const tag = collegeTags.find(t => t.college_tag_id.toString() === itemTag.college_tag_id);

        if (!tag) {
          const archivedTag = deletedTags.find(t => t.college_tag_id.toString() === itemTag.college_tag_id);

          if (archivedTag) {
            tags.push({ ...archivedTag, is_deleted: itemTag.is_deleted, is_archived: true });
          }
          continue;
        }
        else {
          tags.push({ ...tag, is_deleted: itemTag.is_deleted, is_archived: false });
        }
      }

      return {
        ...historyTag,
        tags,
      };
    });
  }
}

export default AthleteHelpers;
