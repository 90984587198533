import { TextField, styled } from '@material-ui/core';
import theme, { Colors } from '../../../../theme';

const StyledInputRank = styled(TextField)((
  props: {
    isdeletedrank: string;
  }
) => ({
  marginLeft: 10,

  '& .MuiOutlinedInput-root': {
    backgroundColor: '#EAEFF5',
    width: 28,
    height: 32,
    // fontSize: FontSizes.medium,
    borderRadius: 4,
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
  },
  '& .MuiInputLabel-outlined': {
    color: Colors.filter.input.labelColor,
    lineHeight: 0,
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, 0px) scale(0.75)',
    color: Colors.filter.input.shrinkLabelColor,
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  '& .MuiOutlinedInput-input': {
    ...theme.typography.baseText,
    color: Boolean(props.isdeletedrank) ? 'red' : Colors.default.secondary,
    padding: '1px 1px 1px 1px',
    borderRadius: 4,
    textAlign: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.filter.input.background,
  },
  '& .Mui-focused': {
    color: Colors.filter.input.labelColor,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.filter.input.focusedBorderColor,
    },
  },
}),
);

StyledInputRank.defaultProps = { variant: 'outlined', autoComplete: 'off', inputProps: { maxLength: 2 } };

export default StyledInputRank;
