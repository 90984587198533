import React, { useEffect } from 'react';
import Autocomplete, { AutocompleteGetTagProps, AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import { ItemType } from './types';
import BaseInput from './BaseInput';
import TagIcon from './TagIcon';
import { TagNames } from '../../../assets/icons/collegeTags';
import { Colors, ColorTypes, FontSizes } from '../../theme';
import AthleteHelpers from '../../../helpers/AthleteHelpers';
import { AthleteRankForFiltersList } from '../../../api/ranks';
import { CollegeTasksDto } from '../../../api/tasks';

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: BaseAutocompleteMainProps<any, any>) => ({
    margin: props.marginInput || 0,
  }),
  option: {
    margin: 2,
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.common.activeState} !important`,
    },
    '&:hover': {
      backgroundColor: Colors.common.hoverOnWhite,
    },
  },
  popupIndicator: {
    '& svg': {
      color: theme.palette.common.black,
    },
  },
  inputRoot: {
    maxHeight: 44,
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 60,
    },
    '&[class*="MuiOutlinedInput-root"]': {
      padding: '0px !important',
      paddingLeft: '8px !important',
      '& .MuiAutocomplete-input:first-child': {
        paddingRight: 60,
      }
    },
  },
  clearIndicatorDirty: {
    visibility: 'visible',
  },
  textField: {
    margin: 0,
  },
  tagsAutocomplete: {
    maxWidth: 70,
    backgroundColor: Colors.rank.bgRank,
    color: '#1B4484',
    border: '1px solid #DBE3EE',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150
    },
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      boxShadow: '4px 8px 20px rgba(0, 0, 0, 0.12)',
    },
  },

}));

interface BaseAutocompleteMainProps<IT = any, M extends boolean = true> {
  value: any;
  label?: string;
  multiple?: M;
  groupByKey?: keyof IT,
  disableCloseOnSelect?: boolean;
  limitTags?: number;
  noOptionsText?: string;
  minWidth?: string | number;
  maxWidth?: string | number;
  marginInput?: string | number;
  showId?: boolean;
  className?: string;
  setValues: (values: M extends true ? IT[] : IT) => void;
}

export interface BaseAutocompleteProps<IT = any, M extends boolean = true> extends BaseAutocompleteMainProps<IT, M> {
  options: IT[]
  groupOptions?: void
}

export interface BaseAutocompleteGroupProps<IT = any, M extends boolean = true> extends BaseAutocompleteMainProps<IT, M> {
  groupOptions: { group: string, opts: IT[] }
  options?: void
}

function BaseAutocomplete<IT = any, M extends boolean = true>(props: BaseAutocompleteProps<IT, M> | BaseAutocompleteGroupProps<IT, M>) {
  const {
    value: currentValue, multiple = true, disableCloseOnSelect = true, limitTags = 1,
    noOptionsText = 'No data', showId = false, label = '', setValues, groupByKey,
  } = props;

  const inputRef = React.useRef<any>(null);

  const [open, setOpen] = React.useState(false);

  const classes = useStyles(props);

  useEffect(() => {
    open ? setFocused() : setUnFocused();
  }, [currentValue]);

  const setFocused = () => {
    if (inputRef.current && !inputRef.current.parentElement?.classList.contains('Mui-focused')) {
      inputRef.current.parentElement?.classList.add('Mui-focused');
    }
  };

  const setUnFocused = () => {
    if (inputRef.current && inputRef.current.parentElement?.classList.contains('Mui-focused')) {
      inputRef.current.parentElement?.classList.remove('Mui-focused');
    }
  };

  const getRenderValue = (option: ItemType | AthleteRankForFiltersList | CollegeTasksDto | IT) => {
    if (typeof option === 'string' || typeof option === 'number') return `${option}`;

    if ((option as AthleteRankForFiltersList).athlete_rank_id) {
      return (option as AthleteRankForFiltersList).rank
    }

    if ((option as CollegeTasksDto).college_task_id) {
      return (option as CollegeTasksDto).task
    }

    const value = (option as { id: string | number, name: string, shortName?: string });
    return value?.shortName ?
      `${value.shortName}`
      :
      showId ? value?.id : value?.name || '';
  };

  const getOptionLabel = (option: ItemType | AthleteRankForFiltersList | CollegeTasksDto | IT) => {
    if (typeof option === 'string' || typeof option === 'number') return `${option}`;

    if ((option as AthleteRankForFiltersList).athlete_rank_id) {
      return (option as AthleteRankForFiltersList).rank
    }

    if ((option as CollegeTasksDto).college_task_id) {
      return (option as CollegeTasksDto).task
    }

    const value = (option as { id: string | number, name: string, shortName?: string });
    return value?.shortName ?
      `${value?.name} (${value?.shortName})`
      :
      showId ? `${value?.name} (${value?.id})` : value?.name || '';
  };

  const groupByFn = (
    (groupByKey ? (option: IT): any => option[groupByKey] : undefined)
    || ((props.options && props.options.length > 0 && (props.options[0] as any).athlete_rank_id) ? (option: any): string => option.group : undefined)
    // @ts-ignore
    || (props.groupOptions ? (): string => props.groupOptions.group : undefined)
  )

  return (
    <Autocomplete
      className={props.className || ''}
      popupIcon={<ExpandMoreIcon />}
      id='baseAutocompleteAAA'
      value={currentValue}
      classes={{
        root: classes.root,
        inputRoot: classes.inputRoot,
        clearIndicatorDirty: classes.clearIndicatorDirty,
        option: classes.option,
        popupIndicator: classes.popupIndicator,
        paper: classes.paper
      }}
      onOpen={() => {
        setOpen(true);
        setFocused();
      }}
      onClose={() => {
        setOpen(false);
        setUnFocused();
      }}
      disableCloseOnSelect={disableCloseOnSelect}
      multiple={Boolean(multiple)}
      limitTags={limitTags}
      options={props.groupOptions?.opts || props.options || []}
      noOptionsText={noOptionsText}
      groupBy={groupByFn}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option: any, value: any) => {
        if (option.athlete_rank_id) {
          return option?.athlete_rank_id === value?.athlete_rank_id
        }
        if (option.college_task_id && option.group) { // grouped tasks
          return option?.college_task_id === value?.college_task_id && option?.task === value?.task
        }
        if (option.college_task_id) {
          return option?.college_task_id === value?.college_task_id
        }
        if (option.college_user_id && option.users_id) { // collegeCoaches
          return option?.college_user_id === value?.college_user_id
        }
        else {
          return typeof value === 'string' || typeof value === 'number' ? option === value : (option?.id === value?.id)
        }

      }}
      renderOption={(option: any, { selected }) => {
        const val = getOptionLabel(option);
        const icon = option as { iconName: TagNames, color?: string, isPrimary?: boolean };
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}>
            <div>
              {icon.iconName && icon.isPrimary && <><TagIcon name={TagNames[icon.iconName]} color={icon.color} />&#8194;{val}</>}
              {icon.iconName && !icon.isPrimary &&
                <Chip
                  avatar={AthleteHelpers.isHaveIcon(icon.iconName) ? <TagIcon
                    name={icon.iconName} color={Colors.tag.colorRegular} fontSize={FontSizes.preMedium}
                  /> : <></>}
                  style={{
                    height: 24,
                    paddingLeft: AthleteHelpers.isHaveIcon(icon.iconName) ? 10 : 2,
                    paddingRight: 2,
                    backgroundColor: icon.color,
                    color: Colors.tag.colorRegular,
                    cursor: 'pointer',
                  }}
                  size="small"
                  label={val}
                />
              }
              {!icon.iconName && val}
            </div>
            {selected && <div style={{ alignSelf: 'flex-end', color: Colors.default[ColorTypes.secondary], maxHeight: 24, }}><DoneIcon fontSize='small' /></div>}
          </div>
        );
      }}
      onChange={(event, value: any) => {
        setValues(value);
      }}
      renderTags={(value, getTagProps: AutocompleteGetTagProps) => {
        return (
          value.map((option: ItemType | AthleteRankForFiltersList | CollegeTasksDto | IT, index: number) => {
            const val = getRenderValue(option);
            if (typeof option == 'object' && (option as any).color) {
              return (
                <Chip
                  size={'small'}
                  variant="outlined"
                  label={val}
                  {...getTagProps({ index })}
                  className={classes.tagsAutocomplete}
                />
              )
            }
            else {
              let validateVal = (val: any, value: any) => {
                if (value.length > 1) {
                  if (value[value.length - 1].hasOwnProperty('rank') && val !== (value[value.length - 1] as any).rank) {
                    if (value.length >= limitTags && val === (value[limitTags - 1] as any).rank) return val
                    else return `${val},`
                  }

                  else if (!(value[value.length - 1].hasOwnProperty('shortName') || value[value.length - 1].hasOwnProperty('rank')) && !(typeof value[value.length - 1] == 'number') && val !== (value[value.length - 1] as any).id) {
                    if (value.length >= limitTags && val === (value[limitTags - 1] as any).id) return val
                    else return `${val},`
                  }

                  else if (value[value.length - 1].hasOwnProperty('shortName') && val !== (value[value.length - 1] as any).shortName) {
                    if (value.length >= limitTags && val === (value[limitTags - 1] as any).shortName) return val
                    else return `${val},`
                  }

                  else if (typeof value[value.length - 1] === 'number' && val !== (value[value.length - 1] as any)) {
                    if (value.length >= limitTags && val === value[limitTags - 1].toString()) return val
                    else return `${val},`
                  }

                  else {
                    return val
                  }
                }
                else {
                  return val
                }
              }
              return (<span key={index} style={{ fontSize: 14, color: '#005DA8', marginRight: 2 }}>{validateVal(val, value)}</span>)
            }
          })
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <BaseInput
            inputRef={input => {
              inputRef.current = input;
            }}
            classes={{ root: classes.textField }}
            maxwidth={props.maxWidth}
            minwidth={props.minWidth}
            {...params}
            label={label}
            disabled
            InputLabelProps={{
              shrink: open || (Array.isArray(currentValue) ? currentValue.length > 0 : Boolean(currentValue)),
            }}
          />
        );
      }}
    />
  );
}

export default BaseAutocomplete;
