import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from 'notistack';
import SelectedTasks from './fragments/SelectedTasks';
import ModalSelectTasks from './fragments/ModalSelectTasks';
import useAthleteTasksStyle from './useAthleteTasksStyle';
import AthleteTask from '../athlete-task/AthleteTask';
import { GroupedAthleteTaskType } from '../../../../api/athletes';
import { AthleteTasksDto, CollegeTasksDto, createAthleteTask, deleteAthleteTask, updateAthleteTask, UpdateAthleteTaskDto } from '../../../../api/tasks';
import { PersonalInfoType } from '../Athlete.types';
import BaseButton from '../../shared/BaseButton';
import { useAppStateValue } from '../../../../state/StateContext';

export type AthleteTasksProps = {
  collegeTasks: CollegeTasksDto[];
  athleteId: number;
  users_id: number | string;
  personalInfo: PersonalInfoType | null;
  tasks: GroupedAthleteTaskType[];
  updateAthleteTasks: (tasks: GroupedAthleteTaskType[]) => void;
}
const AthleteTasks = (props: AthleteTasksProps) => {
  const { athleteId, tasks, collegeTasks, personalInfo, updateAthleteTasks } = props;
  const [{ activeSport }] = useAppStateValue();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [positionSelectTasks, setPositionSelectTasks] = useState(0);

  const editRef = useRef<HTMLDivElement | null>(null);

  const classes = useAthleteTasksStyle();

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const notSelectedTasks = useMemo(() => collegeTasks && collegeTasks
    .filter(t => !tasks.find(i => i.college_task_id === t.college_task_id)), [collegeTasks, tasks]);

  const selectedTasks = useMemo(() => collegeTasks && collegeTasks
    .filter(t => tasks.find(i => i.college_task_id === t.college_task_id)), [collegeTasks, tasks]);

  useLayoutEffect(() => {
    const checkPositionSelectTasks = () => {
      if (editRef.current && editRef.current?.offsetHeight !== positionSelectTasks) {
        setPositionSelectTasks((editRef.current?.offsetHeight || 0));
      }
    };

    checkPositionSelectTasks();
  });

  const addNewTask = async (task: CollegeTasksDto) => {

    const res = await createAthleteTask(
      {
        athlete_master_id: athleteId,
        college_task_id: task.college_task_id,
      }
    );
    if (res.success) {
      const newTasks = [...tasks]
      newTasks.push({
        college_task_id: res.data.college_task_id,
        task_title: res.data.task_title,
        ...(
          task.multiple_options ? { values: [res.data], multiple_options: true } : { value: res.data, multiple_options: false }
        )
      })

      updateAthleteTasks(newTasks);
    } else {
      enqueueSnackbar(res.error?.message, { variant: 'error' });
    }
  };

  const addForMultiple = async (groupedTask: GroupedAthleteTaskType, opt: string) => {
    if (!groupedTask.multiple_options) return

    const res = await createAthleteTask(
      {
        athlete_master_id: athleteId,
        college_task_id: groupedTask.college_task_id,
        task: opt,
      }
    );

    if (res.success) {
      groupedTask.values.push(res.data)
      updateAthleteTasks(
        tasks.map(t => t.college_task_id === groupedTask.college_task_id ? groupedTask : t)
      );
    } else {
      enqueueSnackbar(res.error?.message, { variant: 'error' });
    }
  }

  const updateTask = async (
    groupedTask: GroupedAthleteTaskType,
    data: UpdateAthleteTaskDto,
    task?: AthleteTasksDto
  ): Promise<void> => {
    const res = (groupedTask.multiple_options && task) // not a case right now
      ? await updateAthleteTask(task.athlete_task_id, data)
      : (
        !groupedTask.multiple_options
          ? await updateAthleteTask(groupedTask.value.athlete_task_id, data)
          : null
      )

    if (!res) return

    if (res.success) {
      const updatedTask = res.data;

      updateAthleteTasks(
        tasks.map(t => {
          if (t.college_task_id !== updatedTask.college_task_id) return t

          return t.multiple_options
            ? {
              ...t,
              values: t.values.map(v => v.athlete_task_id === updatedTask.athlete_task_id ? updatedTask : v)
            } : {
              ...t,
              value: updatedTask
            }
        })
      );
    } else {
      enqueueSnackbar(res.error?.message, { variant: 'error' });
    }
  }

  const deleteTask = async (groupedTask: GroupedAthleteTaskType, task?: AthleteTasksDto) => {

    if (!task) {
      try {
        if (groupedTask.multiple_options) {
          await Promise.all(groupedTask.values.map(v => deleteAthleteTask(v.athlete_task_id)))
        } else {
          await deleteAthleteTask(groupedTask.value.athlete_task_id);
        }

        // Delete task in state of athlete
        updateAthleteTasks(tasks.filter(t => t.college_task_id !== groupedTask.college_task_id));
      } catch (e) {
        enqueueSnackbar('Error removing task!', { variant: 'error' });
      }
    } else if (task && groupedTask.multiple_options) {
      try {
        await deleteAthleteTask(task.athlete_task_id);

        updateAthleteTasks(
          tasks.map(t => t.college_task_id === groupedTask.college_task_id ? {
            ...groupedTask,
            values: groupedTask.values.filter(v => v.athlete_task_id !== task.athlete_task_id)
          } : t)
        );
      } catch (e) {
        enqueueSnackbar('Error removing task!', { variant: 'error' });
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.tasks} ref={editRef}>

        <div className={classes.blockTasks} style={{ pointerEvents: isLoading ? 'none' : 'inherit' }}>
          {selectedTasks.map((collTask: CollegeTasksDto) => (
            <AthleteTask
              key={collTask.college_task_id}
              task={tasks.find(t => t.college_task_id === collTask.college_task_id)!}
              collegeTask={collTask}
              onAddForMultiple={addForMultiple}
              onUpdate={(data, t) => updateTask(tasks.find(t => t.college_task_id === collTask.college_task_id)!, data, t)}
              onDelete={async (task, t) => {
                setIsLoading(true);
                await deleteTask(task, t);
                setIsLoading(false);
              }}
            />))
          }
          <div className={classes.newTask}>
            <BaseButton
              title={'+ Add to Board'}
              customClass={classes.actionBtn}
              onClick={() => setIsAssigned(true)}
            />
          </div>
        </div>
        {!isSmScreen && isAssigned && <Fade in={isAssigned}>
          <SelectedTasks
            className={classes.selectTasks}
            style={{ top: positionSelectTasks, width: '100%' }}
            items={notSelectedTasks || []}
            selectItem={addNewTask}
            closeWindow={async () => {
              setIsAssigned(false);
            }}
          /></Fade>}

        {isSmScreen && <ModalSelectTasks
          open={isAssigned}
          athleteName={`${personalInfo?.first} ${personalInfo?.last}`}
          notSelectedTasks={notSelectedTasks}
          selectedTasks={selectedTasks}
          athleteTasks={tasks}
          addItem={addNewTask}
          addItemForMultiple={addForMultiple}
          updateItem={updateTask}
          deleteItem={deleteTask}
          closeWindow={() => {
            setIsAssigned(false);
          }}
        />}
      </div>
    </div>
  );
};

export default AthleteTasks;
