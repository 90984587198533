import { AccountInitialSportsDto } from "../api/account"

//@ts-ignore
let globalProgram = JSON.parse(localStorage.getItem('sport'))
let isUaUser = localStorage.getItem('isUaUser') === 'y' ?? false

export function getGlobalProgram() {
  return globalProgram
}

export function setGlobalSport(sport: AccountInitialSportsDto) {
  localStorage.setItem('sport', JSON.stringify(sport))
  globalProgram = sport
}

export function getGlobalIsUaUser() {
  return isUaUser
}

export function setGlobalIsUaUser(value: boolean) {
  localStorage.setItem('isUaUser', value ? 'y' : 'n')
  isUaUser = value
}


