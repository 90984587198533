import React from 'react';
import { IconProps } from '../icons/index';

export const CollaborateIcon = (props: IconProps) => {
  return (
    <svg fill={props.color || '#e8e6e6'} width={props.width || '83px'} height={props.height || '100px'} preserveAspectRatio="xMidYMid meet" data-bbox="-0.015 21.485 200.051 156.915" xmlns="http://www.w3.org/2000/svg" viewBox="-0.015 21.485 200.051 156.915" role="presentation" aria-hidden="true">
      <g>
        <path d="M188.6 71.1c-9.9-14.6-24.9-24.4-42.2-27.7-9.3-1.8-18.5-1.5-27.6.6-3.6-4-7.7-7.6-12.3-10.7-15.1-10.2-33.2-14-51.1-10.6C37.5 26.1 22 36.3 11.8 51.3s-14 33.2-10.6 51.1c2.5 13.4 9 25.5 18.6 35.2l.5 21.2c0 1 .5 2 1.4 2.6.6.4 1.2.6 1.9.6.4 0 .7-.1 1.1-.2l22.1-7.5c11 3.7 22.8 4.4 34.1 2.3 2.3-.4 4.5-1 6.7-1.6 9.3 9.3 21 15.5 34.1 17.9 11.2 2.1 22.9 1.3 33.7-2.4l22.9 7.8c.1 0 .2.1.4.1s.4-.1.6-.2c.3-.2.5-.5.5-.9l.5-22.1c9.6-9.5 16.1-21.4 18.6-34.7 3.2-17.2-.4-34.8-10.3-49.4zM79.7 150.2c-10.6 2-21.6 1.2-31.8-2.4-.7-.2-1.5-.2-2.2 0l-19 6.5-.4-18.1c0-.9-.4-1.7-1-2.3-9.2-8.9-15.3-20.2-17.6-32.6-3.1-16.2.3-32.6 9.6-46.2 9.3-13.6 23.2-22.8 39.4-25.9 3.9-.7 7.8-1.1 11.6-1.1 12.2 0 24.2 3.7 34.5 10.7 13.6 9.2 22.8 23.2 25.9 39.4 6.3 33.4-15.6 65.7-49 72zm117-30c-2.5 12.9-8.8 24.6-18.3 33.8-.2.2-.3.5-.3.8l-.5 21.1-21.8-7.4c-.2-.1-.5-.1-.7 0-10.6 3.7-22 4.6-32.9 2.5-12.2-2.3-23.3-8-32.1-16.5 31.8-10.7 51.6-43.5 45.2-77.3-2.2-11.6-7.3-22.3-14.7-31.1 8.4-1.8 17-2 25.5-.3 16.7 3.2 31.2 12.7 40.8 26.8 9.4 13.9 12.9 30.8 9.8 47.6z"></path>
        <path d="M54.2 88.8a6.2 6.2 0 1 1-12.4 0 6.2 6.2 0 0 1 12.4 0z"></path>
        <path d="M74.9 88.8a6.2 6.2 0 1 1-12.4 0 6.2 6.2 0 0 1 12.4 0z"></path>
        <path d="M95.6 88.8a6.2 6.2 0 1 1-12.4 0 6.2 6.2 0 0 1 12.4 0z"></path>
      </g>
    </svg>
  );
};
