import React from 'react';
import { makeStyles, Theme, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Colors, ColorTypes } from '../../../theme';
import { GroupedAthleteTaskType } from '../../../../api/athletes';
import { AthleteTasksDto, CollegeTasksDto, UpdateAthleteTaskDto } from '../../../../api/tasks';
import BaseMenu from '../../shared/BaseMenu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    '&:last-child': {
      marginRight: 0,
    },
    marginRight: 10,
    marginTop: 5,
  },
  taskTitle: {},
  taskValue: {
    color: Colors.default[ColorTypes.primary],
    marginLeft: 4
  },
  optsBtn: {
    minWidth: 35,
    paddingLeft: 5,
    '& .MuiButton-endIcon' : {
      margin: 0
    },
    '& .MuiSvgIcon-root': {
      color: Colors.default[ColorTypes.primary],
    }
  },
  menuStyle: {
    '& .MuiListItem-button.Mui-selected': {
      backgroundColor: '#ffe6c9 !important'
    }
  }
}));

export type AthleteTaskProps = {
  isDisable?: boolean;
  customClass?: string;
  isCloseIcon?: boolean;
  isPrimaryLabel?: boolean;
  height?: number;
  borderRadius?: number;
} & ({
  collegeTask: CollegeTasksDto
  task: GroupedAthleteTaskType
  onDelete: (groupedTask: GroupedAthleteTaskType, task?: AthleteTasksDto) => void;
  onUpdate: (data: UpdateAthleteTaskDto, t?: AthleteTasksDto | undefined) => void;
  onAddForMultiple: (groupedTask: GroupedAthleteTaskType, opt: string) => void;
  onAdd?: void
} | {
  collegeTask: CollegeTasksDto
  onAdd: (task: CollegeTasksDto) => void;
  task?: void
  onDelete?: void
  onUpdate?: void
  onAddForMultiple?: void
})


const AthleteTask = (props: AthleteTaskProps) => {
  const {
    task, collegeTask, onAdd, onAddForMultiple, onUpdate, onDelete, customClass = '',
    height = 32, borderRadius = 10,
  } = props;

  const commonStyles = {
    height,
    padding: '0 7px',
    borderRadius,
    border: '1px solid #DFE2EC',
    display: 'flex',
    alignItems: 'center',
  }

  const {task: task_title} = collegeTask
  const classes = useStyles({ height });


  if(task) {
    const handleOptionClick = (idx: number) => {
      const { title } = collegeTask.options[idx]
      if(!task.multiple_options && title === task.value.task) return
      if(task.multiple_options && task.values.find(v => v.task === title) && task.values.length === 1) return
      if(task.multiple_options && task.values.find(v => v.task === title)) {
        onDelete && onDelete(task, task.values.find(v => v.task === title))
      } else if(task.multiple_options && !task.values.find(v => v.task === title)) {
        onAddForMultiple && onAddForMultiple(task, title)
      } else {
        onUpdate && onUpdate({
          task: title
        })
      }
    }

    const isOptionSelected = (opt: string) => {
      return task.multiple_options
        ? !!task.values.find(v => v.task === opt)
        : task.value.task === opt
    }

    const task_value = task.multiple_options
      ? task.values.map(v => v.task)
      : [task.value.task]

    task_value.sort((a, b) => {
      return collegeTask.options.findIndex(o => o.title === a) - collegeTask.options.findIndex(o => o.title === b)
    })

    return (
      <span className={classes.item}>
        <div
          className={customClass}
          style={{ ...commonStyles }}
        >
        <CloseIcon 
          style={{ cursor: 'pointer', width: 16, marginRight: 5 }}
          onClick={(event) => {
            if (!onDelete) return;
            event.stopPropagation();
            event.preventDefault();
            onDelete(task);
          }}
        />
          <div className={classes.taskTitle}>{task_title}</div>
          <BaseMenu
            options={collegeTask.options.map(v => v.title)}
            positionAdditional={'down'}
            Title={<div className={classes.taskValue}>{task_value.join(', ')}</div>}
            MenuIcon={<ExpandMoreIcon />}
            customClass={classes.optsBtn}
            customMenuClass={classes.menuStyle}
            customStyle={{ backgroundColor: 'transparent' }}
            handleMenuItemClick={handleOptionClick}
            menuAnchorToLeft
            // @ts-ignore
            isOptionSelected={(o) => isOptionSelected(o)}
            keepOpenOnClick={task.multiple_options}
          />
        </div>
      </span>
    );
  }

  return (
    <span className={classes.item} onClick={(event) => {
      event.stopPropagation();
      event.preventDefault();
      onAdd && onAdd(collegeTask);
    }}>
    <div
      className={customClass}
      style={{
        ...commonStyles,
        cursor: 'pointer',
      }}
    >
      <div className={classes.taskTitle}>{task_title}</div>
    </div>
    </span>
  );
};

export default AthleteTask;
