import React, { useMemo, useState, useEffect } from 'react';
import { FormControl, MenuItem, Paper, Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AthletesListAthleteType, AthletesListDto } from '../../../api/athletes';
import { Colors, FontSizes } from '../../theme';
import AthleteCard from './athlete-card/AthleteCard';
import { useAppStateValue } from '../../../state/StateContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { CollegeNoteDto } from '../../../api/notes';
import empty from '../../../assets/empty.svg';

const pageLengthOptions = [
  {
    label: '10 cards',
    value: 10,
  },
  {
    label: '25 cards',
    value: 25,
  },
  {
    label: '50 cards',
    value: 50,
  },
  {
    label: '100 cards',
    value: 100,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: 'inherit',
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    transform: 'translateZ(0)',
  },
  containerPagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 1,
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  rootPagination: {
    display: 'flex',
    margin: theme.spacing(1),
    '& .MuiPaginationItem-root': {
      borderRadius: 2,
      color: '#1B4484',
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected:hover, & .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible': {
      backgroundColor: Colors.common.hoverOnPink,
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      color: '#1B4484',
      backgroundColor: Colors.common.activeState,
    },
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiSelect-icon': {
      color: '#1B4484',
    },
    '& .MuiInput-root': {
      fontWeight: 500,
      color: '#1B4484',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '0px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },

  },
  total: {
    fontSize: FontSizes.preMedium,
    lineHeight: 3.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
    marginRight: theme.spacing(2),
  },
  labelSelectCount: {
    fontSize: 16,
    lineHeight: '20px',
    color: '#1B4484',
    marginRight: 4,
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
  },
  emptyBlock: {
    width: '100%',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    backgroundColor: '#F7F6FB',
    borderRadius: 8,
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '404px',
    maxHeight: '343px',
  },
}));

export type AthleteListProps = {
  athletesListDto: AthletesListDto;
  // setAthleteList: React.Dispatch<any>;
  page: number;
  athletesChecked: number[];
  handleAthleteChecked: (athleteId: number, isCheck: boolean) => void;
  changeParams: (params: { page: number, page_length: number }) => void;
}
const AthleteList = (props: AthleteListProps) => {
  const { athletesListDto: { athletes, total }, changeParams, page, athletesChecked, handleAthleteChecked } = props;
  const [isPressedSearch, setIsPressedSearch] = useState(total > 0);

  const [{ collegeTags, systemTags, tasks: collegeTasks, activeSport, account }] = useAppStateValue();

  const [pageLength, setPageLength] = useState<number>(25);
  const [open, setOpen] = useState(false);

  const totalNumberPages = useMemo(() => (total ? Math.ceil(total / pageLength) : 1), [total, pageLength]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  useEffect(() => {
    setIsPressedSearch(total > 0)
  }, [total])

  // const updateAthleteState = (
  //   athleteId: number,
  //   params: { notes?: CollegeNoteDto[], tags?: AthleteTagsResponse[] },
  // ) => {
  //   const idx = athletes.findIndex(a => a.athlete_master_id === athleteId);

  //   const newAthletes = [
  //     ...athletes.slice(0, idx),
  //     { ...athletes[idx], ...params },
  //     ...athletes.slice(idx + 1),
  //   ];
  //   setAthleteList({
  //     athletes: newAthletes,
  //     total,
  //   })
  // dispatch({
  //   type: 'update', value: {
  //     athleteList: {
  //       athletes: newAthletes,
  //       total,
  //     },
  //   },
  // });
  // };

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    changeParams({ page, page_length: pageLength });
  };

  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPageLength(() => {
      const pageLength = event.target.value as number;

      changeParams({ page: 1, page_length: pageLength });
      return pageLength;
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Paper classes={{ root: classes.container }}>
        <Box width="100%" height='100%' display={'flex'} flexDirection={'column'}>
          {athletes.map((athlete: AthletesListAthleteType) => {
            return (
              <AthleteCard
                key={athlete.athlete_master_id}
                users_id={account?.id || 0}
                athlete={athlete}
                collegeTags={[...collegeTags, ...systemTags] || []}
                collegeTasks={collegeTasks || []}
                activeSport={activeSport?.sports_id || 0}
                athletesChecked={athletesChecked}
                handleAthleteChecked={handleAthleteChecked}
              // updateAthleteState={updateAthleteState}
              />
            );
          })}
          {isPressedSearch && <Box className={classes.containerPagination}>
            <FormControl className={classes.formControl}>
              {/* <span className={classes.total}>Total: {total}</span> */}
              <span className={classes.labelSelectCount}>Show: </span>
              <Select
                id="select-page-length"
                IconComponent={ExpandMoreIcon}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={pageLength}
                onChange={handleChangeSelect}
              >
                {pageLengthOptions.map(option => <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>)}
              </Select>
            </FormControl>
            <Pagination
              classes={{ root: classes.rootPagination }}
              size={matches ? 'small' : 'medium'}
              count={totalNumberPages}
              color="primary"
              page={page}
              onChange={handleChange}
            />
          </Box>}
          {!isPressedSearch && <div className={classes.emptyBlock}>
            <img
              src={empty}
              className={classes.image}
              alt="search-placeholder"
            />
          </div>}
        </Box>
      </Paper>
    </>
  );
};

export default AthleteList;
