import { useState, ChangeEvent, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

import { useAppStateValue } from '../../state/StateContext';

import { signIn, signInUA, UserCredentialsDto, User } from '../../api/auth';
import ApiResponse from '../../api/ApiResponse';

import { loginFormDic } from '../../dictionary';
// import welcomeImg from '../../assets/Dots.png';
import logoCP from '../../assets/cp_logo_2024_blue.png';
import { CircularProgress } from '@material-ui/core';
import { setGlobalIsUaUser } from '../../helpers/globals';
import Layout from '../layout';

const colors = {
  darkBlue: `#005DA8`,
  orange: `#F68B16`,
};

const FORGOT_PASSWORD_LINK = 'https://bit.ly/CoachPacket_SupportForm'
const CREATE_ACCOUNT_LINK = 'https://bit.ly/CoachPacket_SupportForm'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    minHeight: '100vh',
  },
  leftSideContainer: {
    background: colors.darkBlue,
    flexBasis: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexBasis: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  welcomeImage: {
    width: 'auto',
    height: '100%',
    objectFit: 'cover',
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  rightSideContainer: {
    // flexBasis: '50%',
    width: '100%', //replacement flexBasis
    background: `#fff`,
    '& .MuiContainer-root': {
      paddingLeft: 77,
      paddingRight: 77,
    },
    // [theme.breakpoints.down('md')]: {
    //   flexBasis: '70%',
    // },
    [theme.breakpoints.down('sm')]: {
      '& .MuiContainer-root': {
        paddingLeft: 50,
        paddingRight: 50,
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      display: 'flex',
      alignItems: 'center',
      '& .MuiContainer-root': {
        paddingLeft: 25,
        paddingRight: 25,
      },
    },
  },
  paper: {

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(11),
    }
  },
  logoCP: {

    maxHeight: 77,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4),
    boxShadow: `0px 0px 19px 4px rgba(7, 29, 67, 0.05)`,
    borderRadius: 5,
    padding: '40px 61px 48px',
    '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DFE2EC',
    },
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: '50px 30px',
      marginTop: theme.spacing(5),
    },
  },
  submit: {
    color: '#fff',
    height: theme.spacing(6),
    // width: '100%',
    // [theme.breakpoints.down('xs')]: {
    //   marginTop: theme.spacing(5),
    // },
  },
  wrapper: {
    marginTop: theme.spacing(5),
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.common.white,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    opacity: 1,
  },
  disable: {
    ...theme.typography.disable,
  },
}));

const initialFieldsValues: UserCredentialsDto = {
  email: '',
  password: '',
};

export default function Login() {
  const classes = useStyles();
  const history = useHistory();

  const [values, setValues] = useState<UserCredentialsDto>(initialFieldsValues);
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, dispatch] = useAppStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validateFormValues = (): boolean => {
    let formValuesErrors = {
      email: '',
      password: '',
    };
    formValuesErrors.email =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/.test(
        values.email,
      )
        ? ''
        : 'Enter valid user email.';
    formValuesErrors.password =
      values.password.length > 4
        ? ''
        : 'User password is required. Minimum 5 characters.';

    setErrors({
      ...formValuesErrors,
    });

    return Object.values(formValuesErrors).every(
      (fieldError) => fieldError === '',
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isFormValid = validateFormValues();

    if (isFormValid) {
      setIsLoading(true);
      if (values.email.slice(0, 6) === 'coach*' || values.email.slice(0, 6) === 'scout*') {
        const credentials = {
          username: values.email.slice(6),
          password: values.password,
          isAdmin: false
        }

        signInUA(credentials)
          .then(processResponseUaUser)
          .catch(() => dispatch({ type: 'userLogged', value: { isUserLogged: false } }))
          .finally(() => setIsLoading(false));
      }
      else {
        signIn(values)
          .then(processResponse)
          .catch(() => dispatch({ type: 'userLogged', value: { isUserLogged: false } }))
          .finally(() => setIsLoading(false));
      }
    } else {
      dispatch({ type: 'userLogged', value: { isUserLogged: false } });
    }
  };

  const processResponse = (response: ApiResponse<User>) => {
    if (response.success) {
      const { data: user } = response;
      dispatch({
        type: 'userLogged',
        value: { isUserLogged: true, user },
      });

      if (window.location.pathname.includes('/ulink/athlete/')) return <Layout />
      else window.location.href = '/search'
    } else {
      enqueueSnackbar(response.error?.message, { variant: 'error' });
      console.log('Login failed', response.error);
    }
  };

  const processResponseUaUser = (response: ApiResponse<any>) => {
    if (response.success) {
      const { data: token } = response;

      localStorage.setItem('access_token', token.access_token)
      localStorage.setItem('refresh_token', token.refresh_token)
      localStorage.setItem('expires_in', Date.now() + token.expires_in)

      setGlobalIsUaUser(true)
      window.location.href = '/search'
    } else {
      enqueueSnackbar(response.error?.message, { variant: 'error' });
      console.log('Login failed', response.error);
    }
  };

  return (
    <main className={classes.mainContainer}>
      {/* <div className={classes.leftSideContainer}>
        <img
          src={welcomeImg}
          alt={'welcomeImage'}
          className={classes.welcomeImage}
        />
      </div> */}
      <div className={classes.rightSideContainer}>
        <Container component="div" maxWidth="sm">
          <CssBaseline />
          <div className={classes.paper}>
            <img
              src={logoCP}
              alt={'logoCoachPacket'}
              className={classes.logoCP}
            />
            <form className={`${classes.form} ${isLoading ? classes.disable : ''}`} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="User email"
                placeholder='Enter your email'
                name="email"
                value={values.email}
                autoComplete="email"
                autoFocus
                color={'secondary'}
                onChange={handleChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                placeholder='Enter password'
                name="password"
                type="password"
                value={values.password}
                color={'secondary'}
                onChange={handleChange}
                error={Boolean(errors.password)}
                helperText={errors.password}
                autoComplete="current-password"
              />
              <Box display={'flex'} justifyContent='space-between'>
                <Link href={CREATE_ACCOUNT_LINK} target="_blank" rel="noopener noreferrer">Create Account</Link>
                <Link href={FORGOT_PASSWORD_LINK} target="_blank" rel="noopener noreferrer">Forgot Password</Link>
              </Box>
              <div className={classes.wrapper}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {loginFormDic.buttonCaption}
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </form>
          </div>
        </Container>
      </div>
    </main>
  );
}
