import { Box, IconProps, makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";

type Props = {
  icon: (props: IconProps) => JSX.Element
  img_src: string
  alt_text: string
}

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'transparent',
    padding: 0,
    boxShadow: 'none',
  },
  tooltipImage: {
    height: 580,
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipIcon: {
    fontSize: 40,
    marginRight: 16,
  },
  popper: {
    '.MuiTooltip-tooltip': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      
    },
  },
}));

export default function LandingImgTooltip({ icon: IconComponent, img_src, alt_text }: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      open={open}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      title={
        <Box className={classes.tooltipContainer}>
          <img src={img_src} alt={alt_text} className={classes.tooltipImage} />
        </Box>
      }
      PopperProps={{
        modifiers: {
          flip: {
            enabled: true,
          },
          preventOverflow: {
            boundariesElement: 'viewport',
          },
          offset: {
            offset: '0, -290'
          }
        },
      }}
      classes={{
        tooltip: classes.customTooltip,
        popper: classes.popper,
      }}
    >
      <Box style={{ display: 'inline-block', cursor: 'pointer' }}>
        <img src={img_src} style={{ height: 240 }} alt={alt_text} />
      </Box>
    </Tooltip>
  );
}