const athleteColumns = [
  {
    title: 'Name',
    flexBasis: 400,
    flexGrow: 1,
    maxWidth: 400,
    minWidth: 200,
    fields: [],
  },
  {
    title: 'Tags',
    flexBasis: 570, //640
    flexGrow: 1,
    maxWidth: 'auto',
    minWidth: 'auto',
    fields: [],
  },
  {
    title: 'Ranks',
    flexBasis: 270, //640
    flexGrow: 1,
    maxWidth: 'auto',
    minWidth: 'auto',
    fields: [],
  },
]

const athleteOrderMenuOptions: Map<string, string> = new Map([
  ['first', 'First name'],
  ['last', 'Last name'],
  ['position1_abbrev', 'Position'],
  ['state', 'State'],
]);

export {
  athleteColumns,
  athleteOrderMenuOptions,
}
