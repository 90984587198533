import React, { useRef, useState } from 'react';
import { makeStyles, Theme} from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem } from '@material-ui/core';
import { Box } from '@material-ui/core';
import BaseButton from './BaseButton';

export type AdditionalOptionType = { item: string | JSX.Element; onClick?: () => void; isSeparateElement?: boolean }

const noop = () => { };
const useStyles = makeStyles((theme: Theme) =>
({
  menuPaper:{
    boxShadow: '4px 8px 20px rgba(0, 0, 0, 0.12)'
  }
}))
export type BaseMenuProps<OPT = string | JSX.Element> = {
  options: OPT[];
  iconOptions?: Map<string, React.ReactNode>;
  additionalOptions?: AdditionalOptionType[];
  iconAdditionalOptions?: Map<string, React.ReactNode>;
  isOptionSelected?: (option: OPT, selected: string | undefined) => boolean;
  keepOpenOnClick?: boolean;
  MenuIcon?: React.ReactNode;
  Title?: string | React.ReactNode;
  selectedOption?: string;
  customStyle?: any;
  customClass?: string;
  customMenuClass?: string;
  positionAdditional?: 'up' | 'down';
  menuAnchorToLeft?: boolean;
  handleMenuItemClick?: (idx: number) => void;
}
const BaseMenu = (props: BaseMenuProps) => {
  const {
    options, iconOptions, additionalOptions = [], iconAdditionalOptions, selectedOption,
    isOptionSelected, keepOpenOnClick = false, menuAnchorToLeft = false,
    positionAdditional = 'up', customClass = '', customMenuClass = '', customStyle = {},
    MenuIcon = <MoreVertIcon />, Title = '', handleMenuItemClick = noop,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const shouldClose = useRef(true);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (!shouldClose.current) return;
    setAnchorEl(null);
  };

  const renderAdditionalMenu = (option: AdditionalOptionType, idx: number) => {
    const IconOption = iconAdditionalOptions && typeof option.item === 'string' && iconAdditionalOptions.get(option.item);

    if (option.isSeparateElement) {
      return IconOption ? <Box key={`additional-${idx}`} display={'flex'} alignItems={'flex-start'}>
        {IconOption}&nbsp;{option.item}
      </Box>
        : <div key={`additional-${idx}`}>{option.item}</div>;
    }

    return <li
      key={`additional-${idx}`}
      onClick={() => {
        if (option.onClick) {
          option.onClick();
          setAnchorEl(null);
        }
      }}
      onMouseOver={() => {
        shouldClose.current = false;
      }}>
      {IconOption ? <Box display={'flex'} alignItems={'flex-start'}>{IconOption}&nbsp;{option.item}</Box> : option.item}
    </li>;
  };

  return (
    <>
      <BaseButton
        EndIcon={MenuIcon}
        customClass={customClass}
        customStyle={customStyle}
        title={Title}
        onClick={handleClick}
      />
      <Menu
        id="base-menu"
        anchorEl={anchorEl}
        classes={{paper:classes.menuPaper, list: customMenuClass}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: menuAnchorToLeft? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: menuAnchorToLeft? 'left' : 'right',
        }}
        getContentAnchorEl={null}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        keepMounted
      >
        {positionAdditional === 'up' && additionalOptions
          .map((option: AdditionalOptionType, idx: number) => renderAdditionalMenu(option, idx))}

        {options.map((option: string | JSX.Element, idx: number) => {
          const IconOption = iconOptions && typeof option === 'string' && iconOptions.get(option as string);
          return (
            <MenuItem
              key={`optional-${idx}`}
              selected={isOptionSelected? isOptionSelected(option, selectedOption) : option === selectedOption}
              dense
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                event.preventDefault();
                shouldClose.current = option !== selectedOption;
                handleMenuItemClick(idx);
                if(!keepOpenOnClick) {
                  handleClose();
                }
              }}>
              {IconOption ? <Box display={'flex'} alignItems={'flex-start'}>{IconOption}&nbsp;{option}</Box> : option}
            </MenuItem>
          );
        })}

        {positionAdditional === 'down' && additionalOptions
          .map((option: AdditionalOptionType, idx: number) => renderAdditionalMenu(option, idx))}
      </Menu>
    </>
  );
};

export default BaseMenu;
