import ApiResponse from './ApiResponse';
import config from './config';
import { DEFAULT_ERROR_MESSAGE } from '../constants';

const URL = config.urlFrontRush;

export type SyncFrontRushDto = {
  synced?: boolean;
}

export type AthleteFrontRushDto = {
  success: boolean;
}

export type AthletesFrontRushDto = {
  synced: number;
}

export async function isSyncedUserWithFrontRush(userId: number): Promise<ApiResponse<SyncFrontRushDto>> {
  try {
    const response = await fetch(`${URL}/front-rush-is-synced/${userId}`, { method: 'GET' });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || DEFAULT_ERROR_MESSAGE);
    }

    return {
      success: true,
      status: 200,
      data,
    };

  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function addAthleteToFrontRush(
  userId: number,
  athleteMasterId: number,
): Promise<ApiResponse<AthleteFrontRushDto>> {
  try {
    const response = await fetch(`${URL}/front-rush-add/${userId}/${athleteMasterId}`, { method: 'POST' });
    const data = await response.json();

    if (!response.ok || !data.success) {
      throw new Error(data.message || DEFAULT_ERROR_MESSAGE);
    }

    return {
      success: true,
      status: 200,
      data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function loginUrlFrontRush(userId: number, athleteMasterId?: number): Promise<ApiResponse<{ success: boolean; url: string; }>> {
  try {
    let params = `${userId}`;
    if (athleteMasterId) {
      params += `/${athleteMasterId}`;
    }

    const response = await fetch(`${URL}/front-rush-login/${params}`, { method: 'GET' });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || DEFAULT_ERROR_MESSAGE);
    }

    return {
      success: true,
      status: 200,
      data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function addAthletesToFrontRush(
  userId: number,
  athleteIds: number[],
  eventId: number,
  college_program_id: number,
): Promise<ApiResponse<AthletesFrontRushDto>> {
  try {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('users_id', userId.toString());
    urlencoded.append('event', eventId.toString());
    urlencoded.append('college_program_id', college_program_id.toString());
    urlencoded.append('athletes', athleteIds.join(','));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    };

    const response = await fetch(`${URL}/front-rush-add-athletes`, requestOptions);
    const data = await response.json();

    if (!response.ok || !(data.synced > 0)) {
      throw new Error(data.message || DEFAULT_ERROR_MESSAGE);
    }

    return {
      success: true,
      status: 200,
      data,
    };

  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}
