import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { athleteColumns } from '../athleteListSettings';
import { FontSizes } from '../../../theme';

const maxWidth = (athleteColumns[0].maxWidth as number);

export default makeStyles((theme: Theme) => ({
  linkNameText: {
    ...theme.typography.baseText,
    textDecoration: 'none',
    fontWeight: 700,
    fontSize: FontSizes.preLarge,
    lineHeight: '27px',
    marginRight: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
      color: 'rgba(27, 68, 132, 1)',
      textDecoration: 'underline',
      fontWeight: 700,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: FontSizes.extraMedium,
    },
  },
  nameText: {
    ...theme.typography.baseText,
    textDecoration: 'none',
    fontWeight: 700,
    fontSize: FontSizes.preLarge,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: FontSizes.extraMedium,
      marginRight: 0,
    },
  },
  baseText: {
    ...theme.typography.baseText,
    paddingLeft: 14,
  },
  titleText: {
    ...theme.typography.baseText,
    fontSize: FontSizes.extraMedium,
    fontWeight: 700,
  },
  blockText: {
    display: 'flex',
    ...theme.typography.baseText,
    fontSize: FontSizes.medium,
    lineHeight: '30px',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      lineHeight: '24px',
    },
  },
  decoration:{
    cursor: 'pointer',
    '&:hover':{
      textDecoration: 'underline'
    },
  },
  ncsaLogo: {
    marginLeft: 'auto',
    marginRight: 19,
    marginTop: 18,
    width: 34, 
    height: 33,
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  personalInfoContainer:{
    flexGrow: 1,
    maxWidth: `${maxWidth}px`,
    flexBasis: `${maxWidth}px`,
    minHeight: 53,
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'auto',
      width: '100%',
      maxWidth: '100%',
    },
  },
  personalInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignSelf: 'baseline',
    cursor:'default',
  },
}));
