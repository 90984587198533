import React from 'react';
import { IconProps } from '../icons/index';

export const GreatIcon = (props: IconProps) => {
  return (
    <svg fill={props.color || '#e8e6e6'} width={props.width || '36px'} height={props.height || '37px'} preserveAspectRatio="xMidYMid meet" id="comp-k61bif0fsvgcontent" data-bbox="27.5 24.5 145 151" viewBox="27.5 24.5 145 151" xmlns="http://www.w3.org/2000/svg" data-type="color" role="img">
      <g>
        <path d="M166.82 92.908c-4.907-6.392-12.402-9.912-21.097-9.912h-15.146c6.23-13.612 6.442-33.453 1.485-45.221-3.609-8.563-9.786-13.275-17.398-13.275-2.065 0-4.226.362-6.425 1.067C97.378 29.064 96.97 37.703 96.61 45.321c-.525 11.074-1.32 27.359-29.535 48.386V83.144H27.5v90.882h39.575v-11.241c6.772 6.125 16.413 12.715 26.557 12.715h45.764c9.546 0 18.961-7.209 21.44-16.41l10.278-38.174c2.838-10.55 1.275-20.76-4.294-28.008zM56.546 163.515H38.029V93.66h18.518l-.001 69.855zm104.396-45.33l-10.278 38.176c-1.237 4.598-6.501 8.625-11.269 8.625H93.632c-9.948 0-21.633-11.478-26.557-17.341v-41.027c38.409-26.049 39.433-47.737 40.053-60.801.38-8.062.829-9.112 4.341-10.246 1.153-.371 2.23-.557 3.196-.557 3.193 0 5.778 2.302 7.69 6.838 5.25 12.462 2.515 34.824-5.228 42.724l-8.754 8.931h37.35c5.424 0 9.831 2.003 12.74 5.796 3.559 4.637 4.463 11.515 2.479 18.882z" fill="#e8e6e6" data-color="1"></path>
      </g>
    </svg>
  );
};
