export enum TagNames {
  default = 'default',
  arrow_down = 'arrow_down',
  arrow_up = 'arrow_up',
  athletic = 'athletic',
  ball_02 = 'ball_02',
  banknote = 'banknote',
  bell = 'bell',
  binoculars = 'binoculars',
  book = 'book',
  brain = 'brain',
  bulb = 'bulb',
  camp = 'camp',
  clip = 'clip',
  clock = 'clock',
  club = 'club',
  cup = 'cup',
  diamond_1 = 'diamond_1',
  diamond_2 = 'diamond_2',
  east = 'east',
  envelope = 'envelope',
  eye = 'eye',
  eye_closed = 'eye_closed',
  face = 'face',
  fast = 'fast',
  film = 'film',
  fire = 'fire',
  flag = 'flag',
  flower = 'flower',
  globe = 'globe',
  growth = 'growth',
  half_star = 'half_star',
  hand = 'hand',
  heart = 'heart',
  home = 'home',
  hourglass = 'hourglass',
  hull = 'hull',
  lemon = 'lemon',
  lightning = 'lightning',
  lock = 'lock',
  moon = 'moon',
  mug = 'mug',
  omit = 'omit',
  pazzle = 'pazzle',
  phone = 'phone',
  photo = 'photo',
  plus = 'plus',
  precent = 'precent',
  rocket = 'rocket',
  sad = 'sad',
  smile = 'smile',
  snowflake = 'snowflake',
  spade = 'spade',
  speach = 'speach',
  star = 'star',
  team = 'team',
  temperature = 'temperature',
  thumb_up = 'thumb_up',
  ticket = 'ticket',
  user = 'user',
  video = 'video',
  walkon = 'walkon',
  academic = 'academic',
  beer = 'beer',
  bell2 = 'bell2',
  bomb = 'bomb',
  camp2 = 'camp2',
  camp3 = 'camp3',
  chat = 'chat',
  chat2 = 'chat2',
  cocktail = 'cocktail',
  emailFast = 'emailFast',
  email = 'email',
  email2 = 'email2',
  extinguisher = 'extinguisher',
  fast2 = 'fast2',
  fire2 = 'fire2',
  fire3 = 'fire3',
  flashlight = 'flashlight',
  frown = 'frown',
  grenade = 'grenade',
  key = 'key',
  lightning2 = 'lightning2',
  loud = 'loud',
  lowEnergy = 'lowEnergy',
  mailBox = 'mailBox',
  message = 'message',
  money = 'money',
  mute = 'mute',
  plane = 'plane',
  potOfGold = 'potOfGold',
  quiet = 'quiet',
  rabbit = 'rabbit',
  ribbon = 'ribbon',
  ribbon2 = 'ribbon2',
  rocket2 = 'rocket2',
  run = 'run',
  sleep = 'sleep',
  smile2 = 'smile2',
  strong = 'strong',
  sunny = 'sunny',
  turd = 'turd',
  turtle = 'turtle',
  visit = 'visit',


  question = "question",
  circle_right = "circle_right",
  circle_left = "circle_left",
  car = "car",
  calendar_days = "calendar_days",
  tag = "tag",
  thumbtack = "thumbtack",
  pencil = "pencil",
  glasses = "glasses",
  cake_candles = "cake_candles",
  tree = "tree",
  robot = "robot",
  shoe_prints = "shoe_prints",
  hammer = "hammer",
  wand_magic_sparkles = "wand_magic_sparkles",
  scissors = "scissors",
  headphones = "headphones",
  carrot = "carrot",
  apple_whole = "apple_whole",
  ghost = "ghost",
  skull = "skull",
  cat = "cat",
  anchor = "anchor",
  umbrella = "umbrella",
  bullseye = "bullseye",
  music = "music",
  pause = "pause",
  suitcase = "suitcase",
  water = "water",
  church = "church",
  microscope = "microscope",
  volleyball = "volleyball",
  infinity = "infinity",
  cloud = "cloud",
  zero = "zero",
  one = "one",
  two = "two",
  three = "three",
  four = "four",
  five = "five",
  six = "six",
  seven = "seven",
  eight = "eight",
  nine = "nine"
}
