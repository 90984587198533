import React, { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormControlLabel, Switch } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CircularProgress } from '@material-ui/core';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import BaseAutocomplete from '../../shared/BaseAutocomplete';
import BaseInput from '../../shared/BaseInput';
import { TagType } from '../../shared/types';
import { useAppStateValue } from '../../../../state/StateContext';
import useAthleteOptionsStyle from './useAthleteOptionsStyle';
import transformIntoExcel from './athletesIntoExcelBuilder'

import BaseButton from '../../shared/BaseButton';
import { getTournamentEvalsCount, getTournamentEvals } from '../../../../api/tournaments';
import { AthleteListQueryDto, AthleteTaskForFiltersList, getAthletes } from '../../../../api/athletes';
import { getCollegeGroups, AthleteRankForFiltersList } from '../../../../api/ranks';
import { getCollegeTags } from '../../../../api/tags';
import { collegeCoachesList } from '../../../../api/account'

export type AthleteOptionsProps = {
  state: AthleteListQueryDto;
  setState: React.Dispatch<React.SetStateAction<AthleteListQueryDto>>;
}

type LocalTaskFilterOptsType = { group: string, college_task_id: number, task: string }[]

const AthleteOptions2 = (props: AthleteOptionsProps) => {
  const { state, setState } = props;
  const [{
    activeSport,
    collegeTags,
    tasks,
    tournaments,

    ranksForFilter,
    isVisibleRanks,
    isVisibleTasks,
  }, dispatch] = useAppStateValue();
  const [count, setCount] = useState<string>("");
  const [isImporting, setIsImporting] = useState(false);
  const [isAllNotes, setIsAllNotes] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useAthleteOptionsStyle();

  const theme = useTheme();
  const matchesEvent = useMediaQuery(theme.breakpoints.down('xs'));
  const spacingGridSm = useMediaQuery('(min-width:800px)');

  const tasksForFilter = useMemo(() => {
    return tasks.reduce((acc: LocalTaskFilterOptsType, task) => {
      return [
        ...acc,
        ...(task.options || []).map(opt => ({
          group: task.task,
          college_task_id: task.college_task_id,
          task: opt.title
        }))
      ]
    }, [])
  }, [tasks])

  useEffect(() => {
    async function getEvalsCount(): Promise<void> {
      let allNotesQuery = isAllNotes ? "?all_notes=1" : "";

      if (state.tournament?.id) {
        let response = await getTournamentEvalsCount(state.tournament.id, allNotesQuery)
        if (response.success) {
          const { data: count } = response;
          setCount(`(${count.count})`);
        } else {
          enqueueSnackbar(response.error?.message || 'Error create tag', { variant: 'error' });
        }
      }
    }
    getEvalsCount()
  }, [state.tournament, isAllNotes])

  const tagList = useMemo(
    () => (collegeTags && collegeTags.map(tag => (
      { id: tag.college_tag_id, name: tag.title, iconName: tag.icon, color: tag.color, isPrimary: tag.primary }
    ))),
    [collegeTags]);

  const eventList = useMemo(
    () => (tournaments?.filter(event => event.sports_id === activeSport?.sports_id)
      .map(t => ({ id: t.id, name: t.name }))),
    [tournaments, activeSport?.sports_id]);

  const getEvals = async () => {
    let allNotesQuery = isAllNotes ? "?all_notes=1" : "";

    let response = await getTournamentEvals((state.tournament as { id: number, name: string }).id, allNotesQuery);

    if (response.success) {
      const { data } = response;
      const link = document.createElement('a');
      // @ts-ignore
      link.href = window.URL.createObjectURL(data);
      link.download = `evals_${(state.tournament as { id: number, name: string }).id}.xlsx`;
      link.click();
    } else {
      enqueueSnackbar(response.error?.message || 'Error create tag', { variant: 'error' });
    }
  };

  const canImport = !!(
    state.tags?.length ||
    state.ranks?.length ||
    state.note ||
    (state.tournament && state.is_evaluated_event)
  );

  const startImport = async () => {
    if (isImporting || !canImport) return
    setIsImporting(true)

    try {
      const [
        export2Excel,
        athletes,
        collegeGroups,
        collegeTags,
        coaches
      ] = await Promise.all([
        // size
        import('../../../../helpers/export2Excel/index'),
        getAthletes({ ...state, for_export: true }),
        getCollegeGroups(),
        getCollegeTags(),
        collegeCoachesList(),
      ])

      if (!athletes.success) throw athletes.error?.message
      if (!athletes.data.athletes.length) throw new Error('There is no athletes to import')
      if (!collegeGroups.success) throw collegeGroups.error?.message
      if (!collegeTags.success) throw collegeTags.error?.message
      if (!coaches.success) throw coaches.error?.message

      const output = transformIntoExcel(
        athletes.data.athletes,
        collegeGroups.data,
        collegeTags.data,
        coaches.data,
      )
      export2Excel.exportJsonToExcel({ data: output })

    } catch (err: any) {
      enqueueSnackbar(err?.message || 'Error during Export', { variant: 'error' });
    } finally {
      setIsImporting(false)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <Box className={classes.athBottomOptionsWrap}>

      <Box className={classes.athTopOptionsWrap}>
        <Box className={classes.formControlWrap}>
          {matchesEvent && <Box>Event</Box>}
          <FormControlLabel
            className={classes.formControlSwitch}
            control={
              <Switch
                checked={state.is_event}
                onChange={event => {
                  const newState = {
                    [event.target.name]: event.target.checked,
                    ...(!event.target.checked ? { tournament: null } : {}),
                  };
                  setState({ ...state, ...newState });
                  !event.target.checked && dispatch({ type: 'update', value: { activeTournament: 0 } });
                }}
                color="secondary"
                name="is_event"
                inputProps={{ 'aria-label': 'event checkbox' }}
              />
            }
            label={!matchesEvent && !state.is_event ? 'Event' : ''}
          />
        </Box>
        <Grid container spacing={2}>
          {
            state.is_event &&
            <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={8} lg={4}>
              <BaseAutocomplete
                className={classes.input}
                label="Event"
                value={(eventList || []).find(event => event.id === state.tournament?.id) || null}
                multiple={false}
                disableCloseOnSelect={false}
                options={eventList || []}
                setValues={values => {
                  const val = (values as { id: number, name: string });
                  setState({
                    ...state,
                    tournament: val?.id ? { id: val.id, name: val.name } : null,
                  });
                  dispatch({ type: 'update', value: { activeTournament: val?.id ?? null } });
                }}
              />
            </Grid>
          }
          {
            state.tournament?.id &&
            <>
              <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                    <Checkbox checked={state.is_evaluated_event}
                      onChange={() => {
                        setState({
                          ...state,
                          is_evaluated_event: !state.is_evaluated_event
                        });
                      }}
                      name="is_evaluated_event" />}
                  label="Evaluated on event"
                />
              </Grid>

              <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  control={
                    <Checkbox checked={isAllNotes}
                      onChange={(e) => setIsAllNotes(e.target.checked)}
                      name="is_all_notes" />}
                  label="Download all notes"
                />
              </Grid>

              <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={3}>
                <BaseButton
                  variant="outlined"
                  StartIcon={<GetAppIcon color='secondary' />}
                  title={`Download Evals ${count}`}
                  onClick={() => getEvals()}
                  customClass={classes.evalsCountBtn}
                />
              </Grid>
            </>
          }
        </Grid>
      </Box>

      <Box className={classes.athTopOptionsWrap}>
        <Box className={classes.formControlWrap}>
          {matchesEvent && <Box>Evaluated</Box>}
          <FormControlLabel
            className={classes.formControlSwitch}
            control={
              <Switch
                checked={!!state.is_evaluated}
                onChange={event => {
                  const newState = {
                    [event.target.name]: event.target.checked,
                    ...(!event.target.checked ? { tags: [] } : {}),
                  };
                  setState({
                    ...state,
                    ...newState,
                  });
                }}
                color="secondary"
                name="is_evaluated"
                inputProps={{ 'aria-label': 'evaluated checkbox' }}
              />
            }
            label={!matchesEvent && !state.is_evaluated ? 'Evaluated' : ''}
          />
        </Box>

        {
          state.is_evaluated &&
          <Grid container spacing={2}>
            <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
              <BaseAutocomplete
                className={`${classes.input} ${classes.inputForTags}`}
                label="Tag contains"
                value={(tagList || []).filter(i => state.tags?.find((t: TagType) => t.id === i.id))}
                options={tagList || []}
                setValues={values => setState({
                  ...state,
                  tags: ((values as TagType[])
                    .map((item: TagType) => ({
                      id: item.id, name: item.name, iconName: item.iconName, color: item.color, isPrimary: item.isPrimary,
                    }))),
                })}
              />
            </Grid>

            <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
              <BaseAutocomplete
                className={`${classes.input} ${classes.inputForTags}`}
                label="Rating contains"
                limitTags={3}
                value={(ranksForFilter || []).filter(i => state.ranks?.find((t) => t.athlete_rank_id === i.athlete_rank_id))}
                options={(ranksForFilter || []).sort((a, b) => (a.group || '').localeCompare(b.group || '') || (a.rank || '').localeCompare(b.rank || ''))}
                setValues={values => setState({
                  ...state,
                  ranks: values,
                })}
              />
            </Grid>

            {isVisibleTasks &&
              <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
                <BaseAutocomplete
                  className={`${classes.input} ${classes.inputForTags}`}
                  label="Boards"
                  limitTags={1}
                  groupByKey="group"
                  value={(tasksForFilter || []).filter(i => state.tasks?.find((t) => t.college_task_id === i.college_task_id && t.options?.includes(i.task)))}
                  options={tasksForFilter}
                  setValues={values => {
                    setState({
                      ...state,
                      tasks: values.reduce((acc: AthleteTaskForFiltersList[], val) => {
                        const existTask = acc.find(i => i.college_task_id === val.college_task_id)

                        if (existTask) {
                          existTask.options?.push(val.task)
                        } else {
                          acc.push({
                            college_task_id: val.college_task_id,
                            options: [val.task],
                          })
                        }
                        return acc
                      }, []),
                    })
                  }}
                />
              </Grid>
            }
            <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
              <BaseInput
                classes={{ root: classes.input }}
                id="note_contain"
                label="Note contains"
                name="note"
                type="search"
                value={state.note}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        }

      </Box>


      <div className={classes.actionBlock}>
        <Button
          classes={{ root: classes.btn }}
          variant="contained"
          color="primary"
          type="submit"
        >
          Search
        </Button>
        <BaseButton
          variant="outlined"
          StartIcon={isImporting ? <CircularProgress style={{ width: '20px', height: '20px' }} color='secondary' /> : <GetAppIcon color='secondary' />}
          title={`Evals`}
          disabled={!canImport}
          onClick={() => startImport()}
          customClass={classes.evalsCountBtn}
        />
      </div>

    </Box >
  );
};

export default AthleteOptions2;
