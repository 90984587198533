import { styled } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import Popper from '@material-ui/core/Popper';

const PopperMy = function (props: any) {
  return (<Popper {...props} style={{ width: 'auto' }} placement='bottom-start' />)
}

const StyledInputRank = styled(Autocomplete)(() => ({
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
    padding: 0
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: 0
  },
  '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
    minWidth: 28,
  },
})
);

StyledInputRank.defaultProps = {
  id: "free-solo-demo",
  freeSolo: true,
  disableClearable: true,
  PopperComponent: PopperMy
};

export default StyledInputRank;
