import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import useSummaryStyle from '../useSummaryStyle';
import { summaryDialogOptions, TypeDialogSummary } from '../types';
import EditSummary from './EditSummary';
import { Colors } from '../../../../theme';
import BaseMenu from '../../../shared/BaseMenu';
import AlertDialog from '../../../shared/AlertDialog';
import { CollegeNoteDto } from '../../../../../api/notes';

const summaryMenuOptions: string[] = ['Edit'];

const iconOptions = new Map([
  ['Edit', <EditOutlinedIcon style={{ width: 18, height: 18 }} />],
]);
// const iconAdditionalOptions = new Map([
//   ['Delete', <DeleteOutlineIcon style={{ width: 18, height: 18, color: Colors.svg.dangerous }}/>],
// ]);

export type PreviewReactProps = {
  summary: CollegeNoteDto;
  saveSummary: (updateSummary: CollegeNoteDto, summary?: CollegeNoteDto | undefined) => void;
  removeSummary: (deleteSummary: CollegeNoteDto) => void;
}
const Summary = (props: PreviewReactProps) => {
  const { summary, saveSummary, removeSummary } = props;

  const [isOpenEditSummary, setIsOpenEditSummary] = React.useState(false);
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const classes = useSummaryStyle({ isActive, isOpenEditSummary });

  const DeleteButton = () => {
    return (
      <div className={classes.deleteButton} onClick={() => setIsOpenDialog(true)}>
        <DeleteOutlineIcon style={{ width: 18, height: 18, color: Colors.svg.dangerous, marginRight: 4 }} />
        <span>Delete</span>
      </div>
    )
  }

  const handleMenuItemClick = async (idx: number) => {
    switch (summaryMenuOptions[idx]) {
      case 'Edit':
        setIsOpenEditSummary(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className={`${classes.rowNote} ${classes.rowNoteAddBackground}`}>
        <div className={classes.note} onClick={() => setIsOpenEditSummary(true)}>
          {isOpenEditSummary && <EditSummary
            summary={summary}
            saveSummary={saveSummary}
            removeSummary={removeSummary}
            closeSummary={() => setIsOpenEditSummary(false)}
          />}
          {!isOpenEditSummary &&
            <Tooltip title='Shared between staff' arrow placement="top">
              <Typography className={classes.noteText}>{summary.note}</Typography>
            </Tooltip>}
        </div>
        {!isOpenEditSummary && <Box
          display={'flex'}
          alignItems={'center'}
          mr={'12px'}
          // width={'54px'}
          height={'50px'}
        >
          <div
            className={classes.showMenu}
            // className={`${isActive ? '' : classes.showMenu}`}
            onMouseOver={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            <BaseMenu
              options={summaryMenuOptions}
              iconOptions={iconOptions}
              positionAdditional={'down'}
              customStyle={{ backgroundColor: 'transparent' }}
              additionalOptions={[
                { item: <Divider style={{ margin: 5 }} />, isSeparateElement: true },
                { item: <DeleteButton /> },
              ]}
              // iconAdditionalOptions={iconAdditionalOptions}
              handleMenuItemClick={handleMenuItemClick}
            />
          </div>
        </Box>}
      </div>

      <AlertDialog
        isOpen={isOpenDialog}
        title={summaryDialogOptions[TypeDialogSummary.DELETE].title}
        content={summaryDialogOptions[TypeDialogSummary.DELETE].content}
        closeDialog={(isConfirm) => {
          if (!isConfirm) {
            setIsOpenDialog(false);
            return;
          }
          setIsOpenDialog(false);
          removeSummary(summary);
        }}
      />
    </>
  );
};

export default Summary;
