import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutlineRounded';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { TOOLBAR_HEIGHT } from '../../../../constants';
import HideOptions from '../../shared/filter/HideOptions';
import { ItemType } from '../../shared/filter/filter.types';
import { Colors } from '../../../theme';

const useStyles = makeStyles(theme => ({
  options: {
    position: 'sticky',
    top: TOOLBAR_HEIGHT.desktop,
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    display: 'block',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: 19,
    paddingBottom: 11,
    minHeight: 64,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      position: 'inherit',
      alignItems: 'inherit',
      flexDirection: 'column-reverse',
      margin: 0,
      top: TOOLBAR_HEIGHT.mobile,
    },
  },
  iconBtn: {
    cursor: 'pointer',
    textTransform: 'capitalize',
    maxHeight: 50,
    fontWeight: 400,
    padding: '4px 16px',
    color: 'rgba(27, 68, 132, 1)',
    '&:hover': {
      backgroundColor: Colors.common.hoverOnPink,
    },
  },
  selectedOptions: {
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    '& span': {
      marginRight: 0,
    },
  },
  btnContainer: {
    minWidth: 280,
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    float: 'right', 
  }
}));

type AthleteFilterOptionsProps = {
  openFilters: boolean;
  setOpenFilters: Dispatch<SetStateAction<boolean>>;
  hideOptions?: ItemType[];
  viewOptions?: ItemType;
  clearFilters: () => void;
  onDeleteHideOption?: (value: ItemType) => void;
}
const AthleteFilterOptions = (props: AthleteFilterOptionsProps) => {
  const {
    openFilters, setOpenFilters, hideOptions, viewOptions, clearFilters, onDeleteHideOption,
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.options} borderBottom={openFilters ? `1px solid ${Colors.athlete.colDividerBody}` : 'none'}>

      <Box className={classes.btnContainer}>
        <Button
          variant="text"
          color="secondary"
          classes={{ root: classes.iconBtn }}
          startIcon={<DeleteIcon />}
          onClick={() => clearFilters()}
        >
          Clear All Filters
        </Button>

        <Button
          variant="text"
          color="secondary"
          classes={{ root: classes.iconBtn }}
          startIcon={openFilters ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
          onClick={() => setOpenFilters(prev => !prev)}
        >
          {openFilters ? 'Hide filters' : 'Show filters'}
        </Button>
      </Box>
      {<div className={classes.selectedOptions}>
        <HideOptions
          hideOptions={hideOptions}
          viewOptions={viewOptions}
          onDeleteHideOption={onDeleteHideOption}

        />
      </div>}
    </Box>
  );
};

export default AthleteFilterOptions;
