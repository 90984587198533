import React from 'react';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { PinIcon } from '../../../../assets/icons';
import { Colors } from '../../../theme';

export enum TypeDialogNote {
  DELETE = 'delete',
  CLOSE = 'close',
}

export const noteDialogOptions = {
  [TypeDialogNote.DELETE]: {
    title: 'Delete the note?',
    content: 'The note will be permanently removed from your list.',
  },
  [TypeDialogNote.CLOSE]: {
    title: 'Close editing?',
    content: 'Changes made may not be saved.',
  },
};

export const editNoteMenuOptions: string[] = ['Save', 'Pin', 'Cancel'];
export const iconEditNoteOptions = new Map([
  ['Save', <DoneOutlineIcon style={{ width: 18, height: 18 }}/>],
  ['Pin', <PinIcon width={'18px'} height={'18px'} color={Colors.svg.secondary}/>],
  ['Unpin', <PinIcon width={'18px'} height={'18px'}/>],
  ['Cancel', <CloseIcon style={{ width: 18, height: 18 }}/>],
]);
export const iconEditNoteAdditionalOptions = new Map([
  ['Delete', <DeleteOutlineIcon style={{ width: 18, height: 18, color: Colors.svg.dangerous }}/>],
]);
