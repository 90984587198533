import { API } from 'aws-amplify';

import ApiResponse from './ApiResponse';
import { API_ERRORS, DEFAULT_ERROR_MESSAGE } from '../constants';

export type CollegeTasksDto = {
  college_task_id: number;
  task: string;
  date_modified?: string;
  date_deleted?: string | null;
  college_program_id: number
  options: CollegeTasksOptionsDto[]
  sort_order: number;
  multiple_options: boolean
};

export type CollegeTasksOptionsDto = {
  title: string;
  id: number;
  version: number;
  inColumnSeparatorEnabled: boolean;
  separators: CollegeTaskColumnSeparatorType[]
}

export type CollegeTaskColumnSeparatorType = {
  id: number;
  title: string;
  color: string;
  instructions: ColumnSeparatorInstructionType[]
}

type SeparatorInstructionDefaultType = {
  id: number;
  tags?: number[]
  gradyears?: number[]
  positions?: number[]
  note?: string
}

export type SeparatorInstructionTagType = SeparatorInstructionDefaultType & {
  tags: number[]
}

export type SeparatorInstructionGradyearType = SeparatorInstructionDefaultType & {
  gradyears: number[]
}

export type SeparatorInstructionPositionType = SeparatorInstructionDefaultType & {
  positions: number[]
}

export type SeparatorInstructionNoteType = SeparatorInstructionDefaultType & {
  note: string
}

export type ColumnSeparatorInstructionType = SeparatorInstructionTagType | SeparatorInstructionGradyearType | SeparatorInstructionPositionType | SeparatorInstructionNoteType

export type CreateCollegeTaskDto = {
  task: string;
  options: CollegeTasksOptionsDto[];
  multiple_options: boolean
};

export type AthleteTasksDto = {
  athlete_task_id: number
  college_task_id: number
  athlete_master_id: number
  college_program_id: number
  assigned_to_user_id: number | null
  task: string
  sort_order: number | null
  tournament_id: number | null
  date_completed: string | null
  task_title: string
  assignee_initials: string | null
  assignee_first: string | null
  assignee_last: string | null
};

export type CreateAthleteTaskDto = {
  athlete_master_id: number;
  college_task_id: number;
  tournament_id?: number;
  task?: string;
  assigned_to_user_id?: number
  sort_order?: number | null
};

export type UpdateAthleteTaskDto = {
  assigned_to_user_id?: number | null
  task?: string
  tournament_id?: number | null
  sort_order?: number | null
};

export type UpdateAthleteTaskOrderDto = {
  athlete_task_id: number
  sort_order: number | null
};

export async function getCollegeTasks(): Promise<ApiResponse<CollegeTasksDto[]>> {
  try {
    const data: CollegeTasksDto[] = await API.get(
      'legacyAPI',
      '/college-tasks',
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function createCollegeTask(
  createCollegeTaskDto: CreateCollegeTaskDto,
): Promise<ApiResponse<CollegeTasksDto>> {
  try {
    const data: CollegeTasksDto = await API.post('legacyAPI', '/college-tasks', {
      body: { ...createCollegeTaskDto },
    });

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.response?.data?.statusCode,
        message: error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        name: error.response?.data?.error,
      },
    };
  }
}

export async function updateCollegeTask(
  id: number,
  createCollegeTaskDto: CreateCollegeTaskDto,
): Promise<ApiResponse<CollegeTasksDto>> {
  try {
    const data: CollegeTasksDto = await API.put(
      'legacyAPI',
      `/college-tasks/${id}`,
      {
        body: { ...createCollegeTaskDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.response?.data?.statusCode,
        message: error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        name: error.response?.data?.error,
      },
    };
  }
}

export async function archiveCollegeTask(id: number): Promise<void> {
  await API.del('legacyAPI', `/college-tasks/${id}`, {});
}

export async function createAthleteTask(
  createAthleteTaskDto: CreateAthleteTaskDto,
): Promise<ApiResponse<AthleteTasksDto>> {
  try {
    const data: AthleteTasksDto = await API.post(
      'legacyAPI',
      `/athlete-tasks`,
      {
        body: { ...createAthleteTaskDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function updateAthleteTask(
  id: number,
  updateAthleteTaskDto: UpdateAthleteTaskDto,
): Promise<ApiResponse<AthleteTasksDto>> {
  try {
    const data: AthleteTasksDto = await API.put(
      'legacyAPI',
      `/athlete-tasks/${id}`,
      {
        body: { ...updateAthleteTaskDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function updateAthleteTaskOrder(
  updateAthleteTaskOrderDto: UpdateAthleteTaskOrderDto[],
): Promise<ApiResponse<AthleteTasksDto[]>> {
  try {
    const data: AthleteTasksDto[] = await API.put(
      'legacyAPI',
      `/athlete-tasks/sort`,
      {
        body: updateAthleteTaskOrderDto,
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    if (error.message === API_ERRORS.UNAUTHORIZED) window.location.pathname = '/';

    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function deleteAthleteTask(id: number): Promise<void> {
  await API.del(
    'legacyAPI',
    `/athlete-tasks/${id}`,
    {}
  );
}
