export enum KeysLocalStorage {
  FrontRush = 'Front_Rush',
  access_token = 'access_token',
  refresh_token = 'refresh_token',
  expires_in = 'expires_in'
}

class LocalStorageHelpers {
  static get frontRush(): { synced?: boolean } {
    return JSON.parse(localStorage.getItem(KeysLocalStorage.FrontRush) || '{}')
  }

  static saveFrontRush(synced: boolean) {
    localStorage.setItem(KeysLocalStorage.FrontRush, JSON.stringify({ synced }))
  }

  static clearAllLocalStorage() {
    for (const enumKey in KeysLocalStorage) {
      localStorage.removeItem(KeysLocalStorage[enumKey as keyof typeof KeysLocalStorage]);
    }
  }
}

export default LocalStorageHelpers;
