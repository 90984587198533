import { useEffect, useState } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { Tab, Tabs } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useTabInfoStyle from '../athlete-tab-info/useTabInfoStyle';
import TeamNotes from './TeamNotes';
import ApiResponse from '../../../../api/ApiResponse';
import { AthletesListAthleteType } from '../../../../api/athletes';
import { getTeamNotes, TeamNoteDto } from '../../../../api/notes';
import {
  getTeamDetails,
  TeamDetailsDto,
  getTeamStaff,
  TeamStaffDto,
  getTeamSchedules,
  TeamScheduleDto,
  getTeamRoster,
  TeamRosterDto
} from '../../../../api/teams';
import { useAppStateValue } from '../../../../state/StateContext';
import { CircularProgress } from '@material-ui/core';

import BlockDetails from '../athlete-details/BlockDetails';
import TeamStaff from '../athlete-team-staff/AthleteTeamStaff';
import TeamSchedules from '../athlete-team-schedule/AthleteTeamSchedule';
import TeamRoster from '../athlete-team-roster/AthleteTeamRoster';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      overflowY: 'visible',
      padding: 0,
    },
    dialogTitle: {
      color: '#1B4484',
      position: 'relative',
      padding: '16px 40px 10px',
      fontSize: 18,
      fontWeight: 500,
    },
    closeIcon: {
      position: 'absolute',
      right: 5,
      top: 5,
    },
    spinner: {
      position: 'fixed',
      top: '50%',
      left: '50%',
    },
    detailsWrap: {
      padding: 30,
    }
  }));

export interface ConfirmationDialogRawProps {
  classes?: Record<'paper', string>;
  id: string;
  open: boolean;
  onClose: () => void;
  athlete: AthletesListAthleteType;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const teamTitles = [
  [
    { title: 'Team Name', name: 'team_name', value: '', },
    { title: 'Team Age', name: 'age', value: '' },
    { title: 'Division', name: 'division', value: '' },
    { title: 'State', name: 'state', value: '' },
  ],
  []
];

const emptyTeam: TeamDetailsDto = {
  team_master_id: 0,
  team_name: "",
  age: 0,
  division: "",
  state: ""
};


export default function DialogClubNote(props: ConfirmationDialogRawProps) {
  const { onClose, open, athlete, ...other } = props;

  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notes, setNotes] = useState<TeamNoteDto[]>([]);
  const [teamDetails, setTeamDetails] = useState<TeamDetailsDto>(emptyTeam);
  const [teamStaff, setTeamStaff] = useState<TeamStaffDto[]>([]);
  const [teamSchedules, setTeamSchedules] = useState<TeamScheduleDto[]>([]);
  const [teamRoster, setTeamRoster] = useState<TeamRosterDto[]>([]);

  const [{ activeSport, activeTournament }] = useAppStateValue();

  const classes = useStyles();
  const classesTab = useTabInfoStyle({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function initialization() {
      setIsLoading(true)

      if (tab === 0) {
        const teamNotesDto: ApiResponse<TeamNoteDto[]> = await getTeamNotes(activeSport && activeSport.college_program_id, (athlete.team_master_id as number));

        if (teamNotesDto.success) {
          const { data: teamNotes } = teamNotesDto;
          teamNotes && setNotes(teamNotes)

          setIsLoading(false)
        } else {
          enqueueSnackbar('Cannot load initial data', { variant: 'error' });
          setIsLoading(false)
        }
      }

      if (tab === 1) {
        const teamDetailsDto: ApiResponse<TeamDetailsDto> = await getTeamDetails((athlete.team_master_id as number));

        if (teamDetailsDto.success) {
          const { data: teamDetails } = teamDetailsDto;
          teamDetails && setTeamDetails(teamDetails)

          setIsLoading(false)
        } else {
          enqueueSnackbar('Cannot load initial data', { variant: 'error' });
          setIsLoading(false)
        }
      }

      if (tab === 2) {
        const teamStaffDto: ApiResponse<TeamStaffDto[]> = await getTeamStaff(athlete.team_master_id as number);

        if (teamStaffDto.success) {
          const { data: teamStaff } = teamStaffDto;
          teamStaff && setTeamStaff(teamStaff)

          setIsLoading(false)
        } else {
          enqueueSnackbar('Cannot load initial data', { variant: 'error' });
          setIsLoading(false)
        }
      }

      if (tab === 3) {
        const teamSchedulesDto: ApiResponse<TeamScheduleDto[]> = await getTeamSchedules(athlete.team_master_id as number);

        if (teamSchedulesDto.success) {
          const { data: teamSchedulesData } = teamSchedulesDto;
          teamSchedulesData && setTeamSchedules(teamSchedulesData)

          setIsLoading(false)
        } else {
          enqueueSnackbar('Cannot load initial data', { variant: 'error' });
          setIsLoading(false)
        }
      }

      if (tab === 4) {
        let teamRosterDto: ApiResponse<TeamRosterDto[]>;
        if (!activeTournament) {
          teamRosterDto = await getTeamRoster(
            (athlete.team_master_id as number)
          );
        }
        else {
          teamRosterDto = await getTeamRoster(
            (athlete.team_master_id as number),
            activeTournament
          );
        }

        if (teamRosterDto.success) {
          const { data: teamRosterData } = teamRosterDto;
          teamRosterData && setTeamRoster(teamRosterData)

          setIsLoading(false)
        } else {
          enqueueSnackbar('Cannot load initial data', { variant: 'error' });
          setIsLoading(false)
        }
      }
    }
    initialization()
  }, [open, tab]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  const updateAthleteNotes = (teamNotes: TeamNoteDto[]) => {
    setNotes(teamNotes);
  };
  const handleCancel = () => {
    onClose();
  };

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography component={'div'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      scroll='body'
      maxWidth="md"
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
      {...other}>
      <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
        {athlete.team_name}
        <IconButton aria-label="edit tag" component="span" onClick={handleCancel} className={classes.closeIcon}>
          <CloseIcon color='disabled' />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Box style={{ width: '100%', opacity: `${isLoading ? 0.4 : 1}` }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              classes={{ indicator: classesTab.indicator }}
              value={tab}
              textColor="primary"
              onChange={handleChange}
              variant="scrollable"
              aria-label="details info"
            >
              <Tab label="NOTES" {...a11yProps(0)} />
              <Tab label="DETAILS" {...a11yProps(1)} />
              <Tab label="STAFF" {...a11yProps(2)} />
              <Tab label="SCHEDULE" {...a11yProps(3)} />
              <Tab label="TEAM ROSTER" {...a11yProps(4)} />
            </Tabs>
            <Divider />
          </Box>

          <TabPanel value={tab} index={0}>
            <div className={classesTab.tabAthlete}>
              <div className={classesTab.notes}>
                <TeamNotes
                  athlete={athlete}
                  notes={notes}
                  updateAthleteNotes={updateAthleteNotes}
                  setIsLoading={setIsLoading}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <div className={classes.detailsWrap}>
              <BlockDetails details={AthleteHelpers.prepareAthleteDetailsData(teamDetails, teamTitles)} />
            </div>
          </TabPanel>

          <TabPanel value={tab} index={2}>
            <TeamStaff teamStaff={teamStaff} />
          </TabPanel>

          <TabPanel value={tab} index={3}>
            <TeamSchedules teamSchedules={teamSchedules} />
          </TabPanel>

          <TabPanel value={tab} index={4}>
            <TeamRoster teamRoster={teamRoster} />
          </TabPanel>
        </Box>
        {isLoading && <CircularProgress className={classes.spinner} color="secondary" />}
      </DialogContent>
    </Dialog >
  );
}
