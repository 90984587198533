import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';

import { useAppStateValue } from '../../state/StateContext';

import { signInUA, signOut } from '../../api/auth';
import ApiResponse from '../../api/ApiResponse';
import LocalStorageHelpers from '../../helpers/LocalStorageHelpers';
import { setGlobalIsUaUser } from '../../helpers/globals';


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    minHeight: '100vh',
  },

  buttonProgress: {
    color: '#005DA8',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    opacity: 1,
  },

}));

export default function AdminAfterLogin() {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [, dispatch] = useAppStateValue();
  const { enqueueSnackbar } = useSnackbar();
  const { username, password } = useParams<{ username: string, password: string }>()

  useEffect(() => {
    const signIn = (username: string, password: string) => {
      setIsLoading(true);
      signInUA({ username, password, isAdmin: true })
        .then(processResponse)
        .catch(() => dispatch({ type: 'userLogged', value: { isUserLogged: false } }))
        .finally(() => setIsLoading(false));
    };

    const processResponse = (response: ApiResponse<any>) => {
      if (response.success) {
        const { data: token } = response;
        localStorage.setItem('access_token', token.access_token)
        localStorage.setItem('refresh_token', token.refresh_token)
        localStorage.setItem('expires_in', Date.now() + token.expires_in)
        setGlobalIsUaUser(true)

        history.push('/search')
        history.go(0);
      } else {
        enqueueSnackbar(response.error?.message, { variant: 'error' });
        console.log('Login failed', response.error);
      }
    };

    signIn(username, password)
  }, [])

  return (
    <main className={classes.mainContainer}>
      <Container component="div" maxWidth="sm">
        {isLoading && <CircularProgress size={48} className={classes.buttonProgress} />}
      </Container>
    </main>
  );
}
