import React, { MouseEventHandler } from 'react';
import { Fab, makeStyles, Theme } from '@material-ui/core';
import { Colors, ColorTypes } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
    root: (props: BaseFabButtonProps) => ({
      backgroundColor: props.bgColor || Colors.default[ColorTypes.primary],
      color: props.textColor || Colors.default[ColorTypes.textPrimary],
      width: props.width || 'auto',
      height: props.height || 'auto',
      minHeight: props.height || 'auto',
      padding: props.p || 'auto',
      margin: props.m || 'auto',
      /*eslint no-useless-computed-key: "off"*/
      ['& svg']: {
        fontSize: props.sizeIcon || 'auto',
      },
    }),
  }),
);

export type BaseFabButtonProps = {
  icon: React.ReactElement;
  size?: 'small' | 'medium' | 'large';
  onClick?: MouseEventHandler<any>;
  bgColor?: string;
  textColor?: string;
  disabled?: boolean;
  width?: string;
  height?: string;
  p?: string | number;
  m?: string | number;
  sizeIcon?: string;
}
const BaseFabButton = (props: BaseFabButtonProps) => {
  const classes = useStyles(props);
  return (
    <Fab
      classes={{ root: classes.root }}
      aria-label="add"
      size={props.size || 'small'}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon}
    </Fab>
  );
};

export default BaseFabButton;
