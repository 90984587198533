import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AthleteTask from '../../athlete-task/AthleteTask';

import useAthleteTasksStyle from '../useAthleteTasksStyle';
import { AthleteTasksDto, CollegeTasksDto, UpdateAthleteTaskDto } from '../../../../../api/tasks';
import ModalTransition from '../../../shared/ModalTransition';
import { GroupedAthleteTaskType } from '../../../../../api/athletes';

export type ModalSelectTasksProps = {
  open: boolean;
  athleteName: string;
  athleteTasks: GroupedAthleteTaskType[];
  notSelectedTasks: CollegeTasksDto[];
  selectedTasks: CollegeTasksDto[];
  addItem: (task: CollegeTasksDto) => void;
  addItemForMultiple: (groupedTask: GroupedAthleteTaskType, opt: string) => void;
  updateItem: (groupedTask: GroupedAthleteTaskType, data: UpdateAthleteTaskDto, task?: AthleteTasksDto) => void;
  deleteItem: (groupedTask: GroupedAthleteTaskType, task?: AthleteTasksDto) => void;
  closeWindow: () => void;
}
const ModalSelectTasks = (props: ModalSelectTasksProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { open, athleteName, notSelectedTasks, selectedTasks, athleteTasks, closeWindow, addItem, addItemForMultiple, updateItem, deleteItem } = props;

  const classes = useAthleteTasksStyle();

  return (
    <ModalTransition open={open} closeWindow={closeWindow}>
      <div className={classes.rootModalTasks}>
        <Typography className={classes.athleteNameText}>{athleteName}</Typography>
        <div className={classes.contentModalTasks}>
          <Typography component={'h6'} variant={'subtitle2'}>Assigned</Typography>

          <br/>

          {open ? (<div className={classes.assignedTasks} style={{ pointerEvents: isLoading ? 'none' : 'inherit'}}>
            {selectedTasks.map(a => {
              let task = athleteTasks.find(t => t.college_task_id === a.college_task_id)!
              return (
                <AthleteTask
                  key={a.college_task_id}
                  task={task}
                  collegeTask={a}
                  onUpdate={async (data, t) => {
                    setIsLoading(true);
                    await updateItem(task, data, t);
                    setIsLoading(false);
                  }}
                  onAddForMultiple={async (g, opt) => {
                    setIsLoading(true);
                    await addItemForMultiple(g, opt);
                    setIsLoading(false);
                  }}
                  onDelete={async (g, t) => {
                    setIsLoading(true);
                    await deleteItem(g, t);
                    setIsLoading(false);
                  }}
                />
              );
            })}
          </div>) : <></>}
        </div>

        <br/>

        <div className={classes.contentModalTasks}>
          <Typography component={'h6'} variant={'subtitle2'}>Available</Typography>
          <br/>

          {open? (<div className={classes.blockModalTasks} style={{ pointerEvents: isLoading ? 'none' : 'inherit'}}>
            {notSelectedTasks.map(r => {
              return (
                <AthleteTask
                  key={r.college_task_id}
                  collegeTask={r}
                  isDisable={true}
                  onAdd={async (t) => {
                    setIsLoading(true);
                    await addItem(t);
                    setIsLoading(false);
                  }}
                />
              );
            })}
          </div>) : <></>}

        </div>
      </div>
    </ModalTransition>
  );
};

export default ModalSelectTasks;
