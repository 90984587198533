import { useState } from 'react';
import { Box, Button, Container, Grid, Link, Popover, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import 'swiper/swiper.min.css';

import x_icon from '../../assets/landing/x-icon.svg';

import ios_app from '../../assets/landing/ios-app.png';
import android_app from '../../assets/landing/android-app.png';

import usa_field_hockey from '../../assets/landing/usa_field_hockey.png';
import usa_water_polo from '../../assets/landing/usa_water_polo.svg';
import usssa from '../../assets/landing/usssa.svg';
import usa_hockey from '../../assets/landing/usa_hockey.svg';
import img_academy from '../../assets/landing/img_academy.png';
import prep_hoops from '../../assets/landing/prep_hoops.svg';
import usa_lacrosse from '../../assets/landing/usa_lacrosse.svg';
import usa_baseball from '../../assets/landing/usa_baseball.svg';
import usys from '../../assets/landing/usys.png';
import pg from '../../assets/landing/pg.svg';
import step from '../../assets/landing/3Step.png';
import premier_lacrosse_league from '../../assets/landing/premier-lacrosse-league.png';
import collaborate from '../../assets/landing/collaborate.png';
import discover from '../../assets/landing/discover.png';
import evaluate from '../../assets/landing/evaluate.png';
import plan from '../../assets/landing/plan.png';
import logo from '../../assets/logo_52.svg';


import slide1 from '../../assets/landing/slides/screen1.svg';
import slide2 from '../../assets/landing/slides/screen2.svg';
import slide3 from '../../assets/landing/slides/screen3.svg';
import slide4 from '../../assets/landing/slides/screen4.svg';
import slide5 from '../../assets/landing/slides/screen5.svg';
import slide6 from '../../assets/landing/slides/screen6.svg';
import slide7 from '../../assets/landing/slides/screen7.svg';
import slide8 from '../../assets/landing/slides/screen8.svg';
import slide9 from '../../assets/landing/slides/screen9.svg';
import device from '../../assets/landing/slides/device.svg';

import { GreatIcon } from '../../assets/landing/GreatIcon';
import { CommaIcon } from '../../assets/landing/CommaIcon';
import { DiscoverIcon } from '../../assets/landing/DiscoverIcon';
import { PlanIcon } from '../../assets/landing/PlanIcon';
import { EvaluateIcon } from '../../assets/landing/EvaluateIcon';
import { CollaborateIcon } from '../../assets/landing/CollaborateIcon';
import LandingImgTooltip from '../blocks/publicPages/LandingImgTooltip';


const useStyles = makeStyles((theme) => ({
  whiteContainer: {
    backgroundColor: '#fff',
  },
  img: {
    width: '100%',
    maxWidth: 420,
  },
  linkToStore: {
    width: '100%',
    maxWidth: 170
  },
  title: {
    fontWeight: 500,
  },
  subtitle: {
    marginBottom: 32
  },
  textInfo: {
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 300,
    marginBottom: 40,
    marginTop: 16,
  },
  text: {
    fontSize: 18,
    lineHeight: '22px',
  },
  link_item: {
    marginBottom: 24,
  },
  videoContainer: {
    padding: '45px 0 90px'
  },
  videoWrap: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    paddingTop: 30,
    height: 0,
    marginTop: 40,

    '& iframe': {
      position: 'absolute',
      border: 0,
      height: '100%',
      left: 0,
      top: 0,
      width: '100%',
    }
  },
  iconCards: {
    fontSize: 72,
    color: '#e8e6e6',
    marginBottom: 24,
  },
  contactUs: {
    color: '#fff',
    padding: 20,
    paddingTop: 40,
    textAlign: 'center',
    height: '100%',
  },
  primaryBg: {
    backgroundColor: '#ED8B01'
  },
  primaryBorder: {
    border: '1px solid #ED8B01'
  },
  primaryHeader: {
    color: '#ED8B01'
  },
  secondaryBg: {
    backgroundColor: '#005DA8',
  },
  secondaryBorder: {
    border: '1px solid #005DA8'
  },
  secondaryHeader: {
    color: '#005DA8'
  },
  textField: {
    '& .MuiFormLabel-root': {
      color: '#fff',
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff',
    }
  },
  containerPadding: {
    padding: '56px 0',
  },
  footerWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  feedbackForm: {
    '& .MuiDialog-paperWidthMd': {
      width: '100%'
    },
    '& .MuiDialog-paperScrollPaper': {
      height: '70%',
    }
  },
  swiperWrap: {
    width: '100%',
    maxWidth: 225,
    position: 'relative',
    margin: '20px 0'
  },
  swiper: {
    position: 'absolute',
    top: 10,
    left: 5,
    width: '100%',
    height: '100%'
  },
  headerBlock: {
    display: 'flex',
    marginTop: 48,
  },
  headerLogoContainer: {
    marginRight: 10,
  },
  headerLogo: {
    width: 110,
    height: 110,
    padding: 16,
    backgroundColor: '#005DA8',
    borderRadius: 16,
    [theme.breakpoints.down('sm')]: {
      width: 85,
      height: 85,
      padding: 8,
    },
    [theme.breakpoints.down(460)]: {
      width: 110,
      height: 110,
      padding: 16,
    },
  },
  headerDescription: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'flex-end',
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: '3.4rem',
    color: '#005DA8',
    lineHeight: 1.2,
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7rem',
      lineHeight: 1.1,
    },
  },
  textByNCSA: {
    fontWeight: 500,
    fontSize: '1.8rem',
    color: '#005DA8',
    marginTop: '-8px',
    lineHeight: 1.2,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
    },
  },
  customTooltip: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  swiperJustify: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  swiperPadding: {
    padding: '0 100px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  }
}));


export default function Login() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const slideList = [slide1, slide2, slide3, slide4, slide5, slide6, slide7, slide8, slide9];

  return (
    <Box>
      <Box className={classes.whiteContainer}>
        <Container maxWidth="lg">
          <Grid container className={classes.swiperPadding}>
            <Grid item xs={12} md={7} lg={7}>
              <div className={classes.headerBlock}>
                <div className={classes.headerLogoContainer}>
                  <img className={classes.headerLogo} src={logo} alt={'logo'} />
                </div>
                <div className={classes.headerDescription}>
                  <Box flexGrow={1} />
                  <Typography variant="h3" className={classes.headerTitle}>
                    COACH PACKET
                  </Typography>
                  <Typography variant="h4" className={`${classes.textByNCSA}`}>
                    BY NCSA
                  </Typography>
                </div>
              </div>

              <Typography className={classes.textInfo}>
                Coach Packet, your digital recruiting booklet with rosters with player data and real time schedules, provides
                everything you and your staff need to identify and evaluate recruits at an event, including:
                <ul>
                  <li>FREE access for college coaches and pro scouts.</li>
                  <li>Add confidential custom tags, icons, and coach notes.</li>
                  <li>Share and compare notes in real time across your coaching staff.</li>
                  <li>Ability to create a custom recruiting schedule for the event.</li>
                  <li>Work offline and watch post-event videos when available.</li>
                </ul>
              </Typography>
              <Box display={'flex'}>
                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/ua/app/coach-packet-3-by-ncsa/id1540482512#?platform=iphone" style={{ marginRight: 10 }}>
                  <img src={ios_app} alt="ios_app" className={classes.linkToStore} />
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=org.ncsasports.coachpacket3">
                  <img src={android_app} alt="android_app" className={classes.linkToStore} />
                </a>
              </Box>
            </Grid>
            <Grid item container xs={12} md={5} lg={5} className={classes.swiperJustify}>
              <div className={classes.swiperWrap}>
                <img src={device} alt="device" style={{ position: 'relative', zIndex: 2 }} />
                <Swiper
                  className={classes.swiper}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                  }}
                  modules={[Autoplay]}
                >
                  {slideList.map((item, index) => <SwiperSlide key={index}><img src={item} alt={`slide${index}`} /></SwiperSlide>)}
                </Swiper>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className={classes.videoContainer}>
        <Container maxWidth="md">
          <Box display={'flex'} justifyContent={'center'} alignItems='center' flexDirection={'column'}>
            <Box>
              <Typography variant="h4" component='span' className={classes.title}>
                Discover our
              </Typography>
              &nbsp;&nbsp;
              <Typography variant="h4" component='span' color='secondary' className={classes.title}>game-changing features</Typography>
            </Box>


            <Box className={classes.videoWrap}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/p-z8YR02kCo?si=KuoAKVMrqN0irR2T"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box className={`${classes.whiteContainer} ${classes.containerPadding}`} id='about'>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} lg={3}>
              <Box textAlign={'center'}>
                 <LandingImgTooltip 
                  icon={DiscoverIcon}
                  img_src={discover}
                  alt_text='discover'
                />
                <Typography variant="h5" className={classes.title} style={{ margin: '16px 0' }}>Discover</Typography>
                <Typography className={classes.text}>
                  Access team rosters synced
                  with NCSA profiles. Get complete
                  academic info on each prospect.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box textAlign={'center'}>
                <LandingImgTooltip 
                  icon={PlanIcon}
                  img_src={plan}
                  alt_text='plan'
                />
                <Typography variant="h5" className={classes.title} style={{ margin: '16px 0' }}>Plan</Typography>
                <Typography className={classes.text}>
                  Get the schedule before
                  the event. Plan ahead with your
                  staff to maximize your time.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box textAlign={'center'}>
                <LandingImgTooltip 
                  icon={EvaluateIcon}
                  img_src={evaluate}
                  alt_text='evaluate'
                />
                <Typography variant="h5" className={classes.title} style={{ margin: '16px 0' }}>Evaluate</Typography>
                <Typography className={classes.text}>
                  Identify and evaluate
                  prospects from the stands.
                  Make notes on the fly.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Box textAlign={'center'}>
                <LandingImgTooltip 
                  icon={CollaborateIcon}
                  img_src={collaborate}
                  alt_text='collaborate'
                />
                <Typography variant="h5" className={classes.title} style={{ margin: '16px 0' }}>Collaborate</Typography>
                <Typography className={classes.text}>
                  Share and compare notes
                  with your staff in real time - tags
                  and notes auto-sync in the app.
                </Typography>
              </Box>

            </Grid>

          </Grid>
        </Container>
      </Box>

      <Box className={classes.containerPadding}>
        <Container maxWidth="lg">

          <Box textAlign={'center'} marginBottom={5}>
            <Typography variant="h4" component='span' color='secondary' className={classes.title} gutterBottom>Events</Typography>
            <Typography
              variant="subtitle1"
              className={classes.text}
            >
              Coach Packet by NCSA is the digital recruiting tool for 80+ partners and services over 1,000 events per year.
            </Typography>
            <Typography
              className={classes.text}
              style={{ fontWeight: 700, marginTop: 24 }}
              >
                Do you want to add your event to the app?
              </Typography>
              <Button
                variant="contained"
                color='secondary'
                target="_blank"
                rel="noopener noreferrer"
                href='https://bit.ly/CP_Event_Form'
                style={{ borderRadius: '50px', padding: '10px 30px', marginTop: "24px" }}
              >
                ADD EVENT
              </Button>
          </Box>

          <Grid container justifyContent='center' spacing={5}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_field_hockey} alt="usa_field_hockey" />
                </Box>

                <Typography variant="subtitle1" className={classes.text}>
                  USA Field Hockey
                </Typography>
              </Box>

            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_water_polo} alt="usa_water_polo" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  USA Water Polo
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usssa} alt="usssa" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  United States Specialty
                  Sports Association
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_hockey} alt="usa_hockey" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  The National Governing Body
                  of Ice Hockey in the United States
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={img_academy} alt="img_academy" />
                </Box>
                <Typography variant="subtitle1" className={classes.text}>
                  IMG Academy
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} >
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={prep_hoops} alt="prep_hoops" />
                </Box>

                <Typography className={classes.text}>
                  Prep Hoops
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_lacrosse} alt="usa_lacrosse" />
                </Box>

                <Typography className={classes.text}>
                  USA Lacrosse
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usa_baseball} alt="usa_baseball" />
                </Box>

                <Typography className={classes.text}>
                  USA Baseball
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={usys} alt="usys" />
                </Box>

                <Typography className={classes.text}>
                  US Youth Soccer
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3}>
                  <img src={pg} alt="pg" />
                </Box>

                <Typography className={classes.text}>
                  Perfect Game
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3} style={{ height: 110, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img style={{ maxWidth: "100%", height: 80 }} src={step} alt="3step" />
                </Box>

                <Typography className={classes.text}>
                  3step
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Box textAlign={'center'}>
                <Box marginBottom={3} >
                  <img style={{ maxHeight: 110 }} src={premier_lacrosse_league} alt="Premier Lacrosse League" />
                </Box>

                <Typography className={classes.text}>
                  Premier Lacrosse League
                </Typography>
              </Box>
            </Grid>

          </Grid>
          <Box marginTop={5} style={{ textAlign: "center", fontSize: "24px", fontWeight: 500 }}>
            <a
              href='https://www.ncsasports.org/who-is-ncsa/how-ncsa-works/ncsas-partners'
              target='_blank'
              rel="noreferrer"
              style={{ textDecoration: "none", color: '#005DA8' }}
            >
              All NCSA Partners
            </a>
          </Box>
        </Container>
      </Box>


      <Box className={`${classes.whiteContainer} ${classes.containerPadding}`}>
        <Container maxWidth="lg">
          <Box marginBottom={5} display={'flex'} justifyContent={'center'}>
            <Typography variant="h4" className={classes.title}>What Coaches</Typography>&nbsp;&nbsp;
            <Typography variant="h4" component='h4' color='secondary' className={classes.title} gutterBottom>Say</Typography>
          </Box>

          <Grid container justifyContent='center' spacing={3}>
            <Grid item xs={12} sm={6} lg={4} >
              <Box textAlign={'center'}>
                <GreatIcon />
                <Box marginBottom={5}>
                  <Typography className={classes.text} style={{ margin: '16px 0' }}>
                    “I prefer using Coach Packet over any other platform” – D3 field hockey Coach
                  </Typography>
                </Box>
                <CommaIcon color={"#0057B8"} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Box textAlign={'center'}>
                <GreatIcon />
                <Box marginBottom={5}>
                  <Typography className={classes.text} style={{ margin: '16px 0' }}>
                    “The idea that you can track players and take notes is amazing!” – D1 softball coach
                  </Typography>
                </Box>
                <CommaIcon color={"#0057B8"} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Box textAlign={'center'}>
                <GreatIcon />
                <Box marginBottom={5}>
                  <Typography className={classes.text} style={{ margin: '16px 0' }}>
                    “I use Coach Packet daily and I swear by it.” – D1 Field Hockey coach
                  </Typography>
                </Box>
                <CommaIcon color={"#0057B8"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className={classes.containerPadding}>
        <Container maxWidth="lg">
          <Box textAlign={'center'}>
            <Typography className={classes.text} >
              Used at
            </Typography>
            <Typography variant="h4" color='secondary' className={classes.title} gutterBottom>1,000 Events and growing</Typography>
            <Typography className={classes.text} style={{ marginBottom: 20 }}>
              Unlock free access to the largest digital recruiting booklet – start scouting the next generation of stars today.
            </Typography>

            <Button
              variant="contained"
              color='secondary'
              target="_blank"
              rel="noopener noreferrer"
              href='https://bit.ly/CoachPacket_YouTube'
              style={{ borderRadius: '50px', padding: '10px 30px', }}
            >
              Find out more
            </Button>
          </Box>
        </Container>
      </Box>

      <Box style={{ maxWidth: "100%", marginBottom: "16px", marginLeft: "16px" }}>
        <Grid container justifyContent='center' style={{ maxWidth: "100%" }} spacing={2}>
          <Grid item xs={12} md={6}>
            <Box className={`${classes.contactUs} ${classes.secondaryBg}`} id='contact'>
              <Typography variant="h4" component='h4' className={classes.title}>Customer Support</Typography>
              <Typography variant="h6" component='h6' className={classes.title} gutterBottom>How can we help?</Typography>
              <Link
                href='mailto:support@coachpacket.com'
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{ borderRadius: '50px', padding: '10px 30px', backgroundColor: 'white', color: '#0057B8' }}
                >
                  Contact Us
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={`${classes.contactUs} ${classes.secondaryBg}`}>
              <Typography variant="h4" component='h4' className={classes.title}>Add Your Event</Typography>
              <Typography variant="h6" component='h6' className={classes.title} gutterBottom>Fill out this form to add your event to Coach Packet</Typography>
              <Link
                href='https://forms.office.com/Pages/ResponsePage.aspx?id=82N1hG-_MEitUdZrM9lHHzsOG4W8izZHpdBxr3j6vBBUM05VU0JBTk1SSVhSQkxZMkNHS1NLTzNaUy4u'
                target='_blank'
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  style={{ borderRadius: '50px', padding: '10px 30px', backgroundColor: 'white', color: '#0057B8' }}
                >
                  Event Form
                </Button>
              </Link>
            </Box>
          </Grid>

        </Grid>
      </Box>

      <Box className={classes.containerPadding} style={{ backgroundColor: '#a0a09f', color: '#fff' }}>
        <Container maxWidth="lg">
          <Box className={classes.footerWrap}>
            <a target="_blank" rel="noopener noreferrer" href="https://x.com/CoachPacket">
              <img style={{ width: 22, height: 22, }} src={x_icon} alt="X" />
              {/* <Twitter style={{ color: '#fff', marginBottom: 5 }} /> */}
            </a>
          </Box>
        </Container>
      </Box>
      <Box className={classes.whiteContainer} textAlign={'center'} style={{ color: '#999997', padding: 5 }}>
        <Typography>
          <span>{`© ${new Date().getFullYear()} `}</span><a target="_blank" rel="noopener noreferrer" href="https://www.ncsasports.org/" style={{ color: '#999997' }}>NCSA College Recruiting</a>
        </Typography>
      </Box>
    </Box >

  );
}
