import { makeStyles, Theme } from '@material-ui/core/styles';
import { athleteColumns } from '../athleteListSettings';
import { Colors } from '../../../theme';

export default makeStyles((theme: Theme) => ({
  verticalDivider: {
    color: Colors.athlete.colDividerBody,
    height: 'calc(100% - 30px)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  horizontalDivider: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
      color: Colors.athlete.colDividerBody,
    },
  },
  cardContainer: {
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: 'none',
    marginBottom: theme.spacing(1), // 8px,
  },
  blockInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  footer: {
    display: 'block',
    position: 'relative',
    width: '100%',
    borderTop: '1px solid rgba(223, 226, 236, 1)',
  },
  notes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexBasis: athleteColumns[0].flexBasis + athleteColumns[1].flexBasis,
    maxWidth: (athleteColumns[0].maxWidth as number) + (athleteColumns[1].maxWidth as number),
    minWidth: (athleteColumns[0].minWidth as number) + (athleteColumns[1].minWidth as number),
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexBasis: 0,
      maxWidth: '100%',
      minWidth: '100%',
      flexGrow: 0,
    },
  },
  ranksContainer:{
    width: '65%',
    maxWidth: 800,
    [theme.breakpoints.down('md')]: {
      width: '55%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}));
