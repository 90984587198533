import { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { SnackbarProvider } from 'notistack';
import theme, { FontSizes } from './theme';

import { StateProvider } from '../state/StateContext';

import PublicPages from './PublicPages';
import Layout from './layout';

import { useAppStateValue } from '../state/StateContext';

import '../api/config';

import { DURATION_SNACKBAR, MAX_SHOW_SNACKBAR } from '../constants';
import { setGlobalIsUaUser } from '../helpers/globals';
import Login from './pages/Login';

const {
  REACT_APP_IS_VISIBLE_TASKS: tasksVisible,
} = process.env;

const useStyles = makeStyles((theme: Theme) =>
({
  root: {
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white,
    },
  },
  variantError: {
    color: '#323047',
    backgroundColor: '#F7E5E8',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '19px',
    '& svg': {
      color: '#B00020'
    }
  },
  variantSuccess: {
    color: '#323047',
    backgroundColor: '#F0F8F0',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '19px',
    '& svg': {
      color: '#179716'
    }
  },
  closeBtn: {
    color: theme.palette.common.black,
    fontSize: FontSizes.large,
  },
}),
);

function CoachPacketWebApp(): JSX.Element {
  const [{ isUserLogged }, dispatch] = useAppStateValue();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const validateSession = async () => {
    try {
      setIsLoading(true)

      if (localStorage.getItem('access_token')) {
        dispatch({ type: 'update', value: { isUserLogged: true } });
        setGlobalIsUaUser(true)
      }
      else {
        const session = await Auth.currentSession();

        const isUserLogged = session.isValid();
        dispatch({ type: 'update', value: { isUserLogged } });
        setGlobalIsUaUser(false)
      }
    }
    catch {
      console.log('Coach has to be authorized');
    }
    finally {
      setIsLoading(false)
    }
  };

  const getIsVisibleRanks = () => {
    if (localStorage.getItem('settings_is_visible_ranks') === null) {
      localStorage.setItem('settings_is_visible_ranks', 'true')
      dispatch({ type: 'update', value: { isVisibleRanks: true } })
    }
    else {
      dispatch({ type: 'update', value: { isVisibleRanks: Boolean(localStorage.getItem('settings_is_visible_ranks')) } });
    }
  };

  const getIsVisibleTasks = () => {
    if (tasksVisible === 'true') {
      dispatch({ type: 'update', value: { isVisibleTasks: true } })
    } else {
      dispatch({ type: 'update', value: { isVisibleTasks: false } })
    }
  }

  useEffect(() => {
    validateSession();
    // getIsVisibleRanks();
    getIsVisibleTasks();
  }, []);

  // Use for local development speed up
  // return <Layout />;
  if (isLoading) return <CircularProgress style={{ position: 'fixed', top: '50%', left: '50%' }} color="secondary" />

  if (!isUserLogged && window.location.pathname.includes('/ulink/athlete/')) return <Login />

  return isUserLogged ? <Layout /> : <PublicPages />;
}

function App() {
  const classes = useStyles();
  const snackbarRef = useRef<SnackbarProvider | null>(null);

  return (
    <StateProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          classes={{ root: classes.root, variantSuccess: classes.variantSuccess, variantError: classes.variantError }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={DURATION_SNACKBAR}
          maxSnack={MAX_SHOW_SNACKBAR}
          ref={snackbarRef}
          action={(key) => (
            <Button classes={{ root: classes.closeBtn }} onClick={() => snackbarRef.current?.closeSnackbar(key)}><CloseIcon /></Button>
          )}
        >
          <CoachPacketWebApp />
        </SnackbarProvider>
      </ThemeProvider>
    </StateProvider>
  );
}

export default App;
