import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Backdrop, ClickAwayListener, Fade, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(2),
    overflow: 'scroll',
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1),
    borderRadius: 5,
    overflow: 'scroll',
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    cursor: 'pointer',
  },
}));

const Body = React.forwardRef((props: any, ref: any) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
));

export type ModalTransitionProps = {
  open: boolean;
  children?: ReactNode;
  closeWindow: () => void;
}
const ModalTransition = (props: ModalTransitionProps) => {
  const { open, closeWindow, children } = props;

  const classes = useStyles();

  const handleClose = () => {
    closeWindow();
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
        <ClickAwayListener onClickAway={closeWindow}>
          <Fade in={open}>
            <Body className={classes.paper}>
              <CloseIcon
                classes={{ root: classes.closeIcon }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  closeWindow();
                }} />
              {children}
            </Body>
          </Fade>
        </ClickAwayListener>
    </Modal>
  );
};

export default ModalTransition;
