import { useMemo, useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import BaseMenu from '../../shared/BaseMenu';
import useAthleteRanksStyle from './useAthleteRanksStyle';
import { AthleteRankDto } from '../../../../api/ranks';
import { useAppStateValue } from '../../../../state/StateContext';
import AthleteRanks from './AthleteRanks';
import type { HistoryRank } from '../athlete-ranks-history/types';

export type AthleteGroupProps = {
  athleteId?: number;
  users_id?: number;
  ranks: AthleteRankDto[] | HistoryRank[];
  disabledInput?: boolean;
  customStyle?: any;
}

const AthleteGroup = (props: AthleteGroupProps) => {
  const { athleteId, users_id, ranks, disabledInput, customStyle = {} } = props;
  const [{ collegeGroups, account }] = useAppStateValue();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const classes = useAthleteRanksStyle({});
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const menuGroupsOptions: string[] = collegeGroups ? collegeGroups?.map(group => group.title) : [];

  const preparingRanks = useMemo(() => {

    var result = (ranks as AthleteRankDto[]).reduce((acc: any, item) => {
      if (item.college_rank_group_id) {
        if (acc[item.college_rank_group_id]) {
          acc[item.college_rank_group_id].push(item)
        } else {
          acc[item.college_rank_group_id] = [item]
        }
      }
      return acc
    }, {})

    for (const key in result) {
      const groupedRanks = result[key]

      if (result[key].length > 0 && result[key][0].hasOwnProperty('athlete_rank_history_id')) {
        result[key] = {
          usersRanks: groupedRanks,
          colleaguesRanks: []
        }
      }
      else {
        let res = (groupedRanks as AthleteRankDto[]).reduce((acc: any, item) => {
          if (acc[item.users_id]) {
            acc[item.users_id].push(item)
          } else {
            acc[item.users_id] = [item]
          }
          return acc
        }, {})

        let toArr: AthleteRankDto[][] = Object.getOwnPropertyNames(res).map(k => res[k]);
        let usersRanks = toArr.filter(el => el.find((rank: any) => rank.users_id === account?.id))
        let colleaguesRanks = toArr.filter(el => el.find((rank: any) => rank.users_id !== account?.id))

        result[key] = { usersRanks: usersRanks.flat(), colleaguesRanks }
      }
    }

    return result
  }, []);

  const [ranksList, setRanksList] = useState(preparingRanks);

  const handleMenuItemClick = async (idx: number) => {
    setSelectedIndex(idx)
  };

  return (
    <div className={classes.container} style={matches ? customStyle : {}}>
      {collegeGroups && collegeGroups?.length > 0 &&
        <BaseMenu
          options={menuGroupsOptions}
          customClass={classes.groupMenu}
          customStyle={{ backgroundColor: 'transparent' }}
          handleMenuItemClick={handleMenuItemClick}
          Title={menuGroupsOptions[selectedIndex]}
          MenuIcon={<ExpandMoreIcon />}
        />
      }
      <AthleteRanks
        ranksList={ranksList}
        athleteId={athleteId}
        users_id={users_id}
        groupId={(collegeGroups && collegeGroups[selectedIndex].college_rank_group_id) || 0}
        setRanksList={setRanksList}
        disabledInput={disabledInput}
      />
    </div>
  );
};

export default AthleteGroup;
