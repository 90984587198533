import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { ClickAwayListener } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useAthleteTasksStyle from '../useAthleteTasksStyle';
import { CollegeTasksDto } from '../../../../../api/tasks';
import AthleteTask from '../../athlete-task/AthleteTask';

export type SelectedTasksProps = {
  items: CollegeTasksDto[];
  selectItem: (task: CollegeTasksDto) => void;
  closeWindow: () => void;
  className?: string;
  style?: any;
}
const SelectedTasks = (props: SelectedTasksProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const classes = useAthleteTasksStyle();

  return (
    <ClickAwayListener onClickAway={props.closeWindow}>
      <div
        className={`${props.className || ''}`}
        style={props.style || {}}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          mr={5}
          p={2}
          style={{ pointerEvents: isLoading ? 'none' : 'inherit' }}
        >
          {props.items.map(t => {
            return (
              <AthleteTask
                key={t.college_task_id}
                collegeTask={t}
                isDisable={true}
                onAdd={async (task) => {
                  setIsLoading(true);
                  await props.selectItem(task as CollegeTasksDto);
                  setIsLoading(false);
                }}
              />
            );
          })}
        </Box>
        <CloseIcon
          classes={{ root: classes.closeIcon }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            props.closeWindow();
          }}/>
      </div>
    </ClickAwayListener>
  );
};

export default SelectedTasks;
