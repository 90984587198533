import React from 'react';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Colors } from '../../../theme';

export enum TypeDialogSummary {
  DELETE = 'delete',
  CLOSE = 'close',
}

export const summaryDialogOptions = {
  [TypeDialogSummary.DELETE]: {
    title: 'Delete the summary?',
    content: 'The summary will be permanently removed from your list.',
  },
  [TypeDialogSummary.CLOSE]: {
    title: 'Close editing?',
    content: 'Changes made may not be saved.',
  },
};

export const editSummaryMenuOptions: string[] = ['Save', 'Cancel'];
export const iconEditSummaryOptions = new Map([
  ['Save', <DoneOutlineIcon style={{ width: 18, height: 18 }}/>],
  ['Cancel', <CloseIcon style={{ width: 18, height: 18 }}/>],
]);
export const iconEditSummaryAdditionalOptions = new Map([
  ['Delete', <DeleteOutlineIcon style={{ width: 18, height: 18, color: Colors.svg.dangerous }}/>],
]);
