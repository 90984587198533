import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, CssBaseline, Toolbar, IconButton, Drawer, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/logo_52.svg';
import Login from './pages/Login';
import AdminAfterLogin from './pages/AdminAfterLogin';
import Landing from './pages/Landing';
import FAQ from './pages/FAQ';
import PublicNavBar from './blocks/publicPages/PublicNavBar';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logoContainer: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
    color: 'none',
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    maxWidth: 140,
  },
  logoImg: {
    width: 40,
    padding: 2,
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down(600)]: {
      display: 'block',
    },
  },
  toolbarButtons: {
    display: 'flex',
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  drawerContainer: {
    padding: '20px 30px',
  },
}));

export default function PublicPages() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Router>
      <CssBaseline />
      <div className={classes.root}>
        <AppBar position="static" color='secondary'>
          <Toolbar>
            <div className={classes.logoContainer}>
              <Link className={`${classes.logoLink} ${classes.link}`} to="/">
                <img src={logo} alt={'logo'} className={classes.logoImg} />
              </Link>
            </div>

            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon style={{ color: "#fff" }}/>
            </IconButton>

            <div className={classes.toolbarButtons}>
              <PublicNavBar />
            </div>

            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
              <Box
                className={classes.drawerContainer}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <PublicNavBar isMobile={true} />
              </Box>
            </Drawer>
          </Toolbar>
        </AppBar>
      </div>

      <Switch>
        <Route path="/admin-login/:username/:password" component={AdminAfterLogin} />
        <Route path="/login" component={Login} />
        <Route path="/faq" component={FAQ} />
        <Route path="/" component={Landing} />
      </Switch>
    </Router>
  );
}
