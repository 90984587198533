import React from "react";

export function useWasSeen(offset = 0) {
  // to prevents runtime crash in IE, let's mark it true right away
  const [wasSeen, setWasSeen] = React.useState(
    typeof IntersectionObserver !== "function"
  );

  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (ref.current && !wasSeen) {
      const observer = new IntersectionObserver(
        ([entry]) => entry.isIntersecting && setWasSeen(true), {
          rootMargin: offset + 'px'
        }
      );
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [wasSeen]);
  return [wasSeen, ref] as const;
}
