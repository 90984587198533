import { API } from 'aws-amplify';

import ApiResponse from './ApiResponse';
import { DEFAULT_ERROR_MESSAGE } from '../constants';

export type TournamentShortListDto = {
  id: number;
  name: string;
  sports_id: number;
}[];

export async function getTournamentsShortList(): Promise<
  ApiResponse<TournamentShortListDto>
> {
  try {
    const data: TournamentShortListDto = await API.get(
      'legacyAPI',
      '/tournaments/short',
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function getTournamentEvalsCount(id: number, isAllNotes: string): Promise<
  ApiResponse<any>
> {
  try {
    const data: any = await API.get(
      'legacyAPI',
      `/tournaments/${id}/evals/count${isAllNotes}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function getTournamentEvals(id: number, isAllNotes: string): Promise<
  ApiResponse<void>
> {
  try {
    const data: any = await API.get(
      'legacyAPI',
      `/tournaments/${id}/evals${isAllNotes}`,
      {
        responseType: 'blob'
      }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}
