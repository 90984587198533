import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useAppStateValue } from '../../state/StateContext';
import { AccountInitialSportsDto } from '../../api/account'
import { SPORT_LIST } from './athletes/mocks/hardcoded-data'
import { DRAWER_WIDTH } from '../../constants';
import { setGlobalSport } from '../../helpers/globals';

type Props = {
  resize: boolean;
  handleDrawerToggle: () => void;
};

const colors = {
  appBar: `#005DA8`,
};

const useStyles = makeStyles((theme: Theme) =>
({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBar: {
    background: colors.appBar,
    [theme.breakpoints.up('sm')]: {
      width: `100%`,
      marginLeft: DRAWER_WIDTH.desktop,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShift: {
    background: colors.appBar,
    [theme.breakpoints.up('sm')]: {
      width: `100%`,
      marginLeft: DRAWER_WIDTH.mobile,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarRoot: {
    boxShadow: 'none',
    zIndex: 1299,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    /*eslint no-useless-computed-key: "off"*/
    ['& svg']: {
      color: '#fff',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  backgroundButtons: {
    '& .MuiSvgIcon-root': {
      color: '#fff',
    }

  },
  name: {
    padding: 12,
    fontSize: 14
  },
  university: {
    padding: 8,
    fontSize: 14,
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  verticalDivider: {
    borderWidth: 0.5,
    borderColor: '#73849D',
  },
  multiSportButton: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#fff',
    fontWeight: 400,
  },
})
);

export default function ApplicationBar(props: Props) {
  const { resize, handleDrawerToggle } = props;
  const classes = useStyles();
  const [anchorElMultSport, setAnchorElMultSport] = React.useState<null | HTMLElement>(null);
  const [{ account, activeSport }] = useAppStateValue();

  const sportObj: any = SPORT_LIST.reduce((acc, cur) => ({
    ...acc,
    [cur.id]: cur.name,
  }), {});

  const sportsList: AccountInitialSportsDto[] = account ? account.sports : []

  function handleClickMultiSport(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorElMultSport(event.currentTarget);
  }

  function handleCloseMultiSport() {
    setAnchorElMultSport(null);
  }

  const handleMenuItemClick = async (sport: AccountInitialSportsDto) => {
    setGlobalSport(sport)
    window.location.reload()
  }

  const renderSportMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorElMultSport}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      keepMounted
      open={Boolean(anchorElMultSport)}
      onClose={handleCloseMultiSport}
    >
      {
        sportsList?.map((sport: AccountInitialSportsDto) => {
          return (
            <MenuItem key={sport.sports_id} onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleMenuItemClick(sport);
              handleCloseMultiSport();
            }}>
              {sportObj[sport.sports_id]}
            </MenuItem>
          )
        })
      }
    </Menu>
  )

  return (
    <>
      <AppBar
        position="fixed"
        className={resize ? classes.appBarShift : classes.appBar}
        classes={{
          root: classes.appBarRoot,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {sportsList.length === 1 ?
              <Typography className={classes.university}>{activeSport ? sportObj[activeSport.sports_id] : ""}</Typography> :
              <Button
                className={classes.multiSportButton}
                onClick={handleClickMultiSport}
                endIcon={activeSport ? <ExpandMoreIcon color="primary" classes={{ colorPrimary: classes.multiSportButton }} /> : ''}
              >
                {activeSport ? sportObj[activeSport.sports_id] : ""}
              </Button>
            }
          </div>
        </Toolbar>
      </AppBar>
      {renderSportMenu}
    </>
  );
}
