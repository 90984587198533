import { createContext, Dispatch, ReactNode, useContext, useReducer } from 'react';
import { AthletesListAthleteType } from '../api/athletes';
// ----------------------------------------------------------------------


interface SearchAthleteState {
  isStateInitialized: boolean;
  athletes: AthletesListAthleteType[];
}

const initialState: SearchAthleteState = {
  isStateInitialized: false,
  athletes: [],
};

type StateActionType = 'updateState' | 'initState' | 'clearState';

interface SearchAthleteStateAction {
  type: StateActionType;
  value?: { [Property in keyof SearchAthleteState]?: SearchAthleteState[Property] };
}

function SearchAthleteReducer(state: SearchAthleteState, action: SearchAthleteStateAction): SearchAthleteState {
  switch (action.type) {
    case 'updateState':
      return {
        ...state,
        ...action.value,
      };

    case 'initState':
      return {
        ...state,
        isStateInitialized: true,
      };
    case 'clearState':
      return {
        ...initialState
      };

    default:
      return state;
  }
}



const SearchAthleteStateContext = createContext(
  {} as [state: SearchAthleteState, dispatch: Dispatch<SearchAthleteStateAction>]
);

// ----------------------------------------------------------------------

export const SearchAthleteStateProvider = ({ children }: { children: ReactNode }) => {

  const [state, action] = useReducer(SearchAthleteReducer, initialState)


  return (<SearchAthleteStateContext.Provider value={[state, action]}>
    {children}
  </SearchAthleteStateContext.Provider>)
};

export const useSearchAthleteStateValue = () => useContext(SearchAthleteStateContext);