import React from 'react';
import { Fade, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import useNoteStyle from './useNoteStyle';
import Note from './fragments/Note';
import EditNote from './fragments/EditNote';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import BaseButton from '../../shared/BaseButton';
import { CollegeNoteDto, createCollegeNote, deleteCollegeNote, updateCollegeNote, updateNotePDA } from '../../../../api/notes';
import DateHelpers from '../../../../helpers/DateHelpers';
import { useAppStateValue } from '../../../../state/StateContext';

export type AthleteNotesProps = {
  athleteId: number;
  isMoreNotes?: boolean;
  notes: CollegeNoteDto[];
  updateAthleteNotes: (notes: CollegeNoteDto[]) => void;
}
const AthleteNotes = (props: AthleteNotesProps) => {
  const { notes: allNotes, updateAthleteNotes } = props;
  const notes = allNotes.filter(note => note.category !== 13)
  const [{ account, activeSport, }] = useAppStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenNewNote, setIsOpenNewNote] = React.useState(false);

  const classes = useNoteStyle();

  const saveNote = (updateNote: CollegeNoteDto, note?: CollegeNoteDto) => {
    const optionsForAddingTags = account && account.sports.length > 1 ? {
      athlete_master_id: props.athleteId,
      note: updateNote.note,
      college_program_id: activeSport && activeSport.college_program_id
    } :
      {
        athlete_master_id: props.athleteId,
        note: updateNote.note
      }
    if (note?.college_note_id) {
      updateCollegeNote(

        note.college_note_id,
        optionsForAddingTags,
      ).then(res => {
        if (!res.success) {
          enqueueSnackbar(res.error?.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar('Note updated successfully', { variant: 'success' });
        const athleteNote: CollegeNoteDto = res.data as CollegeNoteDto;
        // Update note in state of athlete
        const idx = notes.findIndex(i => i.college_note_id === note?.college_note_id);
        const updateNotes = [
          ...notes.slice(0, idx),
          { date_modified: new Date().toString(), ...{ ...athleteNote } },
          ...notes.slice(idx + 1),
        ].sort(AthleteHelpers.reorderNotes);

        updateAthleteNotes(updateNotes);
      });
    } else {
      createCollegeNote(optionsForAddingTags)
        .then(res => {
          if (!res.success) {
            enqueueSnackbar(res.error?.message, { variant: 'error' });
          } else {
            enqueueSnackbar('Note created successfully', { variant: 'success' });
            const newNote = res.data as CollegeNoteDto;
            // Create note in state of athlete
            const newNotes = [
              {
                ...newNote,
                athlete_master_id: props.athleteId,
                date_modified: new Date().toString(),
                pinned: false,
              },
              ...notes,
            ].sort(AthleteHelpers.reorderNotes);

            updateAthleteNotes(newNotes);
          }
        });
      setIsOpenNewNote(false);
    }
  };

  const deleteNote = (deleteNote: CollegeNoteDto) => {
    const athleteNoteId = deleteNote.college_note_id || 0;
    try {
      deleteCollegeNote(athleteNoteId)
        .then(() => {
          enqueueSnackbar('Note removed successfully', { variant: 'success' });
          // Delete note in state of athlete
          updateAthleteNotes(notes.filter(n => n.college_note_id !== athleteNoteId));
        });
    } catch (e) {
      enqueueSnackbar(e.message || 'Error removed note!', { variant: 'error' });
    }
  };

  const updateStatusPinned = async (college_note_id: number, pda: number) => {
    let response = await updateNotePDA(college_note_id, pda)

    if (response.success) {

      const idx = notes.findIndex(i => i.college_note_id === college_note_id);
      const updateNotes = [
        ...notes.slice(0, idx),
        { ...notes[idx], export_pda: pda },
        ...notes.slice(idx + 1),
      ].sort(AthleteHelpers.reorderNotes);

      updateAthleteNotes(updateNotes);
    } else {
      enqueueSnackbar(response.error?.message || 'Error pinned note', { variant: 'error' });
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.notes}>
        {!props.isMoreNotes && notes.filter((_, idx) => idx < 3).map((note, i) => {
          return <Note
            key={i}
            note={note}
            saveNote={saveNote}
            removeNote={deleteNote}
            updateStatusPinned={updateStatusPinned}
          />;
        })}
        {props.isMoreNotes && notes.map((note, i) => {
          return <Note
            key={i}
            note={note}
            saveNote={saveNote}
            removeNote={deleteNote}
            updateStatusPinned={updateStatusPinned}
          />;
        })}
      </div>
      {!isOpenNewNote && <div style={{ width: '100%' }}>
        <BaseButton
          title={'+ Add Note'}
          customClass={classes.actionBtn}
          onClick={() => setIsOpenNewNote(true)}
        />
      </div>
      }
      {isOpenNewNote && <Fade in={isOpenNewNote}>
        <div className={classes.note}>
          <Typography className={classes.dateModified}>
            {DateHelpers.toLocaleDateString({ dateStr: new Date().toString() || '' })}
          </Typography>

          <EditNote
            saveNote={saveNote}
            removeNote={deleteNote}
            closeNote={() => setIsOpenNewNote(false)}
          />
        </div>
      </Fade>}
    </div>
  );
};

export default AthleteNotes;
