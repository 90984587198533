import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAthleteCardStyle from './useAthleteCardStyle';
import AthletePersonalInfo from '../athlete-personal-info/AthletePersonalInfo';
import AthleteTabInfo from '../athlete-tab-info/AthleteTabInfo';
import AthleteNotes from '../athlete-notes/AthleteNotes';
import { AthletesListAthleteType, AthleteTagsType, GroupedAthleteTaskType } from '../../../../api/athletes';
import { CollegeTagsDto } from '../../../../api/tags';
import { AthleteRankDto } from '../../../../api/ranks';
import config from '../../../../api/config';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import AthleteTags from '../athlete-tags/AthleteTags';
import AthleteGroup from '../athlete-rank/AthleteGroup';
import { CollegeNoteDto } from '../../../../api/notes';
import { useAppStateValue } from '../../../../state/StateContext';
import { AthleteTasksDto, CollegeTasksDto } from '../../../../api/tasks';
import AthleteTasks from '../athlete-tasks/AthleteTasks';
import { useWasSeen } from '../../../../hooks/useWasSeen';
import AthleteGeneralGroup from '../athlete-rank/fragments/AthleteGeneralGroup';
import { Box } from '@material-ui/core';

export type AthleteCardProps = {
  athlete: AthletesListAthleteType;
  athletesChecked: number[];
  collegeTags: CollegeTagsDto[];
  collegeTasks: CollegeTasksDto[];
  activeSport: number;
  users_id: number;
  handleAthleteChecked: (athleteId: number, isCheck: boolean) => void;
  // updateAthleteState: (athleteId: number, params: { notes?: CollegeNoteDto[], tags?: AthleteTagsResponse[] }) => void;
}

const AthleteCard = (props: AthleteCardProps) => {
  const {
    athlete,
    athletesChecked,
    collegeTags,
    collegeTasks,
    activeSport,
    users_id,
    handleAthleteChecked,
    // updateAthleteState,
  } = props;

  const classes = useAthleteCardStyle();
  const [{
    collegeGroups,
    isVisibleRanks,
    isVisibleTasks,
  }] = useAppStateValue();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [wasSeen, ref] = useWasSeen(400);

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [notes, setNotes] = useState<CollegeNoteDto[]>(() => athlete.notes.sort(AthleteHelpers.reorderNotes));
  const [tags, setAthleteTags] = useState<AthleteTagsType[]>(() =>
    AthleteHelpers.filterAthleteTags(collegeTags, athlete.tags));

  const [tasks, setAthleteTasks] = useState<GroupedAthleteTaskType[]>(athlete.tasks);

  const updateAthleteNotes = (athleteNotes: CollegeNoteDto[]) => {
    setNotes(athleteNotes);
    // updateAthleteState(athlete.athlete_master_id, { notes: athleteNotes });
  };

  const updateAthleteTags = (athleteTags: AthleteTagsType[]) => {
    setAthleteTags(athleteTags);
    // updateAthleteState(athlete.athlete_master_id, { tags: athleteTags });
  };

  return (
    <div ref={ref} className={`${classes.cardContainer} test-class-card-container`}>
      <div className={classes.blockInfo}>
        <AthletePersonalInfo
          athlete={athlete}
          setShowMoreInfo={setShowMoreInfo}
          showMoreInfo={showMoreInfo}
          athletesChecked={athletesChecked}
          handleAthleteChecked={handleAthleteChecked}
          urlNCSA={config.urlNCSA}
        />
        <Divider classes={{ root: classes.verticalDivider }} orientation={'vertical'} />
        <Divider classes={{ root: classes.horizontalDivider }} orientation={'horizontal'} />

        {matches && showMoreInfo && <AthleteTabInfo
          athlete={athlete}
          collegeTags={collegeTags}
          setShow={setShowMoreInfo}
          activeSport={activeSport || 0}
          notes={notes}
          updateAthleteNotes={updateAthleteNotes}
        />}

        <Divider />

        <div className={classes.ranksContainer} style={{ minHeight: isVisibleTasks ? 170 : 125 }}>
          {wasSeen && (<>
            {/* TODO: Ranks */}
            {/* {isVisibleRanks && collegeGroups && collegeGroups?.length !== 0 &&
              <>
                <AthleteGroup
                  ranks={athlete.ranks as AthleteRankDto[]}
                  athleteId={athlete.athlete_master_id}
                  users_id={users_id || 0}
                />
                <Divider classes={{ root: classes.verticalDivider }} orientation={'vertical'} />
                <Divider classes={{ root: classes.horizontalDivider }} orientation={'horizontal'} />
              </>
            } */}
            <Box display={'flex'}>
              <AthleteGeneralGroup athleteRatings={athlete.ranks as AthleteRankDto[]} athlete_master_id={athlete.athlete_master_id} />
              <AthleteTags
                collegeTags={collegeTags}
                athleteId={athlete.athlete_master_id}
                users_id={users_id || 0}
                personalInfo={{ last: athlete.last, first: athlete.first }}
                tags={tags}
                updateAthleteTags={updateAthleteTags}
              />
            </Box>
            {isVisibleTasks && (<AthleteTasks
              collegeTasks={collegeTasks}
              athleteId={athlete.athlete_master_id}
              users_id={users_id || 0}
              personalInfo={{ last: athlete.last, first: athlete.first }}
              tasks={tasks}
              updateAthleteTasks={setAthleteTasks}
            />)}
          </>)}
        </div>
      </div>

      {/* Footer: NOTES and TASKS*/}

      {!matches && showMoreInfo && <AthleteTabInfo
        athlete={athlete}
        collegeTags={collegeTags}
        setShow={setShowMoreInfo}
        activeSport={activeSport || 0}
        notes={notes}
        updateAthleteNotes={updateAthleteNotes}
      />}

      {!showMoreInfo &&
        <Divider />
        &&
        <div className={classes.footer}>

          <div className={classes.notes}>
            <AthleteNotes
              athleteId={athlete.athlete_master_id}
              notes={notes}
              updateAthleteNotes={updateAthleteNotes}
            />
            {/*<Divider orientation="vertical" classes={{ root: classes.verticalDivider }} />*/}
          </div>
          {/*TODO: Tasks*/}
          {/*<AthleteTasks tasks={testTasks} />*/}
        </div>
      }

    </div>
  );
};
export default AthleteCard;
