import React, { useState } from 'react';

import { Box, FormControlLabel, Switch, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import BaseInput from '../../shared/BaseInput';
import BaseAutocomplete from '../../shared/BaseAutocomplete';
import { ItemType } from '../../shared/types';
import { useAppStateValue } from '../../../../state/StateContext';
import { PositionType } from '../../../../helpers/data';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import useAthleteOptionsStyle from './useAthleteOptionsStyle';
import { AthleteListQueryDto } from '../../../../api/athletes';


export type AthleteOptionsProps = {
  state: AthleteListQueryDto;
  setState: React.Dispatch<React.SetStateAction<AthleteListQueryDto>>;
}

const AthleteOptions = (props: AthleteOptionsProps) => {
  const { state, setState } = props;
  const [{ activeSport }] = useAppStateValue();
  const [hideInputs, setHideInputs] = useState<boolean>(true);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const spacingGridSm = useMediaQuery('(min-width:800px)');

  const classes = useAthleteOptionsStyle();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <Box className={classes.athTopOptionsWrap}>
      <Box className={classes.formControlWrap}>
        {matches && <Box>Basic</Box>}
        <FormControlLabel
          className={classes.formControlSwitch}
          control={
            <Switch
              checked={hideInputs}
              onChange={event => setHideInputs(event.target.checked)}
              color="secondary"
              name="is_hide_inputs"
              inputProps={{ 'aria-label': 'event hide inputs' }}
            />
          }
          label={!matches && !hideInputs ? 'Basic' : ''}
        />
      </Box>
      {hideInputs &&
        <Grid container spacing={2}>
          <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
            <BaseInput
              classes={{ root: classes.input }}
              id="first-name"
              label="First name"
              name="first"
              type="search"
              value={state.first}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2} >
            <BaseInput
              classes={{ root: classes.input }}
              id="last-name"
              label="Last name"
              name="last"
              type="search"
              value={state.last}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
            <BaseAutocomplete
              className={classes.input}
              limitTags={2}
              label="Grad. year"
              value={state.gradyears}
              options={AthleteHelpers.gradYearList}
              setValues={values => setState({ ...state, gradyears: values as number[] })}
            />
          </Grid>
          <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2} >
            <BaseAutocomplete
              className={classes.input}
              label="Position"
              limitTags={2}
              value={AthleteHelpers.getPositionsBySport(activeSport?.sports_id || 0).filter(i => state.positions?.find((p: PositionType) => p.id === i.id))}
              options={AthleteHelpers.getPositionsBySport(activeSport?.sports_id || 0)}
              setValues={values => setState({
                ...state,
                positions: (values as PositionType[]).map((item: PositionType) => ({ id: item.id, name: item.shortName, shortName: item.shortName })),
              })}
            />
          </Grid>
          {/* <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
            <BaseInput
              classes={{ root: classes.input }}
              id="club"
              label="Club"
              value={state.club}
              type="search"
              name="club"
              onChange={handleChange}
            />
          </Grid> */}
          <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
            <BaseInput
              classes={{ root: classes.input }}
              id="team"
              label="Team"
              value={state.team}
              type="search"
              name="team"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={spacingGridSm ? 6 : 12} md={4} lg={2}>
            <BaseAutocomplete
              className={classes.input}
              label="State"
              limitTags={4}
              value={(AthleteHelpers.getStatesByCountry()?.opts || []).filter(i => state.states?.includes(i.id))}
              showId={true}
              groupOptions={AthleteHelpers.getStatesByCountry() || {group: '', opts: []}}
              setValues={values => setState({
                ...state,
                states: (values as ItemType[]).map((item: ItemType) => (item as { id: string })?.id) as string[],
              })}
            />
          </Grid>
        </Grid>}
    </Box>
  );
};

export default AthleteOptions;
