import { Box, Button, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'none',
  },
  activeLink: {
    color: `${theme.palette.primary.main} !important`,
  },
  navBtn: {
    color: '#fff',
    height: '100%',
  },
  mobileNav: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileLink: {
    width: "100%",
    margin: "4px 0",
    color: '#000',
  },
}));

export default function PublicNavBar({ isMobile = false }) {
  const classes = useStyles();
  const location = useLocation();
  
  const isFaqPage = location.pathname === '/faq';
  const isLoginPage = location.pathname === '/login';

  return (
    <Box className={isMobile ? classes.mobileNav : ''}>
      <HashLink smooth to={'/#about'} className={`${classes.link} ${isMobile ? classes.mobileLink : ''}`}>
        <Button className={`${classes.navBtn} ${isMobile ? classes.mobileLink : ''}`}>
          About
        </Button>
      </HashLink>
      <HashLink smooth to={'/#contact'} className={`${classes.link} ${isMobile ? classes.mobileLink : ''}`}>
        <Button className={`${classes.navBtn} ${isMobile ? classes.mobileLink : ''}`}>
          Contact
        </Button>
      </HashLink>
      <a href='https://bit.ly/CoachPacket_YouTube' target="_blank" rel="noreferrer" className={`${classes.link} ${isMobile ? classes.mobileLink : ''}`}>
        <Button className={`${classes.navBtn} ${isMobile ? classes.mobileLink : ''}`}>
          How To Videos
        </Button>
      </a>
      <Link to='/faq' className={`${classes.link} ${isMobile ? classes.mobileLink : ''}`}>
        <Button className={`${classes.navBtn} ${isMobile ? classes.mobileLink : ''} ${isFaqPage ? classes.activeLink : ""}`}>
          FAQ
        </Button>
      </Link>
      <Link to='/login' className={`${classes.link} ${isMobile ? classes.mobileLink : ''}`}>
        <Button className={`${classes.navBtn} ${isMobile ? classes.mobileLink : ''} ${isLoginPage ? classes.activeLink : ""}`}>
          Login
        </Button>
      </Link>
    </Box>
  );
}
