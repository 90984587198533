import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { CircularProgress, Typography, Zoom } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from 'notistack';
import BaseCheckbox from '../../shared/BaseCheckbox';
import MenuAthleteOrder from '../fragments/MenuAthleteOrder';
import { Colors, ColorTypes } from '../../../theme';
import LocalStorageHelpers from '../../../../helpers/LocalStorageHelpers';
import { addAthletesToFrontRush, isSyncedUserWithFrontRush, loginUrlFrontRush } from '../../../../api/frontRush';
import { useAppStateValue } from '../../../../state/StateContext';
import { OrderType } from '../Athlete.types';
import BaseButton from '../../shared/BaseButton';
import { ATHLETE_APP_BAR } from '../../../../constants';
import HtmlHelpers from '../../../../helpers/HtmlHelpers';


const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: ATHLETE_APP_BAR,
    backgroundColor: Colors.athlete.bgTableHeader,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  blockLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  blockRight: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    color: Colors.default[ColorTypes.secondary],
    width: 214,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      width: 'auto',
    },
  },
  actionBtn: {
    ...theme.typography.actionBtn,
    height: 32,
    '&:hover': {
      backgroundColor: Colors.common.hoverOnPink,
    },
    fontSize: 14,
  },
  selectedAthletes: {
    ...theme.typography.actionLink,
    alignSelf: 'center',
    fontWeight: 400,
    fontSize: 14,
  },
}));

type AthleteAppBarProps = {
  totalAthletes: number;
  athletesChecked: number[];
  setAllAthletesChecked: () => void;
  clearAllAthletesChecked: () => void;
  changeParams: (params: { sort_by: string, sort_order: OrderType }) => void;
}
const AthleteAppBar = (props: AthleteAppBarProps) => {
  const { totalAthletes, athletesChecked, setAllAthletesChecked, clearAllAthletesChecked, changeParams } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [{ account, athleteListQuery }] = useAppStateValue();
  const { enqueueSnackbar } = useSnackbar();

  const isCheck = useMemo(() => athletesChecked.length > 0, [athletesChecked]);

  let sortBy = athleteListQuery.sort_by;
  let sortOrder = athleteListQuery.sort_order;

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCheckbox = (isCheck: boolean) => {
    isCheck ? setAllAthletesChecked() : clearAllAthletesChecked();
  };

  const addToFrontRush = async () => {
    if (!(athletesChecked.length > 0)) return;

    const coachId = account?.id || 0;

    const coachData = LocalStorageHelpers.frontRush;
    let isSync = coachData.synced;
    if (!isSync) {
      const { success, data } = await isSyncedUserWithFrontRush(coachId);
      isSync = (success && data?.synced) || false;
      LocalStorageHelpers.saveFrontRush(isSync);
    }

    if (!isSync) {
      const { data } = await loginUrlFrontRush(coachId)
      const htmlElement = HtmlHelpers.prepareHtmlElementForOpenUrl(data?.url || '')
      return htmlElement.click();
    }

    setIsLoading(true);
    let data = await addAthletesToFrontRush(coachId, athletesChecked, 0, account?.college_program_id || 0);
    if (data?.data?.synced) {
      enqueueSnackbar(`${data?.data?.synced} athletes was synced with Front Rush`, { variant: 'success' });
    } else {
      // @ts-ignore
      enqueueSnackbar(data?.error?.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  return (
    <div className={classes.appBar}>
      <div className={classes.blockLeft}>
        <BaseCheckbox
          isCheck={isCheck}
          handleCheckbox={handleCheckbox}
          customStyle={{
            margin: '3px 8px 3px 5px', borderRadius: 5, padding: 6,
            [theme.breakpoints.down('sm')]: {
              marginLeft: 5,
            },
          }} />
        {athletesChecked.length > 0 && <Box display={'flex'} alignItems={'center'}>
          <Zoom in={athletesChecked.length > 0}>
            <>
              <BaseButton
                customClass={classes.actionBtn}
                customStyle={{ pointerEvents: isLoading ? 'none' : 'inherit', opacity: isLoading ? 0.6 : 1 }}
                title={'Add to Front Rush'}
                onClick={addToFrontRush}
              />
              {isLoading && <CircularProgress size={14} color={'secondary'} />}
            </>
          </Zoom>
        </Box>}
      </div>
      <div className={classes.blockRight}>
        {!matches && athletesChecked.length > 0 &&
          <Zoom in={athletesChecked.length > 0}>
            <Typography className={classes.selectedAthletes}>
              1-{athletesChecked.length} of {totalAthletes}
            </Typography>
          </Zoom>}
        <Box marginLeft={'auto'} >
          <MenuAthleteOrder
            sortBy={sortBy as string}
            sortOrder={sortOrder as OrderType}
            onChangeOrder={({ sort, field }) => {
              if (sort === sortOrder && field === sortBy) return;
              sortBy = field;
              sortOrder = sort;
              changeParams({ sort_by: sortBy, sort_order: sortOrder });
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default AthleteAppBar;
