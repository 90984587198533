import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { mainMenuDic } from '../../dictionary';

import {
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';

import MainMenuItems from '../blocks/MainMenuItems';
import { DRAWER_WIDTH } from '../../constants';
import { Colors } from '../theme';

type Props = {
  resize: boolean;
  handleResize: () => void;
};

const menuItemColor = '#1B4484';

const menuIcon = {
  color: menuItemColor,
  minWidth: `${DRAWER_WIDTH.mobile - 16}px`,
};
const menuText = {
  fontWeight: 400,
  color: menuItemColor,
  fontSize: '1rem',
  lineHeight: '1.25rem',
  textTransform: 'capitalize' as 'capitalize',
  minWidth: 0,
};
const drawerStyle = {
  backgroundColor: '#FFFF',
  border: 0,
  overflowX: 'hidden' as 'hidden',
  zIndex: 1299,
};

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    ...drawerStyle,
    width: DRAWER_WIDTH.desktop,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperShift: {
    ...drawerStyle,
    width: DRAWER_WIDTH.mobile,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toggleStyle: {
    width: '100%',
    minWidth: `${DRAWER_WIDTH.mobile - 24}px`,
    '&:hover': {
      backgroundColor: Colors.common.hoverOnWhite,
    },
  },
  divider: {
    marginBottom: 8,
  },
  listItemRadius: {
    borderRadius: '5px',
  },
  itemGutters: {
    paddingLeft: '12px',
  },
  itemGuttersShift: {
    paddingLeft: '12px',
  },
  menuTextNoWrap: {
    whiteSpace: 'nowrap',
  },
  menuIcon,
  menuText,
}));

const drawerToggleSidebar = {
  rtl: {
    ResizeToggleIcon: ChevronLeftIcon,
    ToggleIcon: ChevronRightIcon,
  },
  ltr: {
    ResizeToggleIcon: ChevronRightIcon,
    ToggleIcon: ChevronLeftIcon,
  },
};

export default function DesktopMainMenu(props: Props) {
  const { resize, handleResize } = props;
  const theme = useTheme();
  const classes = useStyles();

  const { ResizeToggleIcon, ToggleIcon } = drawerToggleSidebar[theme.direction];

  const actionsMenuItems = [
    {
      text: mainMenuDic.toggle,
      action: handleResize,
      icon: resize ? (
        <ResizeToggleIcon />
      ) : (
        <ToggleIcon />
      ),
    },
  ];

  return (
    <Hidden xsDown implementation="css">
      <Drawer
        classes={
          resize
            ? {
              paper: classes.drawerPaperShift,
            }
            : {
              paper: classes.drawerPaper,
            }
        }
        variant="permanent"
        open
      >
        <MainMenuItems resize={resize} />
        <Box justifyContent={'flex-end'} mb={2} mt={'auto'} p={'0 12px'}>
          <Divider className={classes.divider} />

          <List>
            {actionsMenuItems.map((menuItem) => (
              <ListItem
                button
                key={menuItem.text}
                onClick={menuItem.action}
                className={classes.listItemRadius}
                classes={
                  resize
                    ? { gutters: classes.itemGuttersShift }
                    : { gutters: classes.itemGutters }
                }
              >
                <ListItemIcon className={classes.menuIcon}>
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText
                  className={`${classes.menuText} ${classes.menuTextNoWrap}`}
                  primary={menuItem.text}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Hidden>
  );
}
