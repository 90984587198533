import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    cutText?: any;
    lineClamp?: any;
    baseText?: any;
    emptyText?: any;
    actionLink?: any;
    actionBtn?: any;
    disable?: any;
    baseBorder?: any;
  }

  interface TypographyOptions {
    cutText?: any;
    lineClamp?: any;
    baseText?: any;
    emptyText?: any;
    actionLink?: any;
    actionBtn?: any;
    disable?: any;
    baseBorder?: any;
  }
}

export enum ColorTypes {
  primary = 'primary',
  textPrimary = 'textPrimary',
  secondary = 'secondary',
  textSecondary = 'textSecondary',
  default = 'default',
  paper = 'paper'
}

export enum Fonts {
  Roboto = 'Roboto',
  Lato = 'Lato',
}

export enum FontSizes {
  preSmall = '0.5rem', // 8px
  small = '0.625rem', // 10px
  preMedium = '0.75rem', // 12px
  medium = '0.875rem', // 14px
  extraMedium = '1rem', // 16px
  preLarge = '1.125rem', // 18px
  large = '1.25rem', // 20px
  extraLarge = '1.5rem', // 24px
  huge = '1.875rem' // 30px
}

export const Colors = {
  default: {
    [ColorTypes.primary]: 'rgba(246, 139, 22, 1)',
    [ColorTypes.textPrimary]: 'rgba(255, 255, 255, 1)',
    [ColorTypes.secondary]: '#005DA8',
    [ColorTypes.textSecondary]: 'rgba(255, 255, 255, 1)',
    [ColorTypes.default]: '#F7F6FB',
    [ColorTypes.paper]: 'rgba(255, 255, 255, 1)',
  },
  table: {
    header: 'rgba(175, 175, 175, 1)',
    body: 'rgba(50, 49, 47, 1)',
  },
  tab: {
    bgColor: 'rgba(245, 245, 245, 1)',
    selected: 'rgba(232, 231, 231, 1)',
    hover: 'rgba(232, 231, 231, 0.3)',
  },
  text: {
    base: 'rgba(50, 48, 71, 1)',
    title: 'rgba(27, 68, 132, 1)',
  },
  shadow: {
    border: 'rgba(7, 29, 67, 0.05)',
  },
  svg: {
    secondary: 'rgb(15, 43, 91)',
    lightGray: 'rgb(214, 219, 224)',
    dangerous: 'rgb(242, 51, 0)',
  },
  rank: {
    bgRank: 'rgba(238, 246, 255, 1)',
    textRank: 'rgba(153, 154, 163, 1)',
  },
  tag: {
    bgTag: 'rgba(247, 246, 251, 1)',
    bgPrimaryTag: 'rgba(191, 191, 191, 1)',
    bgRegularTag: 'rgba(242, 242, 242, 1)',
    colorRegular: 'rgba(256, 256, 256, 1)',
  },
  task: {
    bgTask: 'rgba(234, 239, 245, 1)',
    textTask: 'rgba(27, 68, 132, 1)',
    addNewTask: 'rgba(234, 239, 245, 1)',
  },
  note: {
    textDate: 'rgba(175, 175, 175, 1)',
    textMoreAction: 'rgba(15, 43, 91, 1)',
    bgHover: 'rgba(229, 229, 229, 0.5)',
  },
  filter: {
    placeholder: 'rgba(181, 181, 181, 1)',
    input: {
      background: 'rgba(255, 255, 255, 1)',
      labelColor: 'rgba(15, 43, 91, 1)',
      shrinkLabelColor: 'rgba(0, 0, 0, 0.56)',
      focusedBorderColor: 'rgba(223, 226, 236, 1)',
    },
  },
  athlete: {
    colDividerHeader: 'rgba(255, 255, 255, 0.3)',
    colDividerBody: 'rgba(223, 226, 236, 1)',
    hoverOption: '#F4F4F6',
    bgTableHeader: 'rgba(247, 246, 251, 1)',
    video: {
      title: 'rgba(11, 66, 105, 1)',
    },
  },
  checkbox: {
    // disable: 'rgba(238, 238, 238, 1)',
    disable: 'rgba(130, 145, 171, 1)',
    active: 'rgba(15, 43, 91, 1)',
  },
  common: {
    grey1: 'rgba(175, 175, 175, 1)',
    grey2: 'rgba(229, 229, 229, 0.5)',
    grey3: 'rgba(234, 234, 234, 1)',
    grey4: 'rgba(245, 245, 245, 1)',
    grey5: 'rgba(232, 231, 231, 1)',
    grey6: 'rgba(240, 240, 240, 1)',
    grey5_03: 'rgba(232, 231, 231, 0.3)',
    dark1: 'rgba(50, 49, 47, 1)',
    blue1: 'rgba(11, 66, 105, 1)',
    blue2: 'rgba(130, 145, 171, 1)',
    divider1: 'rgba(223, 226, 236, 1)',
    bgShadow: 'rgba(30, 32, 37, 0.15)',
    hoverOnWhite: '#F4F4F6',
    hoverOnPink: '#E9E9F1',
    activeState: '#E1E8F3',
  },
};

export default createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    secondary: {
      main: Colors.default[ColorTypes.secondary],
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    primary: {
      main: Colors.default[ColorTypes.primary],
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    background: {
      default: Colors.default[ColorTypes.default],
      paper: Colors.default[ColorTypes.paper],
    },
    divider: 'rgba(223, 226, 236, 1)',
  },
  typography: {
    fontFamily: Fonts.Roboto,
    button: {
      textTransform: 'none',
    },
    cutText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    lineClamp: {
      display: '-webkit-box',
      lineClamp: 2,
      boxOrient: 'vertical',
      overflow: 'hidden',
    },
    baseText: {
      fontFamily: Fonts.Roboto,
      fontSize: FontSizes.medium,
      fontWeight: 400,
      color: Colors.text.base,
    },
    emptyText: {
      fontFamily: Fonts.Roboto,
      fontSize: FontSizes.medium,
      fontWeight: 400,
      color: 'rgba(175, 175, 175, 1)',
    },
    actionLink: {
      fontFamily: Fonts.Roboto,
      fontSize: FontSizes.preMedium,
      fontWeight: 500,
      color: Colors.common.blue1,
      cursor: 'pointer',
    },
    actionBtn: {
      height: 24,
      fontFamily: Fonts.Roboto,
      fontSize: FontSizes.preMedium,
      fontWeight: 500,
      color: Colors.default.secondary,
      '&:hover': {
        backgroundColor: Colors.common.hoverOnPink,
      },
    },
    baseBorder: {
      border: Colors.common.grey3,
      borderRadius: 5,
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)',
    },
    disable: {
      opacity: 0.4,
      pointerEvents: 'none',
    },
  },
  zIndex: {
    snackbar: 1700,
  },
  overrides: {
    MuiListItem: {
      button: {
        '&$selected': {
          backgroundColor: `${Colors.common.activeState} !important`,
        },
        '&:hover': {
          backgroundColor: Colors.common.hoverOnWhite,
        },
      },
    },
    MuiSvgIcon: {
      root: {
        color: 'rgba(27, 68, 132, 1)',
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: 'none',
        padding: '0 10px',
        '& + &': {
          marginTop: 6,
        },
        '&.Mui-expanded': {
          margin: '6px 0',
        },
        '&:before': {
          backgroundColor: '#fff'
        },
      }
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: '#ECECEF',
        borderRadius: 5,
        '&.Mui-expanded': {
          maxHeight: 48,
          minHeight: 48,
        }
      }, 
      content:{
        '&.Mui-expanded': {
          margin: 0,
        }
      }
    },
    MuiTab: {
      textColorPrimary: {
        color: Colors.default[ColorTypes.secondary],
        '&$selected': {
          backgroundColor: `${Colors.common.activeState} !important`,
          color: Colors.default[ColorTypes.secondary],
        },
        '&:hover': {
          backgroundColor: Colors.common.hoverOnWhite,
        },
      },
      root: {
        backgroundColor: Colors.common.grey4,
        textTransform: 'capitalize',
      },
      textColorInherit: {
        color: 'rgba(11, 66, 105, 1)',
        backgroundColor: '#F7F6FB',
        border: '1px solid #F0F0F0',
        margin: 5,
        borderRadius: 5,
        opacity: 1,
        fontWeight: 'normal',
        '&$selected': {
          backgroundColor: Colors.common.activeState,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        fontSize: FontSizes.medium,
      },
      sizeSmall: {
        fontSize: FontSizes.preMedium,
      },
      sizeLarge: {
        fontSize: FontSizes.extraMedium,
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 15px 13px rgba(0, 0, 0, 0.25)',
      },
      rounded: {
        borderRadius: 5,
      }
    },
    MuiMenu:{
      paper:{
        boxShadow: '4px 8px 20px rgba(0, 0, 0, 0.12)',
      }
    }
  },
});
