import { TextField, styled } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import theme, { Colors, FontSizes } from '../../theme';

export type BaseInputProps = {
  id?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
};

const BaseInput = styled(TextField)((props: {
  theme: Theme,
  minwidth?: string | number,
  maxwidth?: string | number,
  margininput?: string | number,
  height?: string | number,
}) => ({
  margin: props.margininput || 0,
  '& .MuiOutlinedInput-root': {
    backgroundColor: Colors.filter.input.background,
    minWidth: props.minwidth || 123,
    maxWidth: props.maxwidth || "",
    height: props.height || 40,
    fontSize: FontSizes.medium,
    borderRadius: 5,
    boxShadow: '4px 8px 20px rgba(154, 170, 207, 0.12)',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: Colors.filter.input.background,
    },
    [theme.breakpoints.down('sm')]: {
      height: props.height || 56,
      maxWidth: props.maxwidth || '100%',
    },
  },
  '& .MuiInputLabel-outlined': {
    ...theme.typography.baseText,
    color: Colors.filter.input.labelColor,
    lineHeight: 0,
    [theme.breakpoints.down('sm')]: {
      lineHeight: 'initial',
    },
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, 0px) scale(0.75)',
    color: Colors.filter.input.shrinkLabelColor,
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  '& .MuiOutlinedInput-input': {
    ...theme.typography.baseText,
    padding: 14,
    borderRadius: 4,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.filter.input.background,
  },
  '& .Mui-focused': {
    color: Colors.filter.input.labelColor,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.filter.input.focusedBorderColor,
    },
  },
}),
);

BaseInput.defaultProps = { variant: 'outlined', autoComplete: 'off' };

export default BaseInput;
