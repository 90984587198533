import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';

import { Colors } from '../../../theme';

export default makeStyles((theme: Theme) => ({
  root: (props: any) => ({
    ...theme.typography.baseBorder,
    backgroundColor: props.bgColor || theme.palette.background.paper,
    width: props.width || '100%',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      borderBottom: '1px solid #DFE2EC',
      borderRadius: 0,
    },
  }),
  tabsContainer:{
    borderBottom: '1px solid #DFE2EC', 
    borderTop: '1px solid #DFE2EC',
    [theme.breakpoints.down('sm')]: {
      borderTop: '0px solid #DFE2EC',
    },
  },
  tabAthlete: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  notes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
  },
  tableBlock: {
    marginLeft: 17,
    marginRight: 17,
  },
  indicator: {
    // backgroundColor: 'rgba(246, 139, 22, 1)',
    backgroundColor: Colors.common.activeState,
    transitionDuration: '0ms'
  },
  rootTab: {
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      minWidth: 72,
    },
  },
  selectedTab:{
    // backgroundColor: '#fff !important',
  }
}));
