import { makeStyles, Theme } from '@material-ui/core/styles';
// import { athleteColumns } from '../athleteListSettings';
import { Colors } from '../../../theme';

export default makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  ranksContainer: {
    // overflowX: 'auto',
    // display: 'flex',
    // flexGrow: 1,
    // [theme.breakpoints.down('md')]: {
    //   width: '100%',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   flexDirection: 'column',
    //   overflowX: 'auto',
    //   alignItems: 'flex-start',
    // },
  },
  coachRankWrap: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 25,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 14,
    },
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: 300,
    minHeight: 53,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      flexGrow: 0,
      flexBasis: 'auto',
    },
  },
  ranks: {
    display: 'flex',
    width: 28,
    height: 32,
    background: '#EAEFF5',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  colorRankValue: (props: any) => ({
    fontSize: '0.875rem',
    color: Colors.default.secondary,
    cursor: props.disabledInput && 'default'
  }),
  colorRankPlaceholder: (props: any) => ({
    color: 'rgb(151, 178, 210)',
    cursor: props.disabledInput && 'default'
  }),
  initials: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '19px',
    cursor: 'default',
  },
  groupMenu: {
    margin: '0 16px',
    color: '#323047',

    '& svg': {
      color: '#323047',
    },

    '& span': {
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 2px',
    },
  }
}));
