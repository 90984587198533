import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Search from './pages/Search';
import { KANBAN_PAGE } from '../constants';
import { SearchAthleteStateProvider } from '../contexts/SearchAthletesContext';


const TagsPage = React.lazy(() => import("./pages/Tags"));
const Ranks = React.lazy(() => import("./pages/Ranks"));
const Settings = React.lazy(() => import("./pages/Settings"));
const AthleteProfile = React.lazy(() => import("./pages/AthleteProfile"));
const Kanban = React.lazy(() => import("./pages/Kanban"));
const SearchClubs = React.lazy(() => import("./pages/SearchClubs"));
const SearchTeams = React.lazy(() => import("./pages/SearchTeams"));
const LogoutForAdmin = React.lazy(() => import("./pages/LogoutForAdmin"));


export default function Routs() {
  return (
    <Switch>
      <Redirect exact path="/" to="/search" />

      <Route exact path="/search">
        <SearchAthleteStateProvider>
          <Search />
        </SearchAthleteStateProvider>
      </Route>

      <Route exact path="/search-clubs"
        children={
          <React.Suspense fallback={<>...</>}>
            <SearchClubs />
          </React.Suspense>
        }
      />

      <Route exact path="/search-teams"
        children={
          <React.Suspense fallback={<>...</>}>
            <SearchTeams />
          </React.Suspense>
        }
      />

      <Route exact path="/tags"
        children={
          <React.Suspense fallback={<>...</>}>
            <TagsPage />
          </React.Suspense>
        }

      />
      {/* <Route exact path="/ranks"
        children={
          <React.Suspense fallback={<>...</>}>
            <Ranks />
          </React.Suspense>
        }
      /> */}
      <Route exact path={KANBAN_PAGE}
        children={
          <React.Suspense fallback={<>...</>}>
            <Kanban />
          </React.Suspense>
        }
      />
      <Route exact path="/settings"
        children={
          <React.Suspense fallback={<>...</>}>
            <Settings />
          </React.Suspense>
        }
      />

      <Route exact path="/ulink/athlete/:athleteToken" render={(match) => <Redirect to={`/athlete/${match.match.params.athleteToken}${match.location.search}`} />} />

      <Route exact path="/athlete/:athleteToken"
        children={
          <React.Suspense fallback={<>...</>}>
            <SearchAthleteStateProvider>
              <AthleteProfile />
            </SearchAthleteStateProvider>
          </React.Suspense>
        }
      />
      <Route exact path="/admin-login/:username/:password"
        children={
          <React.Suspense fallback={<>...</>}>
            <LogoutForAdmin />
          </React.Suspense>
        }
      />
    </Switch>
  );
}
