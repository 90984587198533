import { Auth, API } from 'aws-amplify';

import ApiResponse from './ApiResponse';
import { DEFAULT_ERROR_MESSAGE } from '../constants';

import config from './config'

export interface UserCredentialsDto {
  email: string;
  password: string;
}
export interface UaUserCredentialsDto {
  username: string;
  password: string;
  isAdmin?: boolean;
}

export interface ChangePasswordDto {
  newPassword: string;
  oldPassword: string;
  confirmPassword: string;
}

export interface User {
  cognitoId: string;
  coachId: string;
  email: string;
  cognitoIdToken: string;
}
type CognitoError = {
  username: string,
  message: string,
  code: number
}

export async function signIn(
  userCredentialsDto: UserCredentialsDto
): Promise<ApiResponse<User>> {
  try {
    const cognitoUser = await Auth.signIn(
      userCredentialsDto.email,
      userCredentialsDto.password
    );

    const user: User = {
      cognitoId: cognitoUser.attributes.sub,
      coachId: cognitoUser.attributes['custom:coach_id'],
      email: cognitoUser.attributes.email,
      cognitoIdToken: cognitoUser.signInUserSession.accessToken.jwtToken,
    };

    if (user.cognitoIdToken) {
      await API.post('legacyAPI', '/ncsa/after-login', {
        body: { token: user.cognitoIdToken },
      });
    }

    return {
      success: true,
      status: 200,
      data: user,
    };
  } catch (error) {
    await cognitoError({ username: userCredentialsDto.email, message: `${error.message} [${error.code}]`, code: 400 })
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}


export async function signInUA(
  userCredentials: UaUserCredentialsDto
): Promise<ApiResponse<any>> {
  try {
    const authUrlUA = userCredentials.isAdmin ? `/ua-auth/admin-login-as-coach` : `/ua-auth/login`;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: userCredentials.username, password: userCredentials.password })
    };

    let response = await fetch(config.urlApiServer + authUrlUA, requestOptions)

    if (!response.ok) {
      let errorRequest = await response.json()
      throw errorRequest;
    }

    let initUserDto = await response.json()

    return {
      success: true,
      status: 200,
      data: initUserDto,
    };
  } catch (error) {
    return {
      success: false,
      status: 401,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      }
    };
  }
}


export async function refreshUaToken(
  refresh_token: string
): Promise<ApiResponse<any>> {
  try {

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ refresh_token })
    };

    let newTokens = await fetch(config.urlApiServer + '/ua-auth/token/refresh', requestOptions)
      .then(response => response.json())

    return {
      success: true,
      status: 200,
      data: newTokens,
    };
  } catch (error) {
    return {
      success: false,
      status: 401,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

export async function cognitoError(
  data: CognitoError
): Promise<ApiResponse<void>> {
  try {
    let url = `{URL}/ncsa/cognito-error`;

    url = url.replace(/{URL}/gi, config.urlApiServer)

    await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    return {
      success: true,
      status: 200,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function changePasswordUa(
  data: ChangePasswordDto
): Promise<ApiResponse<null>> {
  try {

    await API.post('uaUserLegacyAPI', '/ua-auth/change-password', {
      body: {
        old_password: data.oldPassword,
        new_password: data.newPassword,
        confirm_password: data.confirmPassword
      },
    });

    return {
      success: true,
      status: 200,
      data: null,
    };

  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.response?.data?.statusCode,
        message: error.response?.data?.message || DEFAULT_ERROR_MESSAGE,
        name: error.response?.data?.error,
      },
    };
  }
}