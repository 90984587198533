export enum loginFormDic {
  note = 'Authentication',
  buttonCaption = 'Sign in',
}

export enum mainMenuDic {
  search = 'Search',
  searchClubs = 'Search Clubs',
  searchTeams = 'Search Teams',
  tags = 'Manage Tags',
  ranks = 'Manage Ratings',
  kanban = 'Manage Boards',
  profile = 'Profile',
  settings = 'Settings',
  logout = 'Logout',
  toggle = 'Toggle sidebar',
}
