import React, { useEffect, useState } from 'react';
import SortIcon from '@material-ui/icons/Sort';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Divider from '@material-ui/core/Divider';
import BaseMenu from '../../shared/BaseMenu';
import { athleteOrderMenuOptions } from '../athleteListSettings';
import { Colors } from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionBtn: {
      ...theme.typography.actionBtn,
      height: 32,
      lineHeight: 1,
      '&:hover': {
        backgroundColor: Colors.common.hoverOnPink,
      },
      fontWeight: 'normal',
      fontSize: 14,
      color: '#1B4484',
      paddingRight: 16,
      paddingLeft: 16,
    },
    divider: {
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
    },
    labelHover: {
      padding: '0 16px',
      marginRight: 0,
      '& .MuiIconButton-root':{
        transitionDuration: '0ms',
      },
      '& .MuiIconButton-colorSecondary:hover':{
        backgroundColor: Colors.common.hoverOnWhite,
      },
      '&:hover': {
        backgroundColor: Colors.common.hoverOnWhite,
      }
    },
  }),
);

const OrderButton = (props: { sortOrder: 'ASC' | 'DESC', onChange: (value: 'ASC' | 'DESC') => void }) => {
  const [value, setValue] = useState(props.sortOrder);
  const classes = useStyles();
  useEffect(() => {
    setValue(props.sortOrder)
  }, [props.sortOrder])

  return (
    <RadioGroup
      aria-label="sort"
      name="order"
      value={value}
      onChange={event => {
        const newValue = (event.target as HTMLInputElement).value as ('ASC' | 'DESC');
        setValue(newValue);
        props.onChange(newValue);
      }}
    >
      <FormControlLabel classes={{ root: classes.labelHover }} value="ASC" control={<Radio />} label="Ascending" />
      <FormControlLabel classes={{ root: classes.labelHover }} value="DESC" control={<Radio />} label="Descending" />
    </RadioGroup>
  );
};

type MenuOrderProps = {
  onChangeOrder: (params: { sort: 'ASC' | 'DESC', field: string }) => void;
  sortBy: string;
  sortOrder: 'ASC' | 'DESC';
}
const MenuAthleteOrder = (props: MenuOrderProps) => {
  const classes = useStyles();
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>(props.sortOrder);

  useEffect(() => {
    setSortOrder(props.sortOrder);
  }, [props.sortOrder])

  const handleMenuItemClick = (idx: number) => {
    switch (Array.from(athleteOrderMenuOptions.values())[idx]) {
      case 'First name':
        props.onChangeOrder({ sort: sortOrder, field: 'first' });
        break;
      case 'Last name':
        props.onChangeOrder({ sort: sortOrder, field: 'last' });
        break;
      case 'Position':
        props.onChangeOrder({ sort: sortOrder, field: 'position1_abbrev' });
        break;
      case 'State':
        props.onChangeOrder({ sort: sortOrder, field: 'state' });
        break;
      default:
        break;
    }
  };

  const selectedOption = () => {
    if (props.sortOrder === sortOrder) {
      return athleteOrderMenuOptions.get(props.sortBy);
    }
    return ''
  }

  return (
    <BaseMenu
      selectedOption={selectedOption()}
      additionalOptions={[
        { item: <OrderButton onChange={value => setSortOrder(value)} sortOrder={props.sortOrder} /> },
        { item: <Divider classes={{ root: classes.divider }} />, isSeparateElement: true },
      ]}
      options={Array.from(athleteOrderMenuOptions.values())}
      MenuIcon={<SortIcon style={{ transform: 'scale(-1, 1)', }} />}
      Title={'Sort'}
      customClass={classes.actionBtn}
      handleMenuItemClick={handleMenuItemClick} />
  );
};

export default MenuAthleteOrder;
