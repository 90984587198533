class HtmlHelpers {
  static prepareHtmlElementForOpenUrl(url: string): HTMLElement {
    const a = document.createElement('a');

    a.href = url;
    a.target = "_blank";
    a.rel = "noopener";

    return a;
  }
}

export default HtmlHelpers;
