import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Colors, ColorTypes } from '../../theme';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 600,
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
  rootTitle: {
    padding: theme.spacing(3, 0, 0, 3),
  },
  rootContent: {
    padding: theme.spacing(0, 0, 0, 3),
  }
}));

export type AlertDialogProps = {
  title?: string;
  content?: string | JSX.Element;
  isOpen: boolean;
  closeDialog: (confirm: boolean) => void;
}
export default function AlertDialog(props: AlertDialogProps) {
  const classes = useStyles();

  const handleClose = (isConfirm: boolean) => {
    props.closeDialog(isConfirm);
  };

  return (
    <div>
      <Dialog
        classes={{ paper: classes.root }}
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {props.title && <DialogTitle
          id="alert-dialog-title"
          classes={{root: classes.rootTitle}}
        >
          {props.title}
        </DialogTitle>}
        {props.content && <DialogContent classes={{root: classes.rootContent}}>
          <DialogContentText id="alert-dialog-description">
            {props.content}
          </DialogContentText>
        </DialogContent>}
        <DialogActions>
          <Button
            style={{
              backgroundColor: Colors.default[ColorTypes.primary],
              color: Colors.default[ColorTypes.textPrimary],
              height: 32,
              opacity: 0.8,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleClose(true);
            }}
            autoFocus
          >
            Confirm
          </Button>
          <Button 
          style={{height: 32,}}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClose(false)
          }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
