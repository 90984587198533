import {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from 'react';

import { AppState, initialState } from './AppState';
import { StateAction, Reducer } from './StateAction';

export const StateContext = createContext(
  {} as [state: AppState, dispatch: Dispatch<StateAction>]
);

export const StateProvider = ({ children }: { children: ReactNode }) => (
  <StateContext.Provider value={useReducer(Reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useAppStateValue = () => useContext(StateContext);
