import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import { emptyFn, ItemType } from './filter.types';
import { CollegeTagsDto } from '../../../../api/tags';
import AthleteTag from '../../athletes/athlete-tag/AthleteTag';
import { useAppStateValue } from '../../../../state/StateContext';

const useStyles = makeStyles(() => ({
  searchValue: {
    backgroundColor: '#EAEFF5',
    border: '1px solid #DBE3EE',
    height: 24,
    margin: '4px 8px 4px 0',
    paddingLeft: 2,
    paddingRight: 2,
    fontSize: 14,
    color: '#1B4484',
    '&:hover': {
      backgroundColor: '#DBE3EE',
      cursor: 'pointer',
    },
  },
  primaryTag: {
    backgroundColor: '#EAEFF5',
    border: '1px solid #DBE3EE',
    height: 24,
    margin: '4px 8px 4px 0',
    fontSize: 14,
    color: '#1B4484',
    '&:hover': {
      backgroundColor: '#DBE3EE',
      cursor: 'pointer',
    },
  },
  regularTag: {
    margin: '4px 8px 4px 0',
    opacity: 1,
    height: 24,
    '&:hover': {
      // backgroundColor: `${Colors.athlete.hoverOption} !important`,
      opacity: '60%',
      cursor: 'pointer',
    },
  },
}));

export type HideOptionsProps = {
  hideOptions?: ItemType[];
  viewOptions?: ItemType;
  onDeleteHideOption?: (value: ItemType) => void;
}
const HideOptions = (props: HideOptionsProps) => {
  const { hideOptions = [], viewOptions = {}, onDeleteHideOption = emptyFn } = props;
  const classes = useStyles();
  const [{ tasks },] = useAppStateValue();

  return (
    <>
      {hideOptions.map(item => {
        const key = Object.keys(item)[0];
        if (Array.isArray(item[key])) {
          const arr = item[key] || [];
          return arr.map((itemArr: any) => {
            if (itemArr.iconName && itemArr.isPrimary) {
              return <AthleteTag
                key={itemArr.id}
                customClass={classes.primaryTag}
                tag={{
                  ...itemArr,
                  icon: itemArr.iconName,
                  college_tag_id: itemArr.id,
                  title: itemArr.name,
                  primary: true,
                }}
                height={24}
                isCloseIcon={true}
                isPrimaryLabel={true}
                handleClick={tag => onDeleteHideOption({ [key]: { id: (tag as CollegeTagsDto).college_tag_id } })}
              />;
            }

            if (itemArr.iconName && !itemArr.isPrimary) {
              return <AthleteTag
                key={itemArr.id}
                customClass={classes.regularTag}
                tag={{
                  ...itemArr,
                  icon: itemArr.iconName,
                  college_tag_id: itemArr.id,
                  title: itemArr.name,
                  primary: false,
                }}
                height={24}
                isCloseIcon={true}
                handleClick={tag => onDeleteHideOption({ [key]: { id: (tag as CollegeTagsDto).college_tag_id } })}
              />;
            }

            if (itemArr.athlete_rank_id) {
              return <Chip
                key={itemArr.athlete_rank_id }
                classes={{ root: classes.searchValue }}
                size="small"
                label={(viewOptions[itemArr?.rank] || itemArr?.rank)}
                onDelete={() => onDeleteHideOption({ [key]: {athlete_rank_id: itemArr?.athlete_rank_id} })}
                deleteIcon={<CloseIcon />}
              />;
            }

            if (itemArr.college_task_id) {
              return <Chip
                key={itemArr.college_task_id }
                classes={{ root: classes.searchValue }}
                size="small"
                label={`${tasks.find(t => itemArr.college_task_id === t.college_task_id)?.task}:${itemArr.options.join(', ')}`}
                onDelete={() => onDeleteHideOption({ [key]: {college_task_id: itemArr?.college_task_id} })}
                deleteIcon={<CloseIcon />}
              />;
            }

            return <Chip
              key={itemArr?.id || itemArr}
              classes={{ root: classes.searchValue }}
              size="small"
              label={(viewOptions[itemArr?.name] || itemArr?.name) || (viewOptions[itemArr] || itemArr)}
              onDelete={() => onDeleteHideOption({ [key]: itemArr?.id ? { id: itemArr.id } : { val: itemArr } })}
              deleteIcon={<CloseIcon />}
            />;
          });
        }

        return (
          Boolean(item[key]) && <Chip
            key={item[key]?.id || key}
            classes={{ root: classes.searchValue }}
            size="small"
            label={(viewOptions[item[key]?.name] || item[key]?.name)
              || (typeof item[key] === 'boolean' ? (viewOptions[key] || key) : (viewOptions[item[key]] || item[key]))}
            onDelete={() => {
              onDeleteHideOption({ [key]: typeof item[key] === 'boolean' ? false : '' });
            }}
            deleteIcon={<CloseIcon />}
          />
        );
      },
      )}
    </>
  );
};

export default HideOptions;
