import React from 'react';
import { IconProps } from '../icons/index';

export const EvaluateIcon = (props: IconProps) => {
  return (
      <svg fill={props.color || '#e8e6e6'} width={props.width || '83px'} height={props.height || '98px'} preserveAspectRatio="xMidYMid meet" data-bbox="0.064 21.9 199.636 155.7" xmlns="http://www.w3.org/2000/svg" viewBox="0.064 21.9 199.636 155.7" role="presentation" aria-hidden="true">
        <g>
          <path d="M6.4 170.2c.5.3 1.1.5 1.7.5.5 0 .9-.1 1.4-.3l39.3-17.2c.7-.3 1.2-.8 1.6-1.4l54.2-95.1c3.2-5.6 1.2-12.8-4.3-16L70.7 23.5c-2.7-1.6-5.9-2-9-1.2-3 .8-5.6 2.8-7.1 5.5l-54.1 95c-.4.6-.5 1.3-.4 2.1l4.7 42.7c.1 1.1.7 2 1.6 2.6zm14.4-38l50-87.6 11.3 6.5L32 138.8l-11.2-6.6zm-1.9-1.1l-11.4-6.7 49.9-87.6L69 43.5l-50.1 87.6zm24.4 17l-19.9 8.7-14-8.1-2.4-22 36.3 21.4zm2.1-1.4L34 140l50.1-87.7 11.3 6.6-50 87.8zM63.5 28.9c1.3-.3 2.6-.2 3.8.5l29.5 17.1c2.4 1.4 3.2 4.4 1.8 6.8L96.5 57 58.4 35l2-3.6c.7-1.3 1.8-2.2 3.1-2.5z"></path>
          <path d="M199.7 175.6v2H5.7v-2h194z"></path>
        </g>
      </svg>
      );
};
