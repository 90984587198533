import React from 'react';
import { Box, Button, ClickAwayListener, TextareaAutosize } from '@material-ui/core';
import useNoteStyle from '../useNoteStyle';
import {
  // editNoteMenuOptions,
  // iconEditNoteAdditionalOptions,
  // iconEditNoteOptions,
  // noteDialogOptions,
  TypeDialogNote,
} from '../types';
// import BaseMenu, { AdditionalOptionType } from '../../../shared/BaseMenu';
// import AlertDialog from '../../../shared/AlertDialog';
import { CollegeNoteDto } from '../../../../../api/notes';

export type EditNoteProps = {
  note?: CollegeNoteDto;
  saveNote: (updateNote: CollegeNoteDto, note?: CollegeNoteDto) => void;
  removeNote: (note: CollegeNoteDto) => void;
  closeNote: () => void;
}
const EditNote = (props: EditNoteProps) => {
  const { note, saveNote, closeNote } = props;

  const [value, setValue] = React.useState(note?.note || '');
  // const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const typeDialogNoteRef = React.useRef<TypeDialogNote | null>(null);

  const classes = useNoteStyle();

  // const options = React.useMemo(() => editNoteMenuOptions.map(i => {
  //   return (note?.pinned && i === 'Pin') ? 'Unpin' : i;
  // }), [note?.pinned]);

  // const handleDeleteOption = () => {
  //   typeDialogNoteRef.current = TypeDialogNote.DELETE;
  //   setIsOpenDialog(true);
  // };
  // const additionalOptions: AdditionalOptionType[] = note ? [
  //   { item: <Divider style={{ margin: 5 }} />, isSeparateElement: true },
  //   { item: 'Delete', onClick: handleDeleteOption },
  // ] : [];

  const handleSaveNote = () => {
    if (value.trim() && value.trim() !== note?.note) {
      saveNote({ ...note, note: value.trim() } as CollegeNoteDto, note);
    }
    closeNote();
  };

  const handleCancelNote = () => {
    if (value.trim() && value.trim() !== note?.note) {
      typeDialogNoteRef.current = TypeDialogNote.CLOSE;
      // setIsOpenDialog(true);
    } else {
      closeNote();
    }
  };

  // const handleMenuItemClick = async (idx: number) => {
  //   switch (editNoteMenuOptions[idx]) {
  //     case 'Save':
  //       if ((value.trim() && value.trim() !== note?.note)) {
  //         saveNote({ ...note, note: value.trim() } as CollegeNoteDto, note);
  //       }
  //       closeNote();
  //       break;
  //     case 'Pin':
  //     case 'Unpin':
  //       if (note) {
  //         saveNote({ ...note, pinned: !note.pinned }, note);
  //       }
  //       closeNote();
  //       break;
  //     case 'Cancel':
  //       handleCancelNote();
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      <ClickAwayListener onClickAway={handleCancelNote}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <div className={classes.editNotes}>
            <TextareaAutosize
              className={classes.textarea}
              aria-label="note-textarea"
              minRows={3}
              maxRows={5}
              value={value}
              onChange={e => setValue(e.target.value)}
            />

            <Box mb={1}>
              <Button
                disabled={!(value.trim() && value.trim() !== note?.note)}
                variant="contained"
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  handleSaveNote();
                }}
                className={classes.saveNoteBtn}
              >
                Save
              </Button>
              <Button
                className={classes.canselNoteBtn}
                color="secondary"
                onClick={e => {
                  e.stopPropagation();
                  handleCancelNote();
                }}>
                Cancel
              </Button>
            </Box>
          </div>

          {/* <Box ml={2} mr={'2px'}>
            <BaseMenu
              options={options}
              iconOptions={iconEditNoteOptions}
              positionAdditional={'down'}
              customStyle={{ backgroundColor: 'transparent', paddingTop: 2 }}
              additionalOptions={additionalOptions}
              iconAdditionalOptions={iconEditNoteAdditionalOptions}
              handleMenuItemClick={handleMenuItemClick}
            />
          </Box> */}
        </Box>
      </ClickAwayListener>

      {/* <AlertDialog
        isOpen={isOpenDialog}
        title={(typeDialogNoteRef.current && noteDialogOptions[typeDialogNoteRef.current].title) || ''}
        content={(typeDialogNoteRef.current && noteDialogOptions[typeDialogNoteRef.current].content) || ''}
        closeDialog={(isConfirm) => {
          if (!isConfirm) {
            setIsOpenDialog(false);
            return;
          }

          switch (typeDialogNoteRef.current) {
            case TypeDialogNote.DELETE:
              if (note) removeNote(note);
              setIsOpenDialog(false);
              break;
            case TypeDialogNote.CLOSE:
              setIsOpenDialog(false);
              closeNote();
              break;
            default:
              break;
          }
        }}
      /> */}
    </>
  );
};

export default EditNote;
