import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSnackbar } from 'notistack';
import SelectedTags from './fragments/SelectedTags';
import ModalSelectTags from './fragments/ModalSelectTags';
import useAthleteTagsStyle from './useAthleteTagsStyle';
import AthleteTag from '../athlete-tag/AthleteTag';
import { CollegeTagsDto, createAthleteTag, deleteAthleteTag } from '../../../../api/tags';
import { PersonalInfoType } from '../Athlete.types';
import { AthleteTagsType } from '../../../../api/athletes';
import AthleteHelpers from '../../../../helpers/AthleteHelpers';
import BaseButton from '../../shared/BaseButton';
import { useAppStateValue } from '../../../../state/StateContext';

export type AthleteTagsProps = {
  collegeTags: CollegeTagsDto[];
  athleteId: number;
  users_id: number | string;
  personalInfo: PersonalInfoType | null;
  tags: AthleteTagsType[];
  updateAthleteTags: (tags: AthleteTagsType[]) => void;
}
const AthleteTags = (props: AthleteTagsProps) => {
  const { athleteId, users_id, tags, collegeTags, personalInfo, updateAthleteTags } = props;
  const [{ activeSport, account, }] = useAppStateValue();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [positionSelectTags, setPositionSelectTags] = useState(0);

  const editRef = useRef<HTMLDivElement | null>(null);

  const classes = useAthleteTagsStyle();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const selectTags = useMemo(() => collegeTags && collegeTags
    .filter(t => !tags.find(i => i.college_tag_id === t.college_tag_id)), [collegeTags, tags]);

  const selectedTagsMap = useMemo(() => tags.reduce((acc, tag) => ({ ...acc, [tag.college_tag_id]: true }), {} as Record<string, boolean>), [tags]);

  useLayoutEffect(() => {
    const checkPositionSelectTags = () => {
      if (editRef.current && editRef.current?.offsetHeight !== positionSelectTags) {
        setPositionSelectTags((editRef.current?.offsetHeight || 0));
      }
    };

    checkPositionSelectTags();
  });

  const addNewTag = async (tag: CollegeTagsDto) => {

    const optionsForAddingTags = account && account.sports.length > 1 ? {
      athlete_master_id: athleteId,
      tournament_id: 0,
      college_program_id: activeSport && activeSport.college_program_id
    } :
      {
        athlete_master_id: athleteId,
        tournament_id: 0,
      }

    const res = await createAthleteTag(

      tag.college_tag_id,
      optionsForAddingTags
    );
    if (res.success) {
      const athlete_tag_id = res.data?.athlete_tag_id || 0;
      // Create tag in state of athlete
      const athleteTag: AthleteTagsType = {
        title: tag.title,
        icon: tag.icon || '',
        color: tag.color || '',
        primary: tag.primary,
        tag_type: tag.tag_type,
        sort_order: tag.sort_order,
        college_tag_id: tag.college_tag_id,
        athlete_tag_id,
        athlete_master_id: athleteId,
        tournament_id: 0,
        users_id,
      };

      const newTags = [
        ...tags,
        athleteTag,
      ].sort(AthleteHelpers.reorderTags);

      updateAthleteTags(newTags);
    } else {
      enqueueSnackbar(res.error?.message, { variant: 'error' });
    }
  };

  const deleteTag = async (tag: AthleteTagsType) => {
    try {
      await deleteAthleteTag(tag.athlete_tag_id);

      // Delete tag in state of athlete
      updateAthleteTags(tags.filter(t => t.college_tag_id !== tag.college_tag_id));
    } catch (e) {
      enqueueSnackbar(e.message || 'Error removed tag!', { variant: 'error' });
    }
  };

  const handleDeleteTag = async (tag: AthleteTagsType) => {
    await deleteTag(tag);
  };

  return (
    <div className={classes.container}>
      <div className={classes.tags} ref={editRef}>

        <div className={classes.blockTags} style={{ pointerEvents: isLoading ? 'none' : 'inherit' }}>
          {collegeTags.filter(t => t.primary)
            .map(item => {
              return <AthleteTag
                key={item.college_tag_id}
                tag={item}
                isDisable={selectedTagsMap[item.college_tag_id] !== true}
                handleClick={async (tag) => {
                  setIsLoading(true);
                  selectedTagsMap[item.college_tag_id]
                    ? await handleDeleteTag(
                      tags.find(i => i.college_tag_id === item.college_tag_id)!
                    )
                    : await addNewTag(tag as CollegeTagsDto);
                  setIsLoading(false);
                }}
              />;
            })}
          {collegeTags.filter(r => !r.primary && selectedTagsMap[r.college_tag_id])
            .map((tag) => (
              <AthleteTag
                key={tag.college_tag_id}
                tag={tag}
                handleClick={async (tag) => {
                  setIsLoading(true);
                  await handleDeleteTag(
                    tags.find(i => i.college_tag_id === tag.college_tag_id)!
                  );
                  setIsLoading(false);
                }}
              />))
          }
          <div className={classes.newTag}>
            <BaseButton
              title={'+ Assign tags'}
              customClass={classes.actionBtn}
              onClick={() => setIsAssigned(true)}
            />
          </div>
        </div>
        {!matches && isAssigned && <Fade in={isAssigned}>
          <SelectedTags
            className={classes.selectTags}
            style={{ top: positionSelectTags, width: '100%' }}
            selectedTagsMap={selectedTagsMap}
            items={collegeTags}
            selectItem={addNewTag}
            closeWindow={async () => {
              setIsAssigned(false);
            }}
          /></Fade>}

        {matches && <ModalSelectTags
          open={isAssigned}
          athleteName={`${personalInfo?.first} ${personalInfo?.last}`}
          regularTags={tags.filter(r => !r.primary)}
          tags={selectTags || []}
          addItem={addNewTag}
          deleteItem={deleteTag}
          closeWindow={() => {
            setIsAssigned(false);
          }}
        />}
      </div>
    </div>
  );
};

export default AthleteTags;
