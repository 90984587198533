import { API } from 'aws-amplify';

import ApiResponse from './ApiResponse';
import { DEFAULT_ERROR_MESSAGE } from '../constants';

export type CollegeNoteDto = {
  college_note_id: number;
  users_id: number;
  college_program_id: number;
  athlete_master_id: number;
  tournament_id: number;
  tournament_name: string;
  note: string;
  initials: string;
  initials_color: string;
  date_modified?: string;
  date_created: string | null;
  export_pda: number;
  category?: number;
};

export type CreateCollegeNoteDto = {
  tournament_id?: number;
  athlete_master_id: number;
  note: string;
  college_program_id?: number;
  category?: number;
};

export type UpdateCollegeNoteDto = {
  tournament_id?: number;
  note: string;
  category?: number;
};

export type TeamNoteDto = {
  team_note_id: number;
  team_master_id: number;
  tournament_id: number;
  college_program_id: number;
  users_id: number;
  note: string;
  date_created: string;
  date_modified: string;
  uuid: string;
};

export type CreateTeamNoteDto = {
  note: string;
  team_master_id: number;
  college_program_id: number;
  users_id: number;
  tournament_id?: number;
  uuid?: string;
};

export async function createCollegeNote(
  createCollegeNoteDto: CreateCollegeNoteDto
): Promise<ApiResponse<CollegeNoteDto>> {
  try {
    const collegeNoteDto: CollegeNoteDto = await API.post(
      'legacyAPI',
      '/college-notes',
      {
        body: { ...createCollegeNoteDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: collegeNoteDto,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function updateCollegeNote(
  id: number,
  createCollegeNoteDto: CreateCollegeNoteDto
): Promise<ApiResponse<CollegeNoteDto>> {
  try {
    const data: CollegeNoteDto = await API.put(
      'legacyAPI',
      `/college-notes/${id}`,
      { body: { ...createCollegeNoteDto } }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}


export async function updateNotePDA(
  college_note_id: number,
  pda: number,
): Promise<ApiResponse<CollegeNoteDto>> {
  try {
    const data: CollegeNoteDto = await API.put(
      'legacyAPI',
      `/college-notes/${college_note_id}/update-pda/${pda}`,
      { body: {} }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function deleteCollegeNote(id: number): Promise<void> {
  await API.del('legacyAPI', `/college-notes/${id}`, {});
}


export async function getTeamNotes(
  college_program_id: number | undefined,
  team_master_id: number,
): Promise<ApiResponse<TeamNoteDto[]>> {
  try {
    const data: TeamNoteDto[] = await API.get(
      'legacyAPI',
      `/team-notes?college_program_id=${college_program_id}&team_master_id=${team_master_id}`,
      {}
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function createTeamNote(
  createTeamNoteDto: CreateTeamNoteDto
): Promise<ApiResponse<TeamNoteDto>> {
  try {
    const teamNoteDto: TeamNoteDto = await API.post(
      'legacyAPI',
      '/team-notes',
      {
        body: { ...createTeamNoteDto },
      }
    );

    return {
      success: true,
      status: 200,
      data: teamNoteDto,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function updateTeamNote(
  teamNoteID: number,
  updateTeamNoteDto: CreateTeamNoteDto
): Promise<ApiResponse<TeamNoteDto>> {
  try {
    const data: TeamNoteDto = await API.put(
      'legacyAPI',
      `/team-notes/${teamNoteID}`,
      { body: { ...updateTeamNoteDto } }
    );

    return {
      success: true,
      status: 200,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      status: 400,
      error: {
        code: error.code,
        message: error.message || DEFAULT_ERROR_MESSAGE,
        name: error.name,
      },
    };
  }
}

export async function deleteTeamNote(teamNoteID: number): Promise<void> {
  await API.del('legacyAPI', `/team-notes/${teamNoteID}`, {});
}