import { useEffect, useState } from 'react';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import useSummaryStyle from './useSummaryStyle';
import Summary from './fragments/Summary';
import EditSummary from './fragments/EditSummary';
import BaseButton from '../../shared/BaseButton';
import { CollegeNoteDto, createCollegeNote, deleteCollegeNote, updateCollegeNote } from '../../../../api/notes';
import { useAppStateValue } from '../../../../state/StateContext';
import { useSearchAthleteStateValue } from '../../../../contexts/SearchAthletesContext';

export type AthleteSummaryProps = {
  athleteId: number;
  notes: CollegeNoteDto[];
}

const AthleteSummary = (props: AthleteSummaryProps) => {
  const [athleteSummary, setAthleteSummary] = useState<CollegeNoteDto | undefined>(props.notes.filter(note => note.category === 13)[0]);
  const [{ account, activeSport, }] = useAppStateValue();
  const [{ athletes }, dispatchSearchAthlete] = useSearchAthleteStateValue();

  const { enqueueSnackbar } = useSnackbar();

  const [isOpenNewSummary, setIsOpenNewSummary] = useState(false);

  const classes = useSummaryStyle();

  useEffect(() => {
    setAthleteSummary(props.notes.filter(note => note.category === 13)[0])
  }, [props.notes])

  const saveSummary = (updateSummary: CollegeNoteDto, summary?: CollegeNoteDto) => {
    const optionsForAddingSummary = account && account.sports.length > 1 ? {
      athlete_master_id: props.athleteId,
      note: updateSummary.note,
      college_program_id: activeSport && activeSport.college_program_id,
      category: 13
    } :
      {
        athlete_master_id: props.athleteId,
        note: updateSummary.note,
        category: 13
      }
    if (summary?.college_note_id) {
      updateCollegeNote(

        summary.college_note_id,
        optionsForAddingSummary,
      ).then(res => {
        if (!res.success) {
          enqueueSnackbar(res.error?.message, { variant: 'error' });
          return;
        }
        enqueueSnackbar('Summary updated successfully', { variant: 'success' });
        // Update note in state of athlete

        setAthleteSummary(res.data as CollegeNoteDto)
        dispatchSearchAthlete({
          type: 'updateState', value: {
            athletes:
              athletes.map(ath => ath.athlete_master_id === res.data.athlete_master_id ? { ...ath, notes: ath.notes.map(n => n.college_note_id === res.data.college_note_id ? res.data : n) } : ath)
          }
        });
      });
    } else {
      createCollegeNote(optionsForAddingSummary)
        .then(res => {
          if (!res.success) {
            enqueueSnackbar(res.error?.message, { variant: 'error' });
          } else {
            enqueueSnackbar('Summary created successfully', { variant: 'success' });

            // Create note in state of athlete
            setAthleteSummary(res.data as CollegeNoteDto)
            dispatchSearchAthlete({
              type: 'updateState', value: {
                athletes:
                  athletes.map(ath => ath.athlete_master_id === res.data.athlete_master_id ? { ...ath, notes: [...ath.notes, res.data] } : ath)
              }
            });
          }
        });
      setIsOpenNewSummary(false);
    }
  };

  const deleteSummary = (deleteSummary: CollegeNoteDto) => {
    const athleteSummaryId = deleteSummary.college_note_id || 0;
    try {
      deleteCollegeNote(athleteSummaryId)
        .then(() => {
          enqueueSnackbar('Summary removed successfully', { variant: 'success' });
          // Delete note in state of athlete

          //!!!
          setAthleteSummary(undefined)
          dispatchSearchAthlete({
            type: 'updateState', value: {
              athletes:
                athletes.map(ath => ath.athlete_master_id === props.athleteId ? { ...ath, notes: ath.notes.filter(n => n.college_note_id !== athleteSummaryId) } : ath)
            }
          });
        });
    } catch (e) {
      enqueueSnackbar(e.message || 'Error removed summary!', { variant: 'error' });
    }
  };

  return (
    <div className={classes.container}>

      {athleteSummary && !isOpenNewSummary &&
        <div className={classes.notes}>
          <Summary
            summary={athleteSummary}
            saveSummary={saveSummary}
            removeSummary={deleteSummary}
          />
        </div>
      }

      {!athleteSummary && !isOpenNewSummary && <div style={{ width: '100%' }}>
        <BaseButton
          title={'+ Add Summary'}
          customClass={classes.actionBtn}
          onClick={() => setIsOpenNewSummary(true)}
        />
      </div>
      }

      {isOpenNewSummary &&
        <Fade in={isOpenNewSummary}>
          <div className={classes.note}>
            <EditSummary
              saveSummary={saveSummary}
              removeSummary={deleteSummary}
              closeSummary={() => setIsOpenNewSummary(false)}
            />
          </div>
        </Fade>}
    </div>
  );
};

export default AthleteSummary;
