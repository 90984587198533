import React, { FormEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Slide, makeStyles, Theme } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { emptyFn, ItemType } from './filter.types';
import { Colors, FontSizes } from '../../../theme';
import { ATHLETE_APP_BAR, TOOLBAR_HEIGHT } from '../../../../constants';
import { useScroll } from '../../../../hooks/useScroll';
import AthleteFilterOptions from '../../athletes/athlete-filter/AthleteFilterOptions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column wrap',
    width: '100%',
    backgroundColor: Colors.athlete.bgTableHeader,
    padding: '0 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  filterOptions: {
    overflow: 'hidden',
    height: 'auto',
  },
  blockSecond: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1.5),
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignSelf: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  actionBlock: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      width: '100%',
      maxWidth: 343,
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: FontSizes.extraMedium,
    height: 36,
    width: 85,
    lineHeight: '14px',
    boxShadow: 'none',
    opacity: 0.8,
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 56,
      fontSize: FontSizes.preLarge,
    },
  },
}));

export type WrapperBlockProps = {
  showOptions?: ReactNode;
  showOptions2?: ReactNode;
  appBarOptions?: ReactNode;
  hideOptions?: ItemType[];
  viewOptions?: ItemType;
  submit: () => void;
  clearFilters: () => void;
  onDeleteHideOption?: (value: ItemType) => void;
}

const WrapperBlock = (props: WrapperBlockProps) => {
  const [openFilters, setOpenFilters] = useState<boolean>(true);

  const {
    clearFilters, showOptions, showOptions2, submit, hideOptions = [], viewOptions = {},
    onDeleteHideOption = emptyFn, appBarOptions,
  } = props;

  const classes = useStyles();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit();
  };

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={onSubmit}>
      <AthleteFilterOptions
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
        clearFilters={clearFilters}
        hideOptions={hideOptions}
        viewOptions={viewOptions}
        onDeleteHideOption={onDeleteHideOption}
      />
      <Box>
        {openFilters && <div className={classes.filterOptions}
        // style={{ maxHeight: openFilters ? 1170 : 0 }}
        >
          <Slide direction="down" in={openFilters}>
            <Box>
              {showOptions}

              <div className={classes.blockSecond} >
                {showOptions2}

                {/* Do not comment, needed to display the filter*/}
                <div
                  className={classes.actionBlock}
                >
                  {/* <Button
                    classes={{ root: classes.btn }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Search
                  </Button> */}
                </div>
              </div>

            </Box>
          </Slide>
        </div>}
        {appBarOptions}
      </Box>
    </form>
  );
};

export default WrapperBlock;
