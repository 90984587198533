import { AppState, initialState } from './AppState';
import { CollegeTagsDto } from '../api/tags';
import { AccountInitialSportsDto } from '../api/account'

type StateActionType =
  'userLogged'
  | 'initState'
  | 'clearState'
  | 'deleteTag'
  | 'createTag'
  | 'updateTag'
  | 'changeActiveTournament'
  | 'changeActiveSport'
  | 'updateListTags'
  | 'updateListTurnaments'
  | 'update';

type TournamentAction = { eventId: number }
type TagDeleteAction = { deletedTagId?: number }
type NewTagAction = { createdTag?: CollegeTagsDto }
type AppStateType = { [Property in keyof AppState]?: AppState[Property] }

export interface StateAction {
  type: StateActionType;
  value?: AppStateType | TournamentAction | TagDeleteAction | NewTagAction | AccountInitialSportsDto;
}

export function Reducer(state: AppState, action: StateAction): AppState {
  switch (action.type) {
    case 'userLogged':
    case 'updateListTurnaments':
    case 'updateListTags':
    case 'update':
      return {
        ...state,
        ...action.value,
      };

    case 'initState':
      return {
        ...state,
        ...action.value,
        isStateInitialized: true,
      };

    case 'clearState':
      return {
        ...initialState
      };

    case 'changeActiveTournament':
      return {
        ...state,
        activeTournament: (action.value as TournamentAction).eventId,
      };

    case 'changeActiveSport':
      return {
        ...state,
        activeSport: (action.value as AccountInitialSportsDto),
      };

    default:
      return state;
  }
}
