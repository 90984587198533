import React, { ChangeEvent } from 'react';
import { Checkbox } from '@material-ui/core';
import { Colors } from '../../theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  checkboxDisable: {
    '&:hover': {
      backgroundColor: Colors.common.hoverOnPink,
    },
    '& .MuiSvgIcon-root': {
      color: Colors.checkbox.disable,
    },
  },
  checkboxActive: {
    '&:hover': {
      backgroundColor: '#E6E6E8 !important',
    },
    '& .MuiSvgIcon-root': {
      color: Colors.checkbox.active,
    },
  },
  customStyle:(props) => ({
    ...props,
  }),
}));


type BaseCheckboxProps = {
  isCheck: boolean;
  customStyle?: any;
  handleCheckbox: (isCheck: boolean) => void;
}
const BaseCheckbox = (props: BaseCheckboxProps) => {
  const { isCheck, customStyle = {}, handleCheckbox } = props;

  const classes = useStyles(customStyle);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleCheckbox(event.target.checked);
  };

  return (
    <Checkbox
      checked={isCheck}
      classes={{ root: isCheck ? `${classes.checkboxActive} ${classes.customStyle}` : `${classes.checkboxDisable} ${classes.customStyle}`, }}
      onChange={handleChange}
      disableRipple={true}
    />
  );
};

export default BaseCheckbox;
