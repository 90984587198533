import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AthleteAttendingType } from '../../../../api/athletes';
import { mapUngroupedSports } from '../mocks/hardcoded-data';
import useTableStyle from '../styles/useTableStyle';
import DateHelpers from '../../../../helpers/DateHelpers';

export type AthleteAttendingProps = {
  athleteAttending?: AthleteAttendingType[],
  activeSport: number,
}
const AthleteAttending = (props: AthleteAttendingProps) => {
  const { athleteAttending = [], activeSport } = props;

  const classes = useTableStyle();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!athleteAttending.length) {
    return (
      <Box display={'flex'} justifyContent={'center'} m={2}>
        <Typography classes={{ root: classes.textHeader }}>
          No information yet
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {matches && <Box className={classes.mobileContainer}>
        {athleteAttending.map((row: AthleteAttendingType, idx) => {
          return (
            <Box key={idx} className={classes.mobileRow}>
              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle}>Date</Typography>
                <Typography className={classes.textValue}>
                  {DateHelpers.prepareFromToDatesString(row.datestart, row.dateend)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle}>Event</Typography>
                <Typography className={classes.textValue}>{row.event_name}</Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle}>Uni#</Typography>
                <Typography className={classes.textValue}>{row.uniform1}</Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle}>Pos</Typography>
                <Typography className={classes.textValue}>
                  {mapUngroupedSports.get(row.position)?.get(activeSport || 0)?.abbrev || 'N/A'}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle}>Team</Typography>
                <Typography className={classes.textValue}>{row.team_name}</Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'} mb={2}>
                <Typography className={classes.textTitle}>Source</Typography>
                <Typography className={classes.textValue}>{row.provider}</Typography>
              </Box>
            </Box>
          );
        })}

      </Box>}

      {!matches && <TableContainer component={Paper} classes={{ root: classes.container }}>
        <Table className={classes.table} aria-label="athlete attending table">
          <TableHead classes={{ root: classes.header }}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">Event</TableCell>
              <TableCell align="left">Uni#</TableCell>
              <TableCell align="left">Pos</TableCell>
              <TableCell align="left">Team</TableCell>
              <TableCell align="left">Source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: classes.body }}>
            {athleteAttending.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell align="left" style={{ width: 160 }}>
                  {DateHelpers.prepareFromToDatesString(row.datestart, row.dateend)}
                </TableCell>
                <TableCell align="left" style={{ width: 320 }}>
                  {row.event_name}
                </TableCell>
                <TableCell align="left" style={{ width: 120 }}>
                  {row.uniform1}
                </TableCell>
                <TableCell align="left" style={{ width: 120 }}>
                  {mapUngroupedSports.get(row.position)?.get(activeSport || 0)?.abbrev || 'N/A'}
                </TableCell>
                <TableCell align="left" style={{ width: 320 }}>
                  {row.team_name}
                </TableCell>
                <TableCell align="left" style={{ width: 120 }}>
                  {row.provider}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  );
};

export default AthleteAttending;
