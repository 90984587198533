import { makeStyles } from '@material-ui/core/styles';
import { Colors, FontSizes } from '../../../theme';

export default makeStyles(theme => ({
  container: {
    boxShadow: 'none',
    maxHeight: 280,
    overflow: 'scroll',
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 280,
    overflow: 'scroll',
  },
  mobileRow: {
    marginTop: theme.spacing(2),
    borderBottom: `1px solid ${Colors.common.divider1}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  table: {
    minWidth: 700,
    marginBottom: 15,
  },
  header: {
    height: 31,
    /*eslint no-useless-computed-key: "off"*/
    ['& th']: {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: FontSizes.medium,
      color: Colors.common.grey1,
      borderBottom: `1px solid ${Colors.common.divider1}`,
      paddingBottom: 12,
      padding: 7,
    },
  },
  body: {
    borderColor: Colors.common.divider1,
    /*eslint no-useless-computed-key: "off"*/
    ['& td']: {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: FontSizes.extraMedium,
      color: Colors.common.dark1,
      paddingTop: 12,
      borderBottom: `1px solid ${Colors.common.divider1}`,
      padding: 7,
    },
  },
  textHeader: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: FontSizes.medium,
    color: Colors.common.grey1,
    padding: 0,
    lineHeight: '24px',
  },
  label: {
    ...theme.typography.baseText,
    fontWeight: 500,
    margin: theme.spacing(1),
    width: 100,
  },
  text: {
    ...theme.typography.baseText,
    margin: theme.spacing(1),
  },
  row: {
    verticalAlign: 'top',
  },
  tags: {
    padding: '4px 12px',
  },
  textTitle: (props: any) => ({
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: FontSizes.medium,
    color: Colors.common.grey1,
    minWidth: props.minWidth || 112,
    padding: '6px 12px',
  }),
  textValue: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: FontSizes.extraMedium,
    color: Colors.common.dark1,
    padding: '6px 12px',
  },
}));
