import { useSnackbar } from 'notistack';
import RankElement from './fragments/RankElement';

import useAthleteRanksStyle from './useAthleteRanksStyle';

import {
  AthleteRankDto,
  UpdateAthleteRankDto,
  createAthleteRank,
  updateAthleteRank,
  deleteAthleteRank,
  getAthleteRanksForFiltersList,
  AthleteRankForFiltersList,
} from '../../../../api/ranks';
import ApiResponse from '../../../../api/ApiResponse';

import { useAppStateValue } from '../../../../state/StateContext';


export type AthleteTagsProps = {
  athleteId?: number;
  users_id?: number;
  ranksList: any;
  disabledInput?: boolean;
  groupId: number;
  setRanksList: React.Dispatch<any>;
}
const AthleteRank = (props: AthleteTagsProps) => {
  const { athleteId, users_id, ranksList, disabledInput, groupId, setRanksList } = props;
  const [{ activeSport, collegeGroups }, dispatch] = useAppStateValue();


  const { enqueueSnackbar } = useSnackbar();
  const classes = useAthleteRanksStyle({});


  const updatingRanksForFilterList = async () => {
    const athleteRanksForFiltersList: ApiResponse<AthleteRankForFiltersList[]> = await getAthleteRanksForFiltersList(activeSport?.college_program_id);

    if (athleteRanksForFiltersList.success) {
      const { data: ranksForFilter } = athleteRanksForFiltersList;

      dispatch({ type: 'update', value: { ranksForFilter } });
    }
  }

  const addNewRank = async (newRank: string, college_rank_id: number) => {
    const optionsForAddingRank: any = {
      users_id: users_id as number,
      value: newRank,
      college_program_id: activeSport?.college_program_id as number,
      college_rank_id,
      athlete_master_id: athleteId as number,
      tournament_id: 0,
    }

    const res = await createAthleteRank(

      optionsForAddingRank
    );

    if (res.success) {
      const { data: newAthRank } = res;
      enqueueSnackbar('Success!', { variant: 'success' });
      updatingRanksForFilterList()

      let copyList = { ...ranksList }

      if (copyList[groupId]) {
        copyList[groupId] = {
          ...ranksList[groupId],
          usersRanks: [...ranksList[groupId].usersRanks,
          {
            ...newAthRank,
            college_rank_group_id: groupId
          }
          ]
        }
      }
      else {
        copyList[groupId] = {
          colleaguesRanks: [],
          usersRanks: [
            {
              ...newAthRank,
              college_rank_group_id: groupId
            }
          ]
        }
      }

      setRanksList(copyList)

      return res.data
    } else {
      enqueueSnackbar(res.error?.message, { variant: 'error' });
    }
  };



  const updateRank = async (newRank: string, athlete_rank_id: number) => {

    const optionsForUpdatingRank: UpdateAthleteRankDto = {
      value: newRank,
      college_program_id: activeSport?.college_program_id as number,
      users_id: users_id as number
    }

    const res = await updateAthleteRank(

      athlete_rank_id,
      optionsForUpdatingRank
    );

    if (res.success) {
      const { data: newAthRank } = res;
      enqueueSnackbar('Success!', { variant: 'success' });
      updatingRanksForFilterList()

      setRanksList({
        ...ranksList,
        [groupId]: {
          ...ranksList[groupId], usersRanks: ranksList[groupId].usersRanks.map((rank: AthleteRankDto) => {
            if (rank.athlete_rank_id === newAthRank?.athlete_rank_id) {
              return {
                ...newAthRank,
                college_rank_group_id: groupId
              }
            }
            else { return rank }
          })

        }
      })
      return res.data
    } else {
      enqueueSnackbar(res.error?.message, { variant: 'error' });
    }
  };



  const deleteRank = async (athlete_rank_id: number) => {
    try {
      await deleteAthleteRank(athlete_rank_id, activeSport?.college_program_id as number);
      updatingRanksForFilterList()

      setRanksList({
        ...ranksList,
        [groupId]: {
          ...ranksList[groupId], usersRanks: ranksList[groupId].usersRanks.filter((rank: AthleteRankDto) => rank.athlete_rank_id !== athlete_rank_id)
        }
      })
    } catch (e) {
      enqueueSnackbar(e.message || 'Error removed tag!', { variant: 'error' });
    }
  };


  return (
    <div className={classes.ranksContainer}>

      <div className={classes.wrap}>
        {collegeGroups && collegeGroups?.find(group => group.college_rank_group_id === groupId)?.ranks.map(item => {
          return (
            <RankElement
              key={item.college_rank_id}
              item={item}
              athleteRanks={(ranksList[groupId] && ranksList[groupId].usersRanks) || []}
              updateRank={updateRank}
              addNewRank={addNewRank}
              deleteRank={deleteRank}
              disabledInput={disabledInput}
            />)
        })
        }
      </div>

      {ranksList[groupId] && ranksList[groupId].colleaguesRanks.map((coachRanks: AthleteRankDto[]) => (
        <div className={classes.coachRankWrap} key={coachRanks[0].users_id} >
          <span style={{ color: coachRanks[0].initials_color ? `#${coachRanks[0].initials_color}` : '#188FFC' }}
            className={classes.initials}
          >
            {coachRanks[0].initials}
          </span>

          <div className={classes.wrap}>
            {collegeGroups && collegeGroups?.find(group => group.college_rank_group_id === groupId)?.ranks.map(item => (
              <RankElement
                key={item.college_rank_id}
                item={item}
                athleteRanks={coachRanks}
                updateRank={updateRank}
                addNewRank={addNewRank}
                deleteRank={deleteRank}
                disabledInput={true}
              />))
            }
          </div>
        </div>
      ))}

    </div>
  );
};

export default AthleteRank;
