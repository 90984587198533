import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import MainMenuItems from './MainMenuItems';

import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { DRAWER_WIDTH } from '../../constants';

type Props = {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  window?: () => Window;
};

const drawerStyle = {
  backgroundColor: '#fff',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      ...drawerStyle,
      width: DRAWER_WIDTH.desktop,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

export default function MobileMainMenu(props: Props) {
  const { mobileOpen, handleDrawerToggle, window } = props;
  const classes = useStyles();
  const theme = useTheme();

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Hidden smUp implementation="css">
      <Drawer
        container={container}
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClick={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <MainMenuItems resize={false} />
      </Drawer>
    </Hidden>
  );
}
