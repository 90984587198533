import { makeStyles } from '@material-ui/core/styles';
import { Colors, Fonts, FontSizes } from '../../../theme';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%', //'calc(100% - 2px)'
    flexWrap: 'wrap',
  },
  notes: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: 280,
    overflowY: 'auto',
  },
  rowNote: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    cursor: 'text',
    boxSizing: 'border-box',
    flexShrink: 0,
    '&:hover': {
      backgroundColor: Colors.common.hoverOnWhite,
      '& $showMenu': {
        display: 'flex',
      },
      // '& $showPinned': {
      //   display: 'none',
      // },
    },
  },
  rowNoteAddBackground: (props: any) => ({
    backgroundColor: props.isActive || props.isOpenEditNote ? Colors.common.grey2 : '#fff',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff'
    },
  }),
  note: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: 13,
    paddingBottom: 5,
    paddingLeft: theme.spacing(3),
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  dateModified: {
    color: Colors.common.grey1,
    marginRight: theme.spacing(2),
    fontFamily: Fonts.Roboto,
    fontSize: FontSizes.preMedium,
    lineHeight: FontSizes.extraLarge,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  noteText: {
    ...theme.typography.lineClamp,
    color: Colors.common.dark1,
    fontFamily: Fonts.Lato,
    fontSize: FontSizes.medium,
    lineHeight: FontSizes.extraLarge,
    fontWeight: 400,
    fontStyle: 'italic',
    paddingLeft: 8,
    wordBreak: 'break-word',
    '& .emoji': { fontStyle: 'normal' }
  },
  actionBtn: {
    ...theme.typography.actionBtn,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3),
    '&:hover': {
      backgroundColor: Colors.common.hoverOnWhite,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  editNotes: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 27,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 11,
    },
  },
  textarea: {
    border: `1px solid ${Colors.common.divider1}`,
    borderRadius: 5,
    '& textarea': {
      border: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    paddingRight: theme.spacing(4),
    paddingTop: 0,
    paddingLeft: 5,
    marginBottom: theme.spacing(1),
    color: Colors.common.dark1,
    fontFamily: Fonts.Lato,
    fontSize: FontSizes.medium,
    lineHeight: '150%',
    fontWeight: 400,
    // fontStyle: 'italic',
  },
  saveNoteBtn: {
    opacity: 0.8,
    marginRight: 11,
    boxShadow: 'none',
    fontSize: '1rem',
    lineHeight: 1.5,
    height: 32,
    paddingLeft: 22,
    paddingRight: 22,
  },
  canselNoteBtn: {
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: 1.5,
    height: 32,
    paddingLeft: 22,
    paddingRight: 22,
  },
  showMenu: {
    display: 'flex',
    '& > button': {
      minWidth: 10,
    }

  },
  showPinned: {
    display: 'flex',
    padding: '9px 0',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.43,
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: Colors.common.hoverOnWhite
    },
  },
  initials: (props: any) => ({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    color: props.initials_color ? `#${props.initials_color}` : '#323047'
  }),
  tournamentName: {
    fontSize: 14,
    margin: '0 12px',
    width: 130,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5rem',
  }
}));
