import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTableStyle from '../styles/useTableStyle';
import { TeamRosterDto } from '../../../../api/teams';

export type TeamRosterProps = {
  teamRoster: TeamRosterDto[];
}

const columnTitles = [
  {
    name: 'Name',
    align: "center"
  },
  {
    name: 'Code',
    align: "center"
  },
  {
    name: 'Age',
    align: "center"
  },
  {
    name: 'Rank',
    align: "center"
  },
  {
    name: 'Players',
    align: "center"
  }]

const TeamRoster = (props: TeamRosterProps) => {
  const { teamRoster = [] } = props;

  const classes = useTableStyle();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  if (!teamRoster.length) {
    return (
      <Box display={'flex'} justifyContent={'center'} m={2}>
        <Typography classes={{ root: classes.textHeader }}>
          No information yet
        </Typography>
      </Box>
    );
  }

  const emptyHelper = (value: any) => value ?? <span style={{ color: 'rgba(175, 175, 175, 1)' }}>empty</span>

  return (
    <>
      {matches && <Box className={classes.mobileContainer}>
        {teamRoster.map((row: TeamRosterDto, idx) => {
          return (
            <Box key={idx} className={classes.mobileRow}>
              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[0].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.team_name)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[1].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.organization_code)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[2].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.age)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[3].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.rank)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[4].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.players)}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>}

      {!matches && <TableContainer component={Paper} classes={{ root: classes.container }}>
        <Table className={classes.table} stickyHeader aria-label="team staff table">
          <TableHead classes={{ root: classes.header }} >
            <TableRow>
              {columnTitles.map((item, idx) => (
                <TableCell key={idx} align={item.align as any} style={{ backgroundColor: '#fff' }}>{item.name}</TableCell>)
              )}
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: classes.body }}>
            {teamRoster.map((row: TeamRosterDto, idx) => (
              <TableRow key={idx} classes={{ root: classes.row }}>

                <TableCell align="center" style={{ minWidth: 120 }} >
                  {row.team_name}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 100 }}>
                  {row.organization_code}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 70 }} >
                  {row.age}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 70 }}>
                  {row.rank}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 70 }}>
                  {row.players}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  );
};

export default TeamRoster;
