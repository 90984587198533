import React from 'react';
import { TagNames } from '../../../assets/icons/collegeTags';

export type TagIconProps = {
  name?: string | null;
  color?: string | null;
  fontSize?: string;
}

const TagIcon = (props: TagIconProps) => {

  //@ts-ignore
  if (!props.name || !TagNames[props.name]) return null;

  return (
    <i className={`ua22 ua22-${props.name}`}
      style={{fontSize: props.fontSize || '16px', color: props.color || '#000'}}
    ></i>
  );
};

export default TagIcon;
