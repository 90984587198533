import React from 'react';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from '@material-ui/core/Tooltip';
import useNoteStyle from '../useNoteStyle';
import { noteDialogOptions, TypeDialogNote } from '../types';
import EditNote from './EditNote';
import { PinIcon } from '../../../../../assets/icons';
import { Colors } from '../../../../theme';
import DateHelpers from '../../../../../helpers/DateHelpers';
import BaseMenu from '../../../shared/BaseMenu';
import AlertDialog from '../../../shared/AlertDialog';
import { CollegeNoteDto } from '../../../../../api/notes';
import { EMOJI_REGEXP } from '../../../../../constants';

const noteMenuOptions: string[] = ['Pin', 'Edit'];

const iconOptions = new Map([
  ['Pin', <PinIcon width={'18px'} height={'18px'} color={Colors.svg.secondary} />],
  ['Unpin', <PinIcon width={'18px'} height={'18px'} />],
  ['Edit', <EditOutlinedIcon style={{ width: 18, height: 18 }} />],
]);
// const iconAdditionalOptions = new Map([
//   ['Delete', <DeleteOutlineIcon style={{ width: 18, height: 18, color: Colors.svg.dangerous }}/>],
// ]);

export type PreviewReactProps = {
  note: CollegeNoteDto;
  saveNote: (updateNote: CollegeNoteDto, note?: CollegeNoteDto) => void;
  removeNote: (note: CollegeNoteDto) => void;
  updateStatusPinned: (college_note_id: number, pda: number) => Promise<void>;
}
const Note = (props: PreviewReactProps) => {
  const { note, saveNote, removeNote, updateStatusPinned } = props;


  const [isOpenEditNote, setIsOpenEditNote] = React.useState(false);
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const classes = useNoteStyle({ isActive, isOpenEditNote, initials_color: note.initials_color });
  const options = React.useMemo(() => noteMenuOptions.map(i => {
    return ((note?.export_pda === 2) && i === 'Pin') ? 'Unpin' : i;
  }), [note?.export_pda]);

  const DeleteButton = () => {
    return (
      <div className={classes.deleteButton} onClick={() => setIsOpenDialog(true)}>
        <DeleteOutlineIcon style={{ width: 18, height: 18, color: Colors.svg.dangerous, marginRight: 4 }} />
        <span>Delete</span>
      </div>
    )
  }

  const handleMenuItemClick = async (idx: number) => {
    switch (noteMenuOptions[idx]) {
      case 'Edit':
        setIsOpenEditNote(true);
        break;
      case 'Pin':
      case 'Unpin': note.export_pda === 2 ?
        updateStatusPinned(note.college_note_id, 0)
        :
        updateStatusPinned(note.college_note_id, 2);
        break;
      default:
        break;
    }
  };

  function wrapEmoji(note: string) {
    const a = note.split(EMOJI_REGEXP)
    const textWithStyledEmoji = a.map((part, index) => EMOJI_REGEXP.test(part) ? <span className="emoji" key={index}>{part}</span> : part)

    return <React.Fragment >{textWithStyledEmoji}</React.Fragment>
  }

  const transformNote = (note: string) => {
    if (EMOJI_REGEXP.test(note)) {
      return wrapEmoji(note)
    }
    return note
  }

  return (
    <>
      <div className={`${classes.rowNote} ${classes.rowNoteAddBackground}`}>
        <div className={classes.note} onClick={() => setIsOpenEditNote(true)}>
          <Typography className={classes.dateModified}>
            {DateHelpers.toLocaleDateString({ dateStr: note.date_modified || '' })}
          </Typography>
          <Typography className={classes.initials} >
            {note.initials}
          </Typography>
          <Tooltip title={note.tournament_name} arrow placement="top" className={classes.tournamentName}>
            <Typography >
              {note.tournament_name}
            </Typography>
          </Tooltip>
          {isOpenEditNote && <EditNote
            note={note}
            saveNote={saveNote}
            removeNote={removeNote}
            closeNote={() => setIsOpenEditNote(false)}
          />}
          {!isOpenEditNote && <Typography className={classes.noteText}>{transformNote(note.note)}</Typography>}
        </div>
        {!isOpenEditNote && <Box
          display={'flex'}
          alignItems={'center'}
          mr={'12px'}
          // width={'54px'}
          height={'50px'}
        >
          {note.export_pda === 2 && <div className={classes.showPinned}>
            <PinIcon width={'18px'} height={'18px'} color={Colors.svg.secondary} />
          </div>}
          <div
            className={classes.showMenu}
            // className={`${isActive ? '' : classes.showMenu}`}
            onMouseOver={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            <BaseMenu
              options={options}
              iconOptions={iconOptions}
              positionAdditional={'down'}
              customStyle={{ backgroundColor: 'transparent' }}
              additionalOptions={[
                { item: <Divider style={{ margin: 5 }} />, isSeparateElement: true },
                { item: <DeleteButton /> },
              ]}
              // iconAdditionalOptions={iconAdditionalOptions}
              handleMenuItemClick={handleMenuItemClick}
            />
          </div>
        </Box>}
      </div>

      <AlertDialog
        isOpen={isOpenDialog}
        title={noteDialogOptions[TypeDialogNote.DELETE].title}
        content={noteDialogOptions[TypeDialogNote.DELETE].content}
        closeDialog={(isConfirm) => {
          if (!isConfirm) {
            setIsOpenDialog(false);
            return;
          }
          setIsOpenDialog(false);
          removeNote(note);
        }}
      />
    </>
  );
};

export default Note;
