import React from 'react';
import { IconProps } from './index';
import { Colors } from '../../component/theme';

export const PinIcon = (props: IconProps) => {
  return (
    <svg fill={props.color || 'none'}
         width={props.width || '19'} height={props.height || '19'} viewBox="0 0 19 19">
      <path
        d="M18.7597 5.72772L13.183 0.150958C12.9816 -0.0505634 12.6547 -0.0505634 12.4534 0.150958L12.4298 0.174587C12.0878 0.51644 11.8994 0.971178 11.8994 1.45471C11.8994 1.76622 11.9786 2.06515 12.1256 2.33023L6.29048 7.33813C5.8439 6.93901 5.2739 6.72057 4.67016 6.72057C4.01958 6.72057 3.408 6.97389 2.9481 7.43389L2.91312 7.46887C2.7116 7.67028 2.7116 7.99697 2.91312 8.19839L6.25406 11.5393L3.00021 14.7931C2.9351 14.86 2.7397 15.1005 1.72714 16.3635C0.762873 17.5659 1.04563 17.3147 1.03572 17.326C0.856697 17.5299 0.866603 17.8374 1.05822 18.0299C1.15862 18.1308 1.291 18.1817 1.4238 18.1817C1.54484 18.1817 1.66619 18.1394 1.7638 18.0541C1.77236 18.0467 0.971739 18.6974 2.182 17.7271C3.44478 16.7145 4.22956 16.1546 4.30169 16.0843L7.55027 12.8357L10.7123 15.9978C10.813 16.0986 10.9451 16.1489 11.077 16.1489C11.209 16.1489 11.3412 16.0986 11.4418 15.9978L11.4768 15.9628C11.9368 15.5029 12.1901 14.8912 12.1901 14.2407C12.1901 13.637 11.9716 13.067 11.5725 12.6204L16.5804 6.78526C16.8455 6.9323 17.1444 7.01145 17.456 7.01145C17.9396 7.01145 18.3942 6.82313 18.7361 6.48107L18.7597 6.45744C18.9612 6.25582 18.9612 5.92913 18.7597 5.72772Z"
        fill={props.color || Colors.svg.lightGray} />
    </svg>
  );
};
