import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTableStyle from '../styles/useTableStyle';
import DateHelpers from '../../../../helpers/DateHelpers';
import AthleteGroup from '../athlete-rank/AthleteGroup';
import { AthleteHistoryRanks } from './types';

export type AthleteRanksHistoryProps = {
  historyRanks: AthleteHistoryRanks[];
}
const AthleteRanksHistory = (props: AthleteRanksHistoryProps) => {
  const { historyRanks = [] } = props;

  const classes = useTableStyle({ minWidth: 65 });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!historyRanks.length) {
    return (
      <Box display={'flex'} justifyContent={'center'} m={2}>
        <Typography classes={{ root: classes.textHeader }}>
          No information yet
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {matches && <Box className={classes.mobileContainer}>
        {historyRanks.map((row: AthleteHistoryRanks, idx) => {
          return (
            <Box key={idx} className={classes.mobileRow}>
              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle}>Event</Typography>
                <Typography className={classes.textValue}>{row.location}</Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle}>Date</Typography>
                <Typography className={classes.textValue}>
                  {DateHelpers.toLocaleDateString({ dateStr: row.date_created })}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle}>Coach</Typography>
                <Typography className={classes.textValue}>{row.coach_name}</Typography>
              </Box>

              <Box
                display={'flex'} flexDirection={'row'}
                justifyContent={'flex-start'}
                mb={2}
              >
                <Typography className={classes.textTitle}>Groups</Typography>

                <AthleteGroup
                  customStyle={{ alignItems: 'flex-start', }}
                  ranks={row.ranks}
                  disabledInput={true}
                />
              </Box>
            </Box>
          );
        })}
      </Box>}

      {!matches && <TableContainer component={Paper} classes={{ root: classes.container }}>
        <Table className={classes.table} aria-label="athlete history tags table">
          <TableHead classes={{ root: classes.header }}>
            <TableRow>
              <TableCell align="left">Event</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Coach</TableCell>
              <TableCell align="center">Groups</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: classes.body }}>
            {historyRanks.map((row: AthleteHistoryRanks, idx) => (
              <TableRow key={idx}>

                <TableCell align="left" style={{ width: 240 }}>
                  {row.location}
                </TableCell>
                <TableCell align="left" style={{ width: 120 }}>
                  {DateHelpers.toLocaleDateString({ dateStr: row.date_created })}
                </TableCell>
                <TableCell align="left" style={{ width: 200 }}>
                  {row.coach_name}
                </TableCell>
                <TableCell align="left">
                  <Box display="flex" flexWrap="wrap" alignItems="center" width="100%">
                    <AthleteGroup
                      ranks={row.ranks}
                      disabledInput={true}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  );
};

export default AthleteRanksHistory;
