import React from 'react';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import useNoteStyle from '../athlete-notes/useNoteStyle';
import { noteDialogOptions, TypeDialogNote } from '../athlete-notes/types';
import EditNote from './EditTeamNote';
import { Colors } from '../../../theme';
import DateHelpers from '../../../../helpers/DateHelpers';
import BaseMenu from '../../shared/BaseMenu';
import AlertDialog from '../../shared/AlertDialog';
import { TeamNoteDto } from '../../../../api/notes';

const noteMenuOptions: string[] = ['Edit'];

const iconOptions = new Map([
  ['Edit', <EditOutlinedIcon style={{ width: 18, height: 18 }} />],
]);
// const iconAdditionalOptions = new Map([
//   ['Delete', <DeleteOutlineIcon style={{ width: 18, height: 18, color: Colors.svg.dangerous }} />],
// ]);

export type PreviewReactProps = {
  note: TeamNoteDto;
  saveNote: (updateNote: TeamNoteDto, note?: TeamNoteDto) => void;
  removeNote: (note: TeamNoteDto) => void;
}
const TeamNote = (props: PreviewReactProps) => {
  const { note, saveNote, removeNote } = props;

  const [isOpenEditNote, setIsOpenEditNote] = React.useState(false);
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  const classes = useNoteStyle({ isActive, isOpenEditNote });

  const DeleteButton = () => {
    return (
      <div className={classes.deleteButton} onClick={() => setIsOpenDialog(true)}>
        <DeleteOutlineIcon style={{ width: 18, height: 18, color: Colors.svg.dangerous, marginRight: 4 }} />
        <span>Delete</span>
      </div>
    )
  }

  const handleMenuItemClick = async (idx: number) => {
    switch (noteMenuOptions[idx]) {
      case 'Edit':
        setIsOpenEditNote(true);
        break;
      default:
        break;
    }
  };


  return (
    <>
      <div className={`${classes.rowNote} ${classes.rowNoteAddBackground}`}>
        <div className={classes.note} onClick={() => setIsOpenEditNote(true)}>
          <Typography className={classes.dateModified}>
            {DateHelpers.toLocaleDateString({ dateStr: note.date_modified || '' })}
          </Typography>
          {isOpenEditNote && <EditNote
            note={note}
            saveNote={saveNote}
            removeNote={removeNote}
            closeNote={() => setIsOpenEditNote(false)}
          />}
          {!isOpenEditNote && <Typography className={classes.noteText}>{note.note}</Typography>}
        </div>
        {!isOpenEditNote && <Box
          display={'flex'}
          alignItems={'center'}
          mr={'12px'}
          // width={'54px'}
          height={'50px'}
        >
          <div
            className={classes.showMenu}
            // className={`${isActive ? '' : classes.showMenu}`}
            onMouseOver={() => setIsActive(true)}
            onMouseLeave={() => setIsActive(false)}
          >
            <BaseMenu
              options={noteMenuOptions}
              iconOptions={iconOptions}
              positionAdditional={'down'}
              customStyle={{ backgroundColor: 'transparent' }}
              additionalOptions={[
                { item: <Divider style={{ margin: 5 }} />, isSeparateElement: true },
                { item: <DeleteButton />},
              ]}
              // iconAdditionalOptions={iconAdditionalOptions}
              handleMenuItemClick={handleMenuItemClick}
            />
          </div>
        </Box>}
      </div>

      <AlertDialog
        isOpen={isOpenDialog}
        title={noteDialogOptions[TypeDialogNote.DELETE].title}
        content={noteDialogOptions[TypeDialogNote.DELETE].content}
        closeDialog={(isConfirm) => {
          if (!isConfirm) {
            setIsOpenDialog(false);
            return;
          }
          setIsOpenDialog(false);
          removeNote(note);
        }}
      />
    </>
  );
};

export default TeamNote;
