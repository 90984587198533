import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTableStyle from '../styles/useTableStyle';
import { TeamStaffDto } from '../../../../api/teams';

export type TeamStaffProps = {
  teamStaff: TeamStaffDto[];
}

const columnTitles = [
  {
    name: 'First',
    align: "left"
  },
  {
    name: 'Last',
    align: "left"
  },
  {
    name: 'Role',
    align: "left"
  },
  {
    name: 'Phone',
    align: "left"
  },
  {
    name: 'Email',
    align: "left"
  },
  {
    name: 'Address',
    align: "left"
  },
  {
    name: 'City',
    align: "left"
  },
  {
    name: 'State',
    align: "center"
  },
  {
    name: 'Zip',
    align: "center"
  }]

const TeamStaff = (props: TeamStaffProps) => {
  const { teamStaff = [] } = props;

  const classes = useTableStyle();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  if (!teamStaff.length) {
    return (
      <Box display={'flex'} justifyContent={'center'} m={2}>
        <Typography classes={{ root: classes.textHeader }}>
          No information yet
        </Typography>
      </Box>
    );
  }

  const emptyHelper = (value: any) => value ?? <span style={{ color: 'rgba(175, 175, 175, 1)' }}>empty</span>

  return (
    <>
      {matches && <Box className={classes.mobileContainer}>
        {teamStaff.map((row: TeamStaffDto, idx) => {
          return (
            <Box key={idx} className={classes.mobileRow}>
              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[0].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.first)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[1].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.last)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[2].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.role)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[3].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.phone)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[4].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.email)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[5].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.address)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[6].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.city)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[7].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.state)}
                </Typography>
              </Box>

              <Box display={'flex'} flexDirection={'row'}>
                <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[8].name}</Typography>
                <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                  {emptyHelper(row.zip)}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>}

      {!matches && <TableContainer component={Paper} classes={{ root: classes.container }}>
        <Table className={classes.table} style={{ paddingLeft: 20 }} stickyHeader aria-label="team staff table">
          <TableHead classes={{ root: classes.header }} >
            <TableRow>
              {columnTitles.map((item, idx) => (
                <TableCell key={idx} align={item.align as any} style={{ backgroundColor: '#fff' }}>{item.name}</TableCell>)
              )}
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: classes.body }}>
            {teamStaff.map((row: TeamStaffDto, idx) => (
              <TableRow key={idx} classes={{ root: classes.row }}>

                <TableCell align="left" style={{ minWidth: 100 }} >
                  {row.first}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                  {row.last}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 150 }} >
                  {row.role}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 120 }}>
                  {row.phone}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                  {row.email}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 180 }}>
                  {row.address}
                </TableCell>
                <TableCell align="left" style={{ minWidth: 100 }}>
                  {row.city}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 50 }}>
                  {row.state}
                </TableCell>
                <TableCell align="center" style={{ minWidth: 80 }}>
                  {row.zip}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  );
};

export default TeamStaff;
