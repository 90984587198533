import React, { useMemo } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTableStyle from '../styles/useTableStyle';
import { TeamScheduleDto } from '../../../../api/teams';

type TeamSchedulesProps = {
  teamSchedules: TeamScheduleDto[];
}
type PreparingSchedulesType = {
  event_name: string;
  data: TeamScheduleDto[];
}

const columnTitles = [
  {
    name: 'Date Time',
    align: "center"
  },
  {
    name: 'Division',
    align: "center"
  },
  {
    name: 'Court',
    align: "center"
  },
  {
    name: 'Team 1',
    align: "center"
  },
  {
    name: 'Team 2',
    align: "center"
  },
  {
    name: 'Winner',
    align: "center"
  },
  {
    name: 'Scores',
    align: "center"
  }]

const TeamSchedules = (props: TeamSchedulesProps) => {
  const { teamSchedules = [] } = props;

  const classes = useTableStyle();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  const preparingSchedules: PreparingSchedulesType[] = useMemo(() => {

    if (teamSchedules.length === 0) return []

    var result = teamSchedules.reduce((acc: any, item) => {
      if (acc[item.event_name]) {
        acc[item.event_name].push(item)
      } else {
        acc[item.event_name] = [item]
      }
      return acc
    }, {})

    let toArr: PreparingSchedulesType[] = Object.getOwnPropertyNames(result).map(k => {
      return { event_name: k, data: result[k] }
    })

    return toArr
  }, []);

  if (!preparingSchedules.length) {
    return (
      <Box display={'flex'} justifyContent={'center'} m={2}>
        <Typography classes={{ root: classes.textHeader }}>
          No information yet
        </Typography>
      </Box>
    );
  }

  const emptyHelper = (value: any) => value ?? <span style={{ color: 'rgba(175, 175, 175, 1)' }}>empty</span>

  return (
    <>
      {matches && <Box className={classes.mobileContainer}>
        {preparingSchedules.map((rowData: PreparingSchedulesType, idx) => {
          return (
            <Box key={idx}>
              <Divider />
              <Typography style={{ minWidth: 90, fontWeight: 'bolder', marginLeft: 12, marginTop: 16}}>{rowData.event_name}</Typography>

              {rowData.data.map((row: TeamScheduleDto, idx) => {
                return (
                  <Box key={idx} className={classes.mobileRow}>
                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[0].name}</Typography>
                      <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                        {emptyHelper(row.date_time)}
                      </Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[1].name}</Typography>
                      <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                        {emptyHelper(row.division)}
                      </Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[2].name}</Typography>
                      <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                        {emptyHelper(row.court)}
                      </Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[3].name}</Typography>
                      <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                        {emptyHelper(row.team_1_name)}
                      </Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[4].name}</Typography>
                      <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                        {emptyHelper(row.team_2_name)}
                      </Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[5].name}</Typography>
                      <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                        {emptyHelper(row.winning_team)}
                      </Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'row'}>
                      <Typography className={classes.textTitle} style={{ minWidth: 80 }}>{columnTitles[6].name}</Typography>
                      <Typography className={classes.textValue} style={{ wordBreak: 'break-word' }}>
                        {emptyHelper(row.scores)}
                      </Typography>
                    </Box>
                  </Box>
                )
              })
              }
            </Box>
          );
        })}
      </Box>
      }

      {!matches && <TableContainer component={Paper} classes={{ root: classes.container }}>
        <Table className={classes.table} stickyHeader aria-label="team staff table">
          <TableHead classes={{ root: classes.header }} >
            <TableRow>
              {columnTitles.map((item, idx) => (
                <TableCell key={idx} align={item.align as any} style={{ backgroundColor: '#fff' }}>{item.name}</TableCell>)
              )}
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: classes.body }}>
            {preparingSchedules.map((rowData: PreparingSchedulesType, idx) => (
              <>
                <TableRow key={rowData.data[0].team_master_id}>
                  <TableCell align="center" colSpan={12}>
                    <Typography style={{ fontWeight: 'bolder' }}>{rowData.event_name}</Typography>
                  </TableCell>
                </TableRow>
                {rowData.data.map((row: TeamScheduleDto, idx) => {
                  return (
                    <TableRow key={idx} classes={{ root: classes.row }}>
                      <TableCell align="center" style={{ minWidth: 120 }} >
                        {row.date_time}
                      </TableCell>

                      <TableCell align="center" style={{ minWidth: 50 }}>
                        {row.division}
                      </TableCell>

                      <TableCell align="center" style={{ minWidth: 50 }} >
                        {row.court}
                      </TableCell>

                      <TableCell align="center" style={{ minWidth: 120 }}>
                        {row.team_1_name}
                      </TableCell>

                      <TableCell align="center" style={{ minWidth: 120 }}>
                        {row.team_2_name}
                      </TableCell>

                      <TableCell align="center" style={{ minWidth: 50 }}>
                        {row.winning_team}
                      </TableCell>

                      <TableCell align="center" style={{ minWidth: 80 }}>
                        {row.scores}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>}
    </>
  );
};

export default TeamSchedules;
